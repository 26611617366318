import { hydrate, render } from "react-dom";
import { attemptAuthentication } from './auth';
import './index.css';
import routing from "./routing"
import reportWebVitals from './reportWebVitals';

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(routing, rootElement);
} else {
  render(routing, rootElement);
}



// ReactDOM.render(routing, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
