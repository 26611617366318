import store from './store'

export const updateUser = user => {
  return { type: 'UPDATE_USER', payload: user }
}

export const addCompletedPoll = (completedPollId, pollResponse) => {
  return { type: 'ADD_COMPLETED_POLL', payload: { completedPollId, pollResponse } }
}

export const logout = () => {
  return { type: 'LOGOUT' }
}

export const boundLogout = () => {
  store.dispatch(logout());
}

export const boundUpdateUser = user => {
  store.dispatch(updateUser(user))
}

export const boundAddCompletedPoll = (completedPollId, pollResponse) => {
  store.dispatch(addCompletedPoll(completedPollId, pollResponse));
}
