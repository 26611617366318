import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { FormControlLabel, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { getVotuzaNdaCookie } from '../../utils';
const cookies = new Cookies();

const messages = [
  "A website designed to hold companies accountable for the highest level of customer service for all consumers that support those companies.",
  "A market research company that provides valuable feedback to various companies by relaying important messages and information from their actual customers.",
  "An unconventional voting system utilizing the voice of the American people to make a change within various companies through a vortal for all people."
];

export default function Landing({ close }){
  // const [termsChecked, setTermsChecked] = useState(false)
  // const [showNda, setShowNda] = useState(!getVotuzaNdaCookie())
  return (
    <Modal
      open={true}
      BackdropProps={{
        style: { backgroundColor: '#f6f6f6'}
      }}
      style={{ overflowY: 'scroll' }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
        <Fragment>
          <div style={{ position: 'absolute', left: '20px', top: '20px'}}>
            <IconButton
              color="primary"
              size="large"
              onClick={close}
            >
              <CloseIcon style={{ height: '3.5em', width: '3.5em'}}/>
            </IconButton>
          </div>
          <div className='container mobile-margin-top' style={{ paddingBottom: '40px', paddingTop: '0px'}}>
            <div>
              <div style={{ paddingBottom: '40px' }}>
                <Typography variant='h3' color='primary' style={{ zIndex: 5, paddingTop: '0px' }}>
                  Welcome to Votuza <span style={{ fontSize: '30px' }}>noun, verb \vō-tü-zha\</span>
                </Typography>
              </div>
              <div>
                {messages.map((message, index) => (
                  <div style={{ marginBottom: '30px' }}>
                    <Typography variant='h5' color="primary">{index + 1}.</Typography>
                    <Typography variant='h5' style={{ color: '#b7b7b7', lineHeight: 1 }}>{message}</Typography>
                  </div>
                ))}
              </div>
              <div style={{ width: '100%'}}>
                <Button onClick={close} style={{  margin: 'auto', display: 'block', minWidth: '0px', paddingLeft: '25px', paddingRight: '25px', letterSpacing: '0.5px', height: '36.5px' }}>Got it</Button>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </Modal>
  )
}

const NdaLanding = ({ setTermsChecked, termsChecked, setShowNda }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
     <div style={{ paddingBottom: '10px' }}>
      <Typography variant='h5' style={{ zIndex: 5, paddingTop: '0px', color: '#034e5b', textAlign: 'center' }}>
        Welcome to Votuza's Beta Launch!
      </Typography>
      <Typography variant='h6' style={{ zIndex: 5, paddingTop: '0px', color: '#034e5b', textAlign: 'center' }}>
        Please review and agree to our NDA below to continue to the site.
      </Typography>
    </div>
    <iframe frameBorder="0" style={{ width: '900px', maxWidth: '95%', height: '600px', maxHeight: '90%' }} src="https://docs.google.com/document/d/e/2PACX-1vQ34TGEz57WdPOKK40GVX0iLWYyZr7IRPZYgZ3jK8vHej_qyNXuavya5_bFTy-6LxXvZt_Turoq1PVc/pub?embedded=true"></iframe>
    <div style={{ maxWidth: '500px', paddingTop: '10px' }}>
      <FormControlLabel
          control={
            <Checkbox color="primary" onChange={() => setTermsChecked(!termsChecked)} name="Terms" />
          }
          style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}
          label={<Typography style={{ color: '#034e5b', fontSize: '15px', letterSpacing: '1px' }}>I Agree to the NDA Agreement above</Typography>}
      />
    </div>
    <div style={{ width: '100%'}}>
      <Button 
        disabled={!termsChecked} 
        onClick={() => {
          cookies.set('votuzaNdaCookie', true, { expires: new Date(Date.now()+864000000000000) });
          setShowNda(false)
        }} 
        style={{ margin: 'auto', display: 'block', minWidth: '0px', paddingLeft: '25px', paddingRight: '25px', letterSpacing: '0.5px', height: '36.5px' }}
      >
        Accept and Continue
      </Button>
    </div>
  </div>
)