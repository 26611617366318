import React, { Fragment } from 'react';
import { SocialIcon } from 'react-social-icons';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import SwipeableViews from 'react-swipeable-views';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom'
import SearchModal from './SearchModal';

export default class HowTo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHowToModal: false,
      showSearchModal: false
    }
  }

  toggleHowToModal = () => {
    this.setState({ showHowToModal: !this.state.showHowToModal });
  }

  toggleSearchModal = () => {
    this.setState({ showSearchModal: !this.state.showSearchModal });
  }

  render() {
    const { isHome } = this.props;
    return (
      <div className={`how-to-button${isHome ? '-home' : ''}`}>

  
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: isHome ? '0px' : '10px' }}>
          <Typography color='textSecondary' style={{ fontSize: 'clamp(1rem, 1vw, 1.25rem)', marginBottom: '5px', zIndex: 10}}>Search</Typography>
          <IconButton onClick={this.toggleSearchModal} style={{ backgroundColor: 'white', position: 'relative', border: '4px solid #034e5b', width: '45px', height: '45px' }}>
            <SearchIcon style={{ position: 'absolute', color: '#034e5b', height: '32px', width: '32px', zIndex: 10, borderRadius: '50%' }}/>
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography color='textSecondary' style={{ fontSize: 'clamp(1rem, 1vw, 1.25rem)', marginBottom: '5px', zIndex: 10}}>How to Use?</Typography>
          <IconButton onClick={this.toggleHowToModal} style={{ backgroundColor: '#034e5b', position: 'relative', width: '45px', height: '45px' }}>
            <HelpIcon style={{ position: 'absolute', color: 'white', height: '45px', width: '45px', zIndex: 10 }}/>
          </IconButton>
        </div>
        {!isHome &&
          <div style={{ width: '100%', padding: '10px', paddingBottom: '0px', boxSizing: 'border-box' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '250px', margin: 'auto', marginTop: '5px', padding: '15px', borderRadius: '10px', overflow: 'hidden' }}>
              <Typography color='textSecondary' style={{ fontSize: 'clamp(1rem, 1.1vw, 1.3rem)', marginBottom: '5px', fontFamily: 'arial', color: '034e5b' }}><span style={{ fontStyle: 'italic', fontWeight: 600, }}>"You cannot escape the responsibility of tomorrow by avoiding it today"</span><br/>– Abraham Lincoln</Typography>
            </div>
          </div>
        }
        {this.state.showHowToModal && <HowToModal onClose={this.toggleHowToModal} title="How to VOTUZA"/>}
        {this.state.showSearchModal && <SearchModal onClose={this.toggleSearchModal}/>}
      </div>
    )
  }
}

class HowToModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    }
  }

  handleChangeIndex = index => {
    if (index < 0 || index > 5) {
      return;
    }
     this.setState({
       index,
     });
   }

  render() {
    const { index } = this.state;
    const { title, onClose } = this.props;
    return (
      <Modal
        open={true}
        className='modal-container'
        BackdropProps={{
          invisible: false
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        onClose={onClose}
      >
        <div style={{backgroundColor: '#f6f6f6', display: 'flex', padding: '25px', justifyContent: 'center', flexDirection:'column', border: '2px solid #034e5b', maxWidth: '600px', minWidth: '300px', borderRadius: '10px', overflow: 'hidden', position: 'relative'}}>
          <div style={{ position: 'absolute', left: '0px', top: '0px'}}>
            <IconButton
              onClick={onClose}
              color="primary"
              size="medium"
            >
              <CloseIcon style={{ height: '2.5em', width: '2.5em'}}/>
            </IconButton>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant='h5' color="textSecondary">
              {title}
            </Typography>
            <div>
            <div style={{ display: 'flex', marginTop: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  onClick={() => this.handleChangeIndex(index-1)}
                  style={{ padding: 'none' }}
                  disabled={index === 0}
                >
                  <NavigateBeforeIcon style={{ width: '2em', height: '2em' }}/>
                </IconButton>
              </div>
              <SwipeableViews enableMouseEvents index={index} onChangeIndex={this.handleChangeIndex}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    Select an industry category
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    Choose a poll
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    Make your vote, and leave a complaint
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    Vote for one poll or vote for all polls.
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    Let the CEOs know you have a VOICE!
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', paddingLeft: '0px', paddingRight: '0px' }}>
                  <Typography variant='h5' color="primary">
                    VOTE...VOTE... and keep VOTING!
                  </Typography>
                </div>
              </SwipeableViews>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  onClick={() => this.handleChangeIndex(index+1)}
                  style={{ padding: 'none' }}
                  disabled={index === 5}
                >
                  <NavigateNextIcon style={{ width: '2em', height: '2em' }}/>
                </IconButton>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <div style={{width: '15px', backgroundColor: index === 0 ? '#9cd70a' : '#034e5b', border: index === 0 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
              <div style={{width: '15px', backgroundColor: index === 1 ? '#9cd70a' : '#034e5b', border: index === 1 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
              <div style={{width: '15px', backgroundColor: index === 2 ? '#9cd70a' : '#034e5b', border: index === 2 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
              <div style={{width: '15px', backgroundColor: index === 3 ? '#9cd70a' : '#034e5b', border: index === 3 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
              <div style={{width: '15px', backgroundColor: index === 4 ? '#9cd70a' : '#034e5b', border: index === 4 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
              <div style={{width: '15px', backgroundColor: index === 5 ? '#9cd70a' : '#034e5b', border: index === 5 ? '1px solid black' : '', margin: '4px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}/>
            </div>
            <div style={{ margin: '20px', marginBottom: '0px' }}>
              <span><Link to={{ pathname: "/about" }} onClick={onClose} style={{ textDecoration: 'underline', color: 'inherit', zIndex: 990  }} className='pointer'>why your vote matters</Link></span>
            </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

// <div style={{ position: 'absolute', left: '5px', top: '5px', zIndex: 1000 }}>
//   <IconButton
//     onClick={onClose}
//     color="secondary"
//     style={{ padding: 'none' }}
//   >
//     <CloseIcon style={{ width: '2em', height: '2em', color: '#034e5b' }}/>
//   </IconButton>
// </div>
