import React, { Component, Fragment, useState, createRef, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import { DrawableOverlay, useStage, useDrawableUtils, useDrawableState, useDrawableHistory } from "react-drawable-overlay"
import { useScreenshot } from 'use-react-screenshot';
import { toPng, toSvg } from 'html-to-image';
import { FormControlLabel, MenuItem, Menu, Checkbox, Snackbar, Modal, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { withRouter } from 'react-router-dom'
import GestureIcon from '@material-ui/icons/Gesture';
import { getVotuzaLandingAnimationCookie } from '../../utils';
import { FaMarker, FaEraser, FaTrash } from "react-icons/fa";
import Draggable from 'react-draggable';
import { HuePicker } from "react-color"
import $ from "jquery";
import * as api from '../../api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const sendFeedback = async (image, comment) => {
  try {
    const response = await api.makeRequest(api.postFeedback, {image, comment});
  } catch (e) {
    console.log('e:', e);
  }
};

const Content = ({ children }) => {
  // const [image, setImage] = useState(null)
  const [image, takeScreenshot] = useScreenshot()
  const getImage = () => takeScreenshot(document.getElementById('root'))
  //const getImage = () => {
    // toSvg(screen, { cacheBust: true, })
    //  .then((dataUrl) => {
    //    console.log('dataUrl:', dataUrl);
    //    setImage(dataUrl);
    //  })
  return (
    <Fragment>

      <DrawableOverlay initialInDrawMode={false} defaultBrushColor="#000">
        {children}
        <Content2 getImage={getImage}/>
      </DrawableOverlay>
    </Fragment>
  )
}

const Content2 = ({ getImage }) => {
  const stage = useStage()
  const {
    reset,
    setEraserSize,
    setBrushColor,
    setBrushSize,
    setInDrawMode,
    setDrawMode
  } = useDrawableUtils();
  const [showFeedback, setShowFeedback] = useState(false);
  const [showMarker, setShowMarker] = useState(false);
  const [comment, setComment] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const { brushSize, brushColor, eraserSize, inDrawMode, drawMode } = useDrawableState()
  useEffect(() => {
    setEraserSize(30);
    const cookie = getVotuzaLandingAnimationCookie();
    if(cookie) {
      setShowFeedback(true);
    } else {
      setTimeout(() => {
        setShowFeedback(true);
      }, 9000);
    }
  }, [])
  const [alertOpen, setAlertOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { undo, redo, history, currentHistoryIndex } = useDrawableHistory()
  const submitFeedback = async () => {
    try {
      // await setImageLoading(true);
      const image = await getImage();
      // setImageLoading(false);
      sendFeedback(image, comment);
      setComment('');
      reset();
      setInDrawMode(false);
      setAlertOpen(true)
    } catch(e) {

    }
  };

  if (!showFeedback) {
    return (
      <div style={{ position: "absolute", height: '100%', top: '0px', width: '100vw', zIndex: inDrawMode ? 10000 : -1 }}>
         <div style={{ position: "relative", height: '100%', width: '100vw' }}>
         {stage}
         </div>
      </div>
    )
  }

  // <div style={{ maxWidth: '100%', position: 'relative' }}>
  //     <HuePicker width='200px' onChange={color => setBrushColor(color.hex)} color={brushColor} />
  // </div>

  console.log('history:', history, currentHistoryIndex);

  return (
    <React.Fragment>
       <div style={{ position: "absolute", height: '100%', top: '0px', width: '100vw', zIndex: inDrawMode ? 10000 : -1 }}>
          <div style={{ position: "relative", height: '100%', width: '100vw' }}>
          {stage}
          </div>
       </div>
       <Draggable cancel="strong" defaultPosition={{x: 50, y: 50}}>
         <div role="presentation" style={{ backgroundColor: 'lightgrey', position: 'fixed', top: '0px', cursor: 'grab', overflow: 'hidden', zIndex: 100000, width: '250px', borderRadius: '25px', border: '1px solid black', display: imageLoading ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
           <div style={{ width: '100%', backgroundColor: 'grey', height: '20px', padding: '2px' }}>
            <button style={{ marginLeft: '20px' }} onClick={() => setShowFeedbackModal(!showFeedbackModal)}>
              { showFeedbackModal ? '-' : '+'}
            </button>
            <Typography variant='h7' style={{ color: 'white', marginLeft: '10px' }}>Feedback</Typography>
           </div>
           { showFeedbackModal &&
             <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
               <Typography variant='h7' color="textSecondary">We encourage beta users to markup the page and leave comments as they like.</Typography>
               <div style={{ textAlign: 'center', margin: '5px', marginTop: '10px' }}>
                  <div style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', padding: '2px' }}>
                    <IconButton onClick={reset}>
                      <FaTrash style={{ color: '#034e5b' }}/>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (drawMode === 'eraser' && inDrawMode) {
                          setInDrawMode(false)
                        } else {
                          setDrawMode('eraser')
                          setInDrawMode(true)
                        }
                      }}
                      style={{ backgroundColor: inDrawMode && drawMode === 'eraser' ? '#9cd70a' : '', marginLeft: '5px', marginRight: '5px' }}
                    >
                      <FaEraser style={{ color: '#034e5b' }}/>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (drawMode === 'brush' && inDrawMode) {
                          setInDrawMode(false)
                        } else {
                          setDrawMode('brush')
                          setInDrawMode(true)
                        }
                      }}
                      style={{ backgroundColor: inDrawMode && drawMode === 'brush' ? '#9cd70a' : '', marginLeft: '5px', marginRight: '5px' }}
                    >
                      <FaMarker style={{ color: '#034e5b' }}/>
                    </IconButton>
                  </div>
               </div>
               <strong>
                 <TextField
                    multiline
                    autofocus={true}
                    inputProps={{ style: {
                      fontSize: '1rem',
                    }}}
                    InputProps={{ style: {
                      padding: '20px',
                      width: '100%',
                    }}}
                    label="Comment"
                    variant='outlined'
                    size="small"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={5}
                    placeholder="I think that the font is too big."
                  />
                </strong>
                <strong>
                  <Button className='blue-button' onClick={submitFeedback} style={{ paddingLeft: '20px', paddingRight: '20px', margin: '5px', letterSpacing: '0.5px', height: '36.5px', minWidth: '0px' }}>Submit</Button>
                </strong>
              </div>
            }
          </div>
       </Draggable>
       <Snackbar open={alertOpen} autoHideDuration={5000} onClose={()=>setAlertOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
         <Alert onClose={()=>setAlertOpen(false)} severity="success">
           Thank you for your feedback
         </Alert>
       </Snackbar>
     </React.Fragment>
  )
}

export default Content;
