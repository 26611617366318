import React, { useState, useEffect, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { useDebounce } from 'use-debounce';
import { withRouter } from 'react-router-dom'
import * as api from '../../api';

const colors =[
  '#ffffff',
  '#eeeeee',
  '#98a6ab',
  '#53757e',
  '#3e6874',
]

const refs = {
  currentSearch: null
}

const SearchModal = ({ onClose, history }) => {
  const [searchInput, setSearchInput] = useState('');
  const [pollSearchResults, setPollSearchResults] = useState([]);
  const [debouncedSearchInput] = useDebounce(searchInput);

  useEffect(() => {
    console.log('useEffect');
    const searchPolls = async (searchInput) => {
      refs.currentSearch = searchInput
      const polls = await api.makeRequest(api.searchPolls, debouncedSearchInput);
      console.log('polls:', polls);
      if (searchInput === refs.currentSearch) {
        console.log('match');
        setPollSearchResults(polls);
      } else {
        console.log('disregard');
      }
    }
    searchPolls(debouncedSearchInput);
  }, [debouncedSearchInput])

  // useEffect(() => {
  //   console.log('searchInputRef:', searchInputRef.current)
  //   if (searchInputRef.current) {
  //     searchInputRef.current.focus();
  //   }
  //   //searchInputRef.focus();
  // }, [])
  return (
    <Modal
      open={true}
      onClose={onClose}
      style={{ overflowY: 'scroll' }}
      BackdropProps={{
        style: { background: '#035462'}
      }}
      id='modal'
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
         <div>
            <div style={{ position: 'relative', width: '100%', minHeight: '100vh' }}>
              <div style={{ position: 'absolute', left: '20px', top: '20px', zIndex: 1000 }}>
                <IconButton
                  onClick={onClose}
                  style={{ color: 'white' }}
                  size="large"
                >
                  <CloseIcon style={{ height: '3.5em', width: '3.5em' }}/>
                </IconButton>
              </div>
              <div className='container' style={{ overflow: 'none', paddingBottom: '50px' }}>
                <Typography variant='h5' style={{ marginTop: '110px', color: '#c1c1c1' }}>Search for a Poll</Typography>
                <div style={{ marginTop: '35px' }}>
                  <input ref={(el) => {if(el)el.focus()}} onChange={(e) => {setSearchInput(e.target.value)}} style={{ background: 'none', border: 'none', fontSize: 'clamp(3rem, calc(33px + 4vw), 5.0rem)', fontFamily: 'Grotesk', width: '100%', color: 'white', '-webkit-appearance': 'none', outline: 0 }}/>
                </div>
                <div style={{ marginTop: '80px' }}>
                  {pollSearchResults.map((poll, index) => {
                    const opacity = 1 - index*.08;
                    return (
                      <div style={{ marginBottom: '30px' }}>
                        <Typography onClick={() => {history.push({ pathname: `/poll/${poll._id}` })}} className='green-hover pointer' variant='h4' style={{ color: `rgba(255, 255, 255, ${opacity}` }}>{poll.title}</Typography>
                        <Typography 
                          onClick={() => {
                            history.push({ pathname: `/category/${poll.category._id}`});
                            onClose();
                          }} 
                          className='green-hover pointer' 
                          variant='h6' 
                          style={{ color: `rgba(255, 255, 255, ${opacity}` }}
                        >
                          {poll.category.name}
                        </Typography>
                      </div>
                  )})}
                </div>
              </div>
            </div>
          </div>
    </Modal>
  )
}


export default withRouter(SearchModal);