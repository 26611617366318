import React, { Fragment } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  PinterestShareButton,
  PinterestIcon,
  TumblrShareButton,
  TumblrIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon
} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const iconSize = 50;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class ShareModal extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false
    }
  }

  copyShareLink = () => {
    this.setState({ alertOpen: true });
  }

  closeAlert = () => {
    this.setState({ alertOpen: false });
  }

  render() {
    const { shareUrl, isOpen=true, onClose, title="Share Your Vote" } = this.props;
    const { alertOpen } = this.state;
    return (
      <Modal
        open={isOpen}
        className='modal-container'
        disableAutoFocus={true}
        disableEnforceFocus={true}
        BackdropProps={{
          invisible: false
        }}
        onClose={onClose}
      >
        <div style={{backgroundColor: '#f6f6f6', position: 'relative', display: 'flex', padding: '25px', justifyContent: 'center', flexDirection:'column', border: '2px solid #034e5b', maxWidth: '90%', minWidth: '300px', borderRadius: '10px', overflow: 'hidden', position: 'relative'}}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ position: 'absolute', left: '5px', top: '5px', zIndex: 1000 }}>
              <IconButton
                onClick={onClose}
                color="secondary"
                style={{ padding: 'none' }}
              >
                <CloseIcon style={{ width: '2em', height: '2em', color: '#034e5b' }}/>
              </IconButton>
            </div>
            <Typography variant='h5' color="textSecondary">
              {title}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '15px', flexWrap: 'wrap' }}>
              <FacebookShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <FacebookIcon size={iconSize} round />
              </FacebookShareButton>
              <LinkedinShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <LinkedinIcon size={iconSize} round />
              </LinkedinShareButton>
              <RedditShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <RedditIcon size={iconSize} round />
              </RedditShareButton>
              <TwitterShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <TwitterIcon size={iconSize} round />
              </TwitterShareButton>
              <EmailShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
                subject="A website recommendation for you - VOTUZA"
                openShareDialogOnClick={true}
                onClick={e => e.preventDefault()}
              >
                <EmailIcon size={iconSize} round />
              </EmailShareButton>
              <FacebookMessengerShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <FacebookMessengerIcon size={iconSize} round />
              </FacebookMessengerShareButton>
              <WhatsappShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <WhatsappIcon size={iconSize} round />
              </WhatsappShareButton>
              <TelegramShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <TelegramIcon size={iconSize} round />
              </TelegramShareButton>
              <TumblrShareButton
                style={{ margin: '5px'}}
                url={shareUrl}
              >
                <TumblrIcon size={iconSize} round />
              </TumblrShareButton>
            </div>
            <div>
              <CopyToClipboard
                text={shareUrl}
                onCopy={this.copyShareLink}
              >
                <Typography className="pointer" variant='h7' color="textSecondary" style={{ textDecoration: 'underline' }}>
                  Copy share link to clipboard
                </Typography>
              </CopyToClipboard>
            </div>
          </div>
          <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.closeAlert} severity="success">
              Share link copied to clipboard
            </Alert>
          </Snackbar>
        </div>
      </Modal>
    )
  }
}
