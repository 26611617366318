import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';

const messages = [
  "For each industry, we compile all votes across industry specific polls to determine which companies are the worst.",
  "Hold CEOs responsible for their company’s customer experience and service.",
  "Expose the worst brand experiences by industry and category.",
  "Offer marketing and voting data reports by company and industry to address specific and systemic issues."
]

class Worst extends Component {
  constructor(props) {
    super(props);
    const categories = getCategoriesFromStorage() || [];
    this.state = {
      categories,
      openCategories: new Set()
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {

    }
  }

  toggleCategory = (categoryId) => {
    const updatedOpenCategories = new Set(this.state.openCategories);
    if (updatedOpenCategories.has(categoryId)) {
      updatedOpenCategories.delete(categoryId);
    } else {
      updatedOpenCategories.add(categoryId)
    }
    this.setState({ openCategories: updatedOpenCategories });
  }

  render() {
    const { categories } = this.state;
    console.log('render NonResponders:', categories);
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div className='left-padding' style={{  paddingTop: '50px' }}>
            <div style={{ paddingBottom: '20px' }}>
              <Typography variant='h2' color='primary'>
                The Worst
              </Typography>
            </div>
            <div className="introduction-holder" style={{ display: 'flex' }}>
              <div style={{ flex: 7}}>
                {categories.slice(0,8).map(category => (
                  <Category category={category} />
                ))}
              </div>
              <div style={{ flex: 3}}>
                <Typography variant='h6' color='primary' style={{ lineHeight: 1 }}>
                  Who are The Worst?
                </Typography>
                {messages.map(message => (
                  <div style={{  marginTop: '15px' }}>
                    <Typography variant='h7' color='textSecondary' style={{ lineHeight: 1, fontSize: '1.1rem', fontWeight: '700' }}>
                      {message}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div>
              {categories.slice(8,categories.length).map(category => (
                <Category category={category} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      companies: props.category.companies.slice().filter(company => company.total_active_votes > 0),
      showMore: false,
    }
  }

  toggleCategory = () => {
    if (this.state.companies.length > 0) {
      this.setState({ open: !this.state.open, showMore: false })
    }
  }

  render() {
    const { category } = this.props;
    const { open, companies } = this.state;
    console.log('companies:', companies);
    const categoryStyle = { lineHeight: 1, display: 'inline-block' };
    if (open) {
      categoryStyle.color = '#9cd70a';
    } else {
    }
    console.log('categoryStyle:', categoryStyle);
    return (
      <div>
        <Typography variant='h3' className='grey-h1-button text-grey' style={categoryStyle} onClick={this.toggleCategory}>
          {category.name}
        </Typography>
        {open &&
          <Fragment>
          {!this.state.showMore ? 
            <div>
              {companies.sort((x, y) => {
                return y.total_active_votes - x.total_active_votes
              }) .slice(0,3).map((company, index) => (
                <div>
                  <div style={{ minWidth: '45px', display: 'inline-block' }}>
                    <Typography variant='h4' color="primary" style={{ display: 'inline' }}>{index + 1}.</Typography>
                  </div>
                  <Typography variant='h4' style={{ lineHeight: 1, color: '#034e5b', display: 'inline' }}>
                    {company.name}
                  </Typography>
                </div>
              ))}
              {companies.length > 3 && (
                <Typography variant='h5' className='green-hover pointer' style={{ lineHeight: 1, color: '#034e5b', display: 'inline' }} onClick={() => this.setState({ showMore: true })}>
                  Show more
                </Typography>
              )}
            </div>
            :
            <div>
              {companies.sort((x, y) => {
                return y.total_active_votes - x.total_active_votes
              }).map((company, index) => (
                <div>
                  <div style={{ minWidth: '45px', display: 'inline-block' }}>
                    <Typography variant='h4' color="primary" style={{ display: 'inline' }}>{index + 1}.</Typography>
                  </div>
                  <Typography variant='h4' style={{ lineHeight: 1, color: '#034e5b', display: 'inline' }}>
                    {company.name}
                  </Typography>
                </div>
              ))}
            </div>
          }
          </Fragment>
        }
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(Worst);
