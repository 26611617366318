import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9cd70a',
    },
    secondary: {
      main: '#ffffff'
    }
  },
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: '#034e5b',
      },
      colorTextPrimary: {
        color: '#ffffff',
        "@media (hover: hover)" : {
          '&:hover': {
            color: '#034e5b',
            cursor: 'pointer'
          }
        },
        "@media (hover: none)" : {
          '&:active': {
            cursor: 'pointer'
          }
        },
      },
      h6: {
        fontSize: '1.15rem',
        ["@media (min-width:1200px)"]: {
          fontSize: '1.3rem',
        }
      },
      h5: {
        fontSize: '1.9rem',
      },
      h2: {
        fontSize: 'clamp(2.5rem, calc(33px + 3vw), 3.5rem)'
      },
      h1: {
        fontSize: 'clamp(3rem, calc(33px + 3.5vw), 4.5rem)',
        display: 'inline-block',
        position: 'relative',
        zIndex: 5,
      },
      body1: {
        fontSize: 'clamp(1.5rem, 2vw, 3rem)',
        lineHeight: 1.2
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: 'clamp(.75rem, 1vw, 1.25rem)',
        color: 'white',
        width: '100%'
      },
      labelIcon: {
        minWidth: '0px'
      },
      textColorInherit: {
        opacity: 1
      }
    },
    MuiTabs: {
      root: {
        margin: 'auto'
      },
      fixed: {
        width: 'clamp(725px, 60vw, 1100px)'
      }
    },
    MuiSelect: {
      selectMenu: {
        background: 'none',
        width: '300px'
      },
      select: {
        '&:focus': {
          backgroundColor: 'none'
        }
      }

    },
    MuiPopover: {
      paper: {
        marginTop: '10px',
        maxHeight: '200px !important'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        background: 'none',
        backgroundColor: 'none'
      },
      root: {
        boxShadow: 'none'
      }
    },
    MuiAlert: {
      filledSuccess: {
        backgroundColor: '#034e5b'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#c1c1c1',
        '&.Mui-disabled': {
          color: '#c1c1c1'
        }
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked + $track':  {
          backgroundColor: '#9cd70a',
          opacity: 1
        },
      },
      track: {
        backgroundColor: 'rgb(183, 183, 183)',
      }
    },
    MuiFormControl: {
      root: {
        maxWidth: '300px',
        marginTop: '6px',
        marginBottom: '6px'
      },
      marginNormal: {
        marginTop: '6px',
        marginBottom: '6px'
      }
    },
    // MuiSvgIcon: {
    //   root: {
    //     height: '4em',
    //     width: '4em'
    //   }
    // },
    MuiInput: {
      root: {
        maxWidth: '300px'
      },
      underline: {
        '&:hover': {
          '&:before': {
            borderBottom: '2px solid #9cd70a !important'
          }
        },
        '&:before': {
          borderBottom: '2px solid rgba(0, 0, 0, 0.2)'
        },
        '&:after': {
          borderBottom: 'none'
        }
      }
    },
    MuiInputLabel: {
      formControl: {
        letterSpacing: '1px'
      }
    },
    MuiInputBase: {
      input: {
        minWidth: '300px',
        color: '#034e5b'
      },
      multiline: {
        maxWidth: 'none !important',
        width: '100%',
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '15px',
        fontSize: '1.4rem !important'
      },
      inputMultiline: {
        minWidth: '0px'
      }
    },
    MuiOutlinedInput: {
      multiline: {
        maxWidth: 'none !important',
        width: '100%',
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '15px',
        fontSize: '1.4rem !important'
      },
      root: {
        '&:hover': {
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#9cd70a !important'
          }
        }
      }
    },
    MuiToolbar: {
      regular: {
        padding: '0px',
        paddingTop: '13px',
        maxHeight: '70px',
        alignItems: 'unset'
      }
    },
    MuiContainer: {
      root: {
        overflow: 'auto',
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: 'white'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#034e5b',
        color: '#047588'
      }
    },
    MuiListItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      gutters: {
        paddingLeft: '40px',
        paddingRight: '40px'
      }
    },
    MuiListItemText: {
      root: {
        marginTop: '0px',
        marginBottom: '0px'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked':  {
          color: 'rgb(3, 78, 91) !important'
        }
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: '#9cd70a',
        color: 'white',
        boxShadow: 'none',
        "@media (hover: hover)" : {
          "&:hover": {
            backgroundColor: '#034e5b',
            color: 'white',
          }
        },
        "@media (hover: none)": {
          "&:active": {
            backgroundColor: '#034e5b',
            color: 'white',
          }
        }
      },
      outlined: {
        border: 'none !important',
      },
      outlinedSecondary: {
        "@media (hover: none)": {
          "&:active": {
            background: 'none !important',
          },
          "&:hover": {
            background: 'none !important',
          }
        }
      },
      label: {
        lineHeight: 'normal'
      },
      root: {
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#9cd70a',
        minWidth: '300px',
        borderRadius: '25px',
        "@media (hover: hover)" : {
          "&:hover": {
            backgroundColor: '#034e5b'
          },
        },
        '&:disabled': {
          color: 'white',
        },
        "@media (hover: none)": {
          "&:active": {
            backgroundColor: '#034e5b'
          },
          "&:hover": {
            backgroundColor: '#034e5b !important',
          }
        }
      },
      textSecondary: {
        color: 'white',
        "@media (hover: hover)": {
          '&:hover': {
            '& .MuiTypography-root': {
              color: '#034e5b !important'
            },
            '& .blue-background-hover': {
              backgroundColor: '#034e5b !important',
              border: '2px solid #034e5b !important'
            },
          },
        },
        "@media (hover: none)": {
          '&:active': {
            '& .MuiTypography-root': {
              color: '#034e5b !important'
            },
            '& .blue-background-hover': {
              backgroundColor: '#034e5b !important',
              border: '2px solid #034e5b !important'
            },
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#034e5b',
        "@media (hover: hover)": {
          '&:hover': {
            color: '#9cd70a',
            background: 'none',
            backgroundColor: 'none'
          }
        },
        "@media (hover: none)": {
          '&:active': {
            color: '#9cd70a',
            background: 'none',
            backgroundColor: 'none'
          }
        }
      },
      colorPrimary: {
        "@media (hover: hover)": {
          '&:hover': {
            color: '#034e5b',
            background: 'none',
            backgroundColor: 'none'
          }
        },
        "@media (hover: none)": {
          '&:active': {
            color: '#034e5b',
            background: 'none',
            backgroundColor: 'none'
          }
        }
      },
      colorSecondary: {
        color: '#034e5b',
        "@media (hover: hover)": {
          '&:hover': {
            color: 'white',
            background: 'none',
            backgroundColor: 'none'
          }
        },
        "@media (hover: none)": {
          '&:active': {
            color: 'white',
            background: 'none',
            backgroundColor: 'none'
          }
        },
      },
    }
  },
  typography: {
    fontFamily: 'Grotesk',
    fontSize: 14,
  }
});

export default theme;
