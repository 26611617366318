import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';

const faqs = [
  {
    question: 'Why should I vote?',
    answer: 'VOTUZA was created for you, to hold CEOs accountable for their lack of interest or response to poor customer service. VOTUZA gives you a voice by voting the worst in categories and specific customer service areas. CEOs will no longer sit in their ivory towers making millions off poor customer service, poor staff training and unfriendly company policies.'
  },
  {
    question: 'Why do I have to join to vote or comment?',
    answer: 'Creating a profile helps to confirm the user is an actual person when submitting a vote and complaint. This helps the company respond to you, take action specific to your customer service complaint and ultimately, improve their customer service for everyone.'
  },
  {
    question: 'Can I change my vote after I submit?',
    answer: 'Yes, you can change your vote for any answer submitted. Log into your profile and search for the category question you want to change, click on that page and choose a different worse company, add a comment and then hit submit.'
  },
  {
    question: 'Can I delete an answer?',
    answer: 'No, you cannot delete an answer. You can change the response to a different company and update comments if you change your mind after voting.'
  },
  {
    question: 'Will you send my complaint to the company?',
    answer: 'VOTUZA will make every attempt to submit votes and comments to the company and their executive management team. Personal identifying information is not shared unless you provide VOTUZA permission. VOTUZA publicly maintains and promotes a Worst List and Shame List of companies that continue to be voted worst and that have not taken action to resolve complaints.'
  },
  {
    question: 'Do you give my information to the company I complained about?',
    answer: 'VOTUZA only provides your information with your vote or comment, if you give us permission. You can select permission with each answer and a comment.'
  },
  {
    question: 'What do you do with my information?',
    answer: 'VOTUZA believes that each customer deserves an answer to their complaint. We support this by allowing you to add details about your complaint, and if you consent, we will connect you directly with the company for a response.'
  },
  {
    question: 'How do you protect my privacy?',
    answer: 'VOTUZA follows industry and all applicable legal standards to protect your data. Your personal information is only shared to companies if you consent at the point of submitting a comment or complaint.'
  },
  {
    question: 'How long will it take for me to get a response from the company?',
    answer: 'VOTUZA publicly maintains and promotes a Worst List and Shame List of companies that continue to be voted worst and that have not taken action to resolve complaints. We cannot guarantee a timeframe for a company response, but we will notify users of any specific request to resolve or contact VOTUZA users for more details about a complaint.'
  },
  {
    question: 'What if I don’t get a response from the company?',
    answer: 'VOTUZA will continue to maintain and promote companies that do not care to respond or take action to resolve customer complaints. Our sole focus is to hold those CEOs accountable for the level of poor customer service, so we will provide our vortal (platform) for users to continue to use their voices and comments to share their experiences to effect lasting change.'
  },
  {
    question: 'How is VOTUZA different from other review sites?',
    answer: 'VOTUZA is different because we remain loyal to the users who are fed up with the poor customer service and lack of company management response. You can also comment on your worst experience and the collective poll results clearly shows where companies are failing.'
  },
  {
    question: 'How can I change my profile information?',
    answer: 'You can edit most details in your profile by logging into your account, selecting Profile and updating each field. Contact info@VOTUZA.com for additional change requests.'
  },
  {
    question: 'Do you have a mobile app?',
    answer: 'It’s coming soon. For now, you can pull up VOTUZA.com from any mobile browser to log in and add your vote or comments.'
  },
  {
    question: 'Can I share my complaint on social media?',
    answer: 'Yes, and we encourage you to share! On any screen click the Share icon to share across many social networks as-is, or add your additional comment for your post. Be sure to tag us!'
  },
  {
    question: 'How can I add a new company or question?',
    answer: 'Thanks for asking, at the bottom of the Categories page, and for each answer you can add something new by clicking on Other, type in the info and then click Submit. We’ll review your note right away.'
  },
]

const companyFaqs = [
  {
    question: 'What is contained in your reports?',
    answer: 'Our detailed reports have everything a CEO needs to respond and make changes in their company. Compare your baseline with your competitors, and measure against key players in other industries.'
  },
  {
    question: 'How can a company be removed from your list?',
    answer: 'It’s simple - respond to your complaints, take action to permanently correct the situation and rise to the level of impeccable customer service every customer deserves!'
  },
  {
    question: 'How can a company resolve a customer complaint?',
    answer: 'Take the time to resolve a repeating complaint by changing your policies and procedures, contact VOTUZA to be connected with our users if they gave permission. Listen to your customers, sit in on phone calls, go on sales calls, mystery shop your own brand, review training materials, policies and procedures. Reward exceptionally good performance, counsel poor performance and publicly commit to sharing accountability as you update your organization.'
  }
]


class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div style={{  paddingTop: '50px' }} className='left-padding'>
            <div style={{ paddingBottom: '20px' }}>
              <Typography variant='h2' color='primary'>
                Frequently Asked Questions
              </Typography>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <div>
                <Typography variant='h4' color='primary'>
                  For Consumers
                </Typography>
                <List>
                  {faqs.map(faq => (
                    <Question faq={faq} />
                  ))}
                </List>
              </div>
              <div>
                <Typography variant='h4' color='primary'>
                  For Companies
                </Typography>
                <List>
                  {companyFaqs.map(faq => (
                    <Question faq={faq} />
                  ))}
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  toggleQuestion = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { faq } = this.props;
    const { open } = this.state;
    return (
      <ListItem style={{ marginBottom: '20px' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <Typography variant='h5' style={{ color: '#9cd70a', marginRight: '20px' }}>Q.</Typography>
            <Typography variant='h5' className='poll-button' color='textSecondary' style={{ color: open ? '#9cd70a' : '#034e5b'}} className='grey-h1-button' onClick={this.toggleQuestion}>
             {faq.question}
            </Typography>
          </div>
          {open &&
            <div style={{ marginLeft: '30px' }}>
              <Typography variant='h6' style={{ color: '#034e5b', marginLeft: '25px', marginTop: '10px' }}>
                {faq.answer}
              </Typography>
            </div>
          }
        </div>
      </ListItem>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(FAQ);
