import { getUserCookie } from './utils';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const login = (username, password) => fetch(`${SERVER_URL}/auth/login`, setAuthHeader('POST', { username, password }));
export const authenticate = () => fetch(`${SERVER_URL}/auth/authenticate`, setAuthHeader('POST'));
export const register = (email, password, first_name, last_name, zip_code, year_of_birth, gender, race, marketing_opt_in, income_bracket, marital_status, redirectUrl) => fetch(`${SERVER_URL}/auth/register`, setAuthHeader('POST', { email, password, income_bracket, marital_status, first_name, last_name, zip_code, year_of_birth, gender, race, marketing_opt_in, redirectUrl }));
export const registerFacebook = (access_token) => fetch(`${SERVER_URL}/auth/facebook/token?access_token=${access_token}`, setAuthHeader('POST'));
export const resetPassword = (password, passwordConfirm, jwt) => fetch(`${SERVER_URL}/auth/resetPassword`, setAuthHeader('POST', { password, passwordConfirm }, null, jwt));
export const verifyAccount = (jwt) => fetch(`${SERVER_URL}/auth/verifyAccount`, setAuthHeader('POST', null, null, jwt));
export const resetPasswordEmail = (email) => fetch(`${SERVER_URL}/auth/generateResetPassword`, setAuthHeader('POST', { email }));

export const getCategories = () => fetch(`${SERVER_URL}/categories`, setAuthHeader('GET'));
export const getCategory = (categoryId) => fetch(`${SERVER_URL}/category/${categoryId}`, setAuthHeader('GET'));
export const getPoll = (pollId) => fetch(`${SERVER_URL}/poll/${pollId}`, setAuthHeader('GET'));
export const getPolls = () => fetch(`${SERVER_URL}/polls`, setAuthHeader('GET'));
export const getPollsWithId = (categoryId) => fetch(`${SERVER_URL}/polls/${categoryId}`, setAuthHeader('GET'));
export const getFeaturedPolls = () => fetch(`${SERVER_URL}/featuredPolls`, setAuthHeader('GET'));
export const searchPolls = (searchText) => fetch(`${SERVER_URL}/polls/search?search='${searchText}'`, setAuthHeader('GET'));

export const postInquiry = (data) => fetch(`${SERVER_URL}/inquiry`, setAuthHeader('POST', data));
export const postFeedback = ({image, comment}) => fetch(`${SERVER_URL}/feedback`, setAuthHeader('POST', { image, comment }));
export const postPollResponse = (pollId, companyId, comment, categoryId, otherCompany, resolution) => fetch(`${SERVER_URL}/pollResponse`, setAuthHeader('POST', { poll: pollId, company: companyId, comment, category: categoryId, other_company: otherCompany, resolution } ));

export const updateUser = (user) => fetch(`${SERVER_URL}/user`, setAuthHeader('PUT', user ));

export const makeRequest = async (request, ...args) => {
  console.log(`making ${request.name} request`, SERVER_URL);
  return new Promise(function(resolve, reject) {
    let timer = setTimeout( () => reject( new Error('Request timed out') ), 20000 );
    request(...args)
    .then(async (response)=>{
      console.log('response:', response);
      if (response && (response.status === 201 || response.status === 204)){
        resolve();
        return;
      } else if (response && response.status >= 200 && response.status < 300){
        try{
          let responseJson = await response.json();
          console.log('responseJson', responseJson);
          resolve(responseJson)
        } catch(err){
          reject('Unable to parse json')
        }
      } else{

        if (response.status === 401){
          console.log('400 response - set to unauthenticated')
          reject('unauthenticated');
        } else if (response.status>299){
          try{
            let json_result = await response.json()
            if (json_result && json_result.error){
              reject(json_result.error)
            } else{
              reject('')
            }
          }
          catch(err){
            console.log('Unable to parse error. Check formatting of error response on server.');
            reject('')
          }
        } else {
          reject(`Did not receive response from ${request.name} request`)
        }
      }
    })
    .then((resJson)=>{
      if (!resJson){
        reject(`Empty JSON response from ${request.name} request`)
      } else{
        resolve(resJson)
      }
    })
    .catch((err)=>{
      reject(err)
    })
    .finally( () => clearTimeout(timer) );
  })
}

const setAuthHeader = (method, body, mode, token) => {
  let headers = {
    method: method,
    redirect: 'follow',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `JWT ${token || getUserCookie()}`
    }
  }
  console.log('headers:', headers);
  if (body){
    headers.body = JSON.stringify(body)
  }
  if (mode){
    headers.mode = mode
  }
  return headers
};
