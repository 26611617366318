import React, { Fragment } from 'react';
import { List, ListItem, ListItemText, Drawer, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { getUser } from "../../redux/selectors";
import LoginModal from '../Common/LoginModal';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MenuDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      loginModalOpen: false,
    }
  }

  toggleLoginModal = () => {
    this.setState({ loginModalOpen: !this.state.loginModalOpen })
  }

  toggleDrawer = () => {
    console.log('toggleDrawer')
    this.setState({ showDrawer: !this.state.showDrawer });
  }

  closeAlert = () => {
    this.setState({ alertOpen: false, alertMessage: null })
  }

  openAlert = (message) => {
    this.setState({ alertOpen: true, alertMessage: message })
  }

  render() {
    const { showDrawer, loginModalOpen, alertOpen, alertMessage } = this.state;
    const { user } = this.props;
    return (
      <Fragment>
        <div className='header-menu'>
          <button style={{ background: 'none', border: 'none', boxShadow: 'none'}} onClick={this.toggleDrawer}>
            <img src={require('../../assets/menu_icon.png').default} style={{height:'35px'}}/>
            <Typography variant='h6' color='textSecondary' style={{ lineHeight: .9, fontSize: '13px' }}>
              Menu
            </Typography>
          </button>
        </div>
        <Drawer
          anchor='right'
          open={showDrawer}
          onClose={this.toggleDrawer}
        >
          <List style={{ paddingTop: '20px' }}>
            {[{ display: 'Home', route: '' }, { display: 'About', route: 'about' }, { display: 'The Worst', route: 'worst' }, { display: 'Categories', route: 'categories' }, { display: 'Shame List', route: 'shamelist' }, { display: 'Sales', route: 'sales' }].map((text, index) => (
              <Link
                to={{
                  pathname: `/${text.route}`,
                }}
                style={{ width: '100%', textDecoration: 'none', color: '#047588' }}
              >
                <ListItem button key={text.display} onClick={this.toggleDrawer}>
                  <ListItemText primary={text.display} variant='h4' />
                </ListItem>
              </Link>
            ))}
            { user ?
              <Link
                to={{
                  pathname: `/profile`,
                }}
                style={{ width: '100%', textDecoration: 'none', color: '#047588' }}
              >
                <ListItem button key='profile' onClick={this.toggleDrawer}>
                  <ListItemText primary='Profile' variant='h4' />
                </ListItem>
              </Link>
              :
              <ListItem button key='login' onClick={this.toggleLoginModal}>
                <ListItemText primary='Login' variant='h4' />
              </ListItem>
            }
          </List>
        </Drawer>
        {loginModalOpen && <LoginModal showModal={loginModalOpen} showLogin={true} handleClose={this.toggleLoginModal} openAlert={this.openAlert}/>}
        <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.closeAlert} severity="success">
            {alertMessage}
          </Alert>
        </Snackbar>
      </Fragment >
    )
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default connect(mapStateToProps)(MenuDrawer);
