import React, { Component, Fragment, useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import { DrawableOverlay, useStage, useDrawableUtils, useDrawableState } from "react-drawable-overlay"
import { FormControlLabel, MenuItem, Menu, Checkbox, Snackbar, Modal, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core'
import Draggable from 'react-draggable';
import { HuePicker } from "react-color"
import { getUser } from "../../redux/selectors";
import Header from '../Header';
import TopCategories from './HomeHeader';
import TrendingPolls from './TrendingPolls';
import VotedWorst from './VotedWorst';
import MediaHeader from './MediaHeader';
import VotuzaForYou from './VotuzaForYou';
import * as api from '../../api';
import FeaturedCategories from './FeaturedCategories';
class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    const { user } = this.props;
    return (
      <Fragment>
        <TopCategories />
        <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
          <img id='people-img' src={require('../../assets/people_colored.png').default} style={{ width: '100%', alignSelf: 'flex-start', height: 'auto', zIndex: 10 }}/>
        </div>
        <VotuzaForYou />
        <FeaturedCategories />
        <div className='container-home' style={{ borderTop: '2px solid #034e5b', borderBottom: '2px solid #034e5b' }}>
          <div style={{ paddingBottom: '20px' }}>
            <div style={{ paddingBottom: '40px' }}>
              <Typography variant='h3' color='primary'>
                How your vote impacts change
              </Typography>
            </div>
              <img className="hide-750" src={require('../../assets/flowchart_whitebg.png').default} style={{ zIndex: 10, width: '100%' }}/>
              <img className="show-750" src={require('../../assets/flowchart_whitebg_mobile.png').default} style={{ zIndex: 10, width: '100%', display: 'none' }}/>
          </div>
        </div>
        <VotedWorst user={user} />
      </Fragment>
    )
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default connect(mapStateToProps)(Home);
