import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Poll from '../Poll';
import * as api from '../../api';

class VotedWorst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      polls: [],
      worst0Wrapped: false,
      worst1Wrapped: false,
      worst2Wrapped: false,
    }
  }

  componentWillMount = async () => {
    try {
      const { polls } = await api.makeRequest(api.getFeaturedPolls);
      console.log('polls:', polls);
      this.setState({ polls });
    } catch(e) {
      console.log('e:', e);
    }
  }

  goToCategories = () => {
    this.props.history.push({ pathname: '/categories' });
  }

  selectPoll = (poll) => {
    this.setState({ selectedPoll: poll });
  }

  closePoll = () => {
    this.setState({ selectedPoll: null });
  }

  updatePolls = (pollId, pollResponseBreakdown) => {
    const polls = this.state.polls.slice();
    const poll = polls.find(poll => poll._id === pollId);
    poll.pollResponseBreakdown = pollResponseBreakdown;
    this.setState({ polls: polls });
  }

  render() {
    const { polls, selectedPoll } = this.state;
    const { user } = this.props;
    return (
      <div className='full-grey'>
        <div className='container-home'>
          <div>
            <div className="voted-worst" style={{ paddingBottom: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h3' color='primary'>
                Voted Worst
              </Typography>
              <Button style={{ minWidth: '0px', paddingLeft: '25px', paddingRight: '25px', letterSpacing: '0.5px', height: '36.5px' }} onClick={this.goToCategories}>See Categories</Button>
            </div>
            <div className="mobile-flex-column-polls" style={{ display: 'flex', flex: 1, zIndex: 889, flexDirection: 'row' }}>
              {polls.map((poll, index) => {
                return (
                  <>
                    <div className="mobile-flex-column" style={{ display: 'flex', flex: 1, zIndex: 889, flexDirection: 'row', marginRight: '15px' }}>
                      <div style={{ borderBottom: '2px solid #034e5b', paddingBottom: '8px', width: '100%', display: 'flex', alignItems: 'end' }}>
                        <div id={`banner-${index}`} className='poll-button' onClick={() => this.selectPoll(poll)}>
                          <Typography variant='h7' color='textSecondary' style={{ lineHeight: 1.1, fontWeight: '700' }}>{poll.title}</Typography>
                        </div>
                      </div>
                    </div>
                     <div  className="mobile-display" style={{ display: 'none', flex: 1, zIndex: 889, flexDirection: 'row',  marginRight: '15px' }}>
                      <div style={{ marginTop: '20px' }}>
                        {poll.pollResponseBreakdown.slice(0,5).map((breakdown, index) => (
                          <div style={{ width: '100%' }}>
                            <Typography variant='h5' color={index === 0 ? 'primary' : 'textSecondary'} style={{ lineHeight: 1.1, fontWeight: '700', fontSize: '1.7rem' }}>{breakdown.company}</Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )
              })}
              </div>
              <div className="mobile-none" style={{ display: 'flex', flex: 1, zIndex: 889, flexDirection: 'row' }}>
                {polls.map((poll, index) => {
                  return (
                    <div style={{ display: 'flex', flex: 1, zIndex: 889, flexDirection: 'row',  marginRight: '15px' }}>
                      <div style={{ marginTop: '20px' }}>
                        {poll.pollResponseBreakdown.slice(0, 5).map((breakdown, index) => (
                          <div style={{ width: '100%' }}>
                            <Typography variant='h5' color={index === 0 ? 'primary' : 'textSecondary'} style={{ lineHeight: 1.1, fontWeight: '700', fontSize: '1.7rem' }}>{breakdown.company}</Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
        { selectedPoll && <Poll updatePolls={this.updatePolls} modal={true} open={true} completed={user && user.completedPolls.includes(selectedPoll._id)} poll={selectedPoll} close={this.closePoll} /> }
      </div>
    )
  }
}

export default withRouter(VotedWorst);

//<br/> &#8212; {poll.category.name}
