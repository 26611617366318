import React from 'react';
import { ClipLoader } from 'react-spinners';

export default function Loading({ offsetHeight }) {
  const offset = offsetHeight ? offsetHeight : '300'
  return (
    <div style={{ height: `calc(100vh - ${offset}px)`, display: 'flex' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader color='#034e5b' size={100} />
      </div>
    </div>
  )
}
