import React from 'react';
import { ClipLoader } from 'react-spinners';
import Modal from '@material-ui/core/Modal';

export default function LoadingOverlay() {
  return (
    <Modal open={true} style={{ height: '100vh' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <ClipLoader color='#034e5b' size={100} />
      </div>
    </Modal >
  )
}
