import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';

const messages = [
  "The Shame List is the list of companies that continue to be voted worst and that have not taken action to resolve complaints. VOTUZA will continue to highlight companies that do not care to respond or take action to resolve customer complaints.",
  "CEOs need to be held responsible",
  "CEOs need to hear directly from their customers",
  "CEOs need to accept their customer complaints as genuine and abundant",
  "CEOs need to be active in the customer service arena",
  "CEOs need to engage and better understand the interactions between their customers and their employees"
];
class NonResponders extends Component {
  constructor(props) {
    super(props);
    const categories = getCategoriesFromStorage() || [];
    this.state = {
      categories,
      openCategories: new Set()
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {

    }
  }

  toggleCategory = (categoryId) => {
    const updatedOpenCategories = new Set(this.state.openCategories);
    if (updatedOpenCategories.has(categoryId)) {
      updatedOpenCategories.delete(categoryId);
    } else {
      updatedOpenCategories.add(categoryId)
    }
    this.setState({ openCategories: updatedOpenCategories });
  }

  render() {
    const { categories } = this.state;
    console.log('render NonResponders:', categories);
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div className='left-padding' style={{  paddingTop: '50px' }}>
            <div style={{ paddingBottom: '20px' }}>
              <Typography variant='h2' color='primary'>
                Shame List
              </Typography>
            </div>
            <div className="introduction-holder" style={{ display: 'flex' }}>
              <div style={{ flex: 7}}>
                <div style={{ marginBottom: '20px', marginRight: '20px' }}>
                  <Typography variant='h7' color='textSecondary' style={{ lineHeight: 1, fontSize: '1.1rem', fontWeight: '700' }}>
                    Coming Soon! You’ll see the company names that have not taken action to resolve VOTUZA customer complaints and worst ratings.
                  </Typography>
                </div>
                {categories.slice(0,14).map(category => (
                  <Category category={category} />
                ))}
              </div>
              <div style={{ flex: 3}}>
                <Typography variant='h6' color='primary' style={{ lineHeight: 1 }}>
                  What is the Shame List?
                </Typography>
                {messages.map(message => (
                  <div style={{  marginTop: '15px' }}>
                    <Typography variant='h7' color='textSecondary' style={{ lineHeight: 1, fontSize: '1.1rem', fontWeight: '700' }}>
                      {message}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div>
              {categories.slice(14,categories.length).map(category => (
                <Category category={category} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      companies: props.category.companies.slice().filter(company => !company.responder)
    }
  }

  toggleCategory = () => {
    if (this.state.companies.length > 0) {
      this.setState({ open: !this.state.open })
    }
  }

  render() {
    const { category } = this.props;
    const { open, companies } = this.state;
    const sortedCompanies = companies.filter((company) => company.total_active_votes > 100 && company.responder === false).sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    console.log('companies:', companies);
    const categoryStyle = { lineHeight: 1, display: 'inline-block' };
    if (open) {
      categoryStyle.color = '#9cd70a';
    }
    console.log('categoryStyle:', categoryStyle);
    return (
      <div>
        <Typography variant='h3' className='grey-h1-button text-grey' style={categoryStyle} onClick={this.toggleCategory}>
          {category.name}
        </Typography>
        {open &&
          <div>
            {sortedCompanies.map(company => (
              <Typography variant='h4' style={{ lineHeight: 1, color: '#034e5b' }}>
                {company.name}
              </Typography>
            ))}
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(NonResponders);
