import Cookies from 'universal-cookie';
const cookies = new Cookies();

function _set_local_storage(key, value){
  window.localStorage.setItem(key, JSON.stringify(value))
}

function _check_local_storage(key){
  let value = window.localStorage.getItem(key)
  return JSON.parse(value);
}

export const getUserCookie = () => {
  return cookies.get('votuzaUserCookie');
};

export const getVotuzaLandingCookie = () => {
  return cookies.get('votuzaLandingSeen');
}

export const getVotuzaNdaCookie = () => {
  return cookies.get('votuzaNdaCookie');
}

export const getVotuzaLandingAnimationCookie = () => {
  return cookies.get('votuzaLandingAnimationSeen');
}

export const getCategoriesFromStorage = () => {
  return _check_local_storage('categories');
};

export const setCategoriesToStorage = (categories) => {
  _set_local_storage('categories', categories);
}

export const getPollsFromStorage = (categoryId) => {
  return _check_local_storage(categoryId);
};

export const setPollsToStorage = (categoryId, polls) => {
  _set_local_storage(categoryId, polls);
}

// export const updatePollResultsToStorage = (categoryId, pollId, pollResponseBreakdown) => {
//   const polls = _check_local_storage(categoryId);
//   const poll = polls.find(poll => poll._id === pollId);
//   poll.pollResponseBreakdown = pollResponseBreakdown;
//   _set_local_storage(categoryId, polls);
// }
