import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import GifPlayer from 'react-gif-player';

export default function LandingAnimation({ close }){
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHidden(true);
      setTimeout(() => {
        close();
      }, 1000);
    }, 6000)
  }, []);
  return (
    <Modal
      open={true}
      BackdropProps={{
        style: { backgroundColor: '#f6f6f6'}
      }}
      style={{ overflowY: 'scroll' }}
      className={hidden ? 'hidden' : ''}
    >
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        <GifPlayer gif={require('../../assets/votuza_animate.gif').default} autoplay={true} />
      </div>
    </Modal>
  )
}

//    <img src={require('../../assets/votuza_animate.gif').default}/>
