import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import ContactForm from '../Common/ContactForm';
import ShareSocialButtons from '../Common/ShareSocialButtons';

export default function Footer() {
  const [showContact, setShowContact] = useState(false)
  return (
      <div style={{ background: 'none', textAlign: 'center', marginTop: '20px', paddingTop: '50px', paddingBottom: '40px', position: 'relative' }}>
        <ShareSocialButtons orientation="horizontal" />
        <Typography variant='h7' color="textSecondary" style={{ lineHeight: 1, fontSize: '1rem', fontWeight: '500', zIndex: 990 }}>
          &copy; 2022-{new Date().getFullYear()} VOTUZA | &nbsp;
          <span><Link to={{ pathname: "/terms-of-use" }} style={{ textDecoration: 'underline', color: 'inherit', zIndex: 990 }} className='pointer'>Terms of Use</Link></span>
           &nbsp; | &nbsp;
          <span><Link to={{ pathname: "/privacy" }} style={{ textDecoration: 'underline', color: 'inherit', zIndex: 990  }} className='pointer'>Privacy</Link></span>
          &nbsp; | &nbsp;
          <span><Link to={{ pathname: "/faq" }} style={{ textDecoration: 'underline', color: 'inherit', zIndex: 990  }} className='pointer'>FAQs</Link></span>
          &nbsp; | &nbsp;
          <span onClick={()=>{setShowContact(true)}} style={{ textDecoration: 'underline', color: 'inherit', zIndex: 990  }} className='pointer'>Contact</span>
          {showContact && <ContactForm isModal={true} close={()=>{setShowContact(false)}} />}
        </Typography>
      </div>
  )
}
