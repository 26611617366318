import React, { Fragment } from 'react';
import { FormControlLabel, MenuItem, Menu, Checkbox, Snackbar, Modal, Typography, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';

const reasons = ["My account", "Sales reporting", "Press/media", "Other"]

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class ContactForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email: '',
      phoneNumber: '',
      name: '',
      submitLoading: false,
      comment: '',
      alertOpen: false,
      showReasons: '',
      industry: null,
      company: null,
      categories: getCategoriesFromStorage() || [],
      reason: props.reason || null,
      reasonRef: React.createRef(),
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {

    }
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  handlePhoneNumber = (e) => {
    this.setState({ phoneNumber: e.target.value });
  }

  handleName = (e) => {
    this.setState({ name: e.target.value });
  }

  handleComment = (e) => {
    this.setState({ comment: e.target.value });
  }

  handleSubmit = async () => {
    try {
      if (!this.state.reason) {
        alert("Must select a reason");
        return;
      }
      this.setState({ submitLoading: true });
      await api.makeRequest(api.postInquiry, { email: this.state.email, phone_number: this.state.phoneNumber, name: this.state.name, message: this.state.comment, reason: this.state.reason, industry: this.state.industry, company: this.state.company });
      this.setState({ alertOpen: true, email: '', phoneNumber: '', name: '', comment: '', reason: null, submitLoading: false})
    } catch(e) {
      alert('There was an issue submitting your inquiry');
    }
    //postInquiry
  }

  closeAlert = () => {
    this.setState({ alertOpen: false })
  }

  toggleReasons = () => {
    this.setState({ showReasons: !this.state.showReasons });
  }

  selectReason = (e) => {
    this.setState({ reason: e.target.value });
  }

  selectIndustry = (e) => {
    this.setState({ industry: e.target.value });
  }

  selectCompany = (e) => {
    this.setState({ company: e.target.value });
  }

  closeModal = () => {
    console.log('closeModal')
    this.props.close();
  }

  render() {
    const disabled = !this.state.email || !this.state.name || !this.state.comment || !this.state.reason;
    const { showSalesInputs } = this.props;
    if (this.props.isModal) {
      return (
        <Modal
          open={true}
          onClose={this.closeModal}
          className='modal-container'
          disableAutoFocus={true}
          disableEnforceFocus={true}
          BackdropProps={{
            invisible: false
          }}
        >
          <div style={{backgroundColor: '#f6f6f6', position: 'relative', display: 'flex', justifyContent: 'center', flexDirection:'column', border: '2px solid #034e5b', maxWidth: '90%', minWidth: '300px', borderRadius: '20px', overflow: 'hidden', position: 'relative'}}>
            <div style={{ position: 'absolute', left: '0px', top: '0px'}}>
              <IconButton
                onClick={this.closeModal}
                color="primary"
                size="medium"
              >
                <CloseIcon style={{ height: '2.5em', width: '2.5em'}}/>
              </IconButton>
            </div>
            <div className="mobile-form" style={{display: 'flex', maxWidth: '600px', paddingLeft: '60px', paddingRight: '60px', paddingTop: '25px', paddingBottom: '50px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white' }}>
              <Typography variant='h5' color="textSecondary">Get in Touch</Typography>
              <TextField
                id="standard-basic"
                label="Name*"
                type="name"
                name="name"
                variant="standard"
                value={this.state.name}
                onChange={this.handleName}
                disabled={this.state.submitLoading}
              />
              <TextField
                id="standard-basic"
                label="Email*"
                type="email"
                name="email"
                variant="standard"
                value={this.state.email}
                onChange={this.handleEmail}
                disabled={this.state.submitLoading}
              />
              <TextField
                id="standard-basic"
                select
                label="Reason*"
                type="text"
                name="reason"
                variant="standard"
                value={this.state.reason}
                onChange={this.selectReason}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }
                }}
              >
                {reasons.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </TextField>
              <div style={{ marginTop: '10px' }}>
                <TextField
                   multiline
                   inputProps={{ style: {
                     fontSize: '1rem',
                   }}}
                   InputProps={{ style: {
                     padding: '20px',
                     width: '100%',
                     minWidth: '300px'
                   }}}
                   label="Message"
                   variant='outlined'
                   size="small"
                   value={this.state.comment}
                   onChange={this.handleComment}
                   rows={8}
                   placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                 />
               </div>
               <Button disabled={disabled} onClick={this.handleSubmit} style={{ marginTop: '40px', minWidth: '0px', paddingLeft: '50px', paddingRight: '50px', letterSpacing: '0.5px', height: '36.5px' }}>Submit</Button>
            </div>
            <Snackbar open={this.state.alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert onClose={this.closeAlert} severity="success">
                Your inquiry has been received
              </Alert>
            </Snackbar>
          </div>
        </Modal>
      )
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h4' color="primary" style={{ marginBottom: '20px' }}>Get in Touch</Typography>
        <div className="mobile-form" style={{display: 'flex', maxWidth: '600px', paddingLeft: '50px', paddingRight: '50px', paddingTop: '25px', paddingBottom: '50px', border: '2px solid #c1c1c1', borderRadius: '20px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white' }}>
          <TextField
            id="standard-basic"
            label="Name*"
            type="name"
            name="name"
            variant="standard"
            value={this.state.name}
            onChange={this.handleName}
            disabled={this.state.submitLoading}
          />
          <TextField
            id="standard-basic"
            label="Email*"
            type="email"
            name="email"
            variant="standard"
            value={this.state.email}
            onChange={this.handleEmail}
            disabled={this.state.submitLoading}
          />
          {
            showSalesInputs &&
            <Fragment>
              <TextField
                id="standard-basic"
                select
                label="Industry (optional)"
                type="text"
                name="industry"
                variant="standard"
                value={this.state.industry}
                onChange={this.selectIndustry}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }
                }}
              >
                {this.state.categories.map(item => (
                  <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                ))}
                <MenuItem key='other-category' value='other'>Other</MenuItem>
              </TextField>
              <TextField
                id="standard-basic"
                select
                label="Company (optional)"
                type="text"
                name="company"
                variant="standard"
                value={this.state.company}
                disabled={!this.state.industry || this.state.industry === 'other'}
                onChange={this.selectCompany}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }
                }}
              >
                {this.state.industry && this.state.industry !== 'other' && this.state.categories.find(cat => cat.name === this.state.industry).companies.map(item => (
                  <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                ))}
                <MenuItem key='other-company' value='other'>Other</MenuItem>
              </TextField>
            </Fragment>
          }
          <TextField
            id="standard-basic"
            select
            label="Reason*"
            type="text"
            name="reason"
            variant="standard"
            value={this.state.reason}
            onChange={this.selectReason}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }
            }}
          >
            {reasons.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </TextField>
          <div style={{ marginTop: '10px' }}>
            <TextField
               multiline
               inputProps={{ style: {
                 fontSize: '1rem',
               }}}
               InputProps={{ style: {
                 padding: '20px',
                 width: '100%',
                 minWidth: '300px'
               }}}
               label="Message"
               variant='outlined'
               size="small"
               value={this.state.comment}
               onChange={this.handleComment}
               rows={8}
               placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
             />
           </div>
           <Button disabled={disabled} variant="contained" onClick={this.handleSubmit} style={{ marginTop: '40px', minWidth: '0px', paddingLeft: '50px', paddingRight: '50px', letterSpacing: '0.5px', height: '36.5px' }}>Submit</Button>
        </div>
        <Snackbar open={this.state.alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.closeAlert} severity="success">
            Your inquiry has been received
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
