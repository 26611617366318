import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { IconButton, FormControlLabel, Checkbox } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router-dom';
import { getUser } from '../../redux/selectors';
import { boundAddCompletedPoll } from '../../redux/actions';
import Loading from '../Common/Loading';
import LoginModal from '../Common/LoginModal';
import ShareModal from '../Common/ShareModal';
import LoadingOverlay from '../Common/LoadingOverlay';
import ProfileFieldsForm from '../Common/ProfileFieldsForm';
import { PieChart } from 'react-minimal-pie-chart';
import ShareSocialButtons from '../Common/ShareSocialButtons';
import ReactTooltip from "react-tooltip";
import * as api from '../../api';

const { REACT_APP_CLIENT_URL, REACT_APP_SERVER_URL } = process.env

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const colors = ['blue', 'red', 'yellow', 'darkblue', 'pink', 'darkgreen', 'purple', 'orange', 'brown', 'white']

class Poll extends Component {
  constructor(props) {
    console.log('category props:', props);
    super(props);
    if (props.modal) {
      props.history.push(props.history.location.pathname + props.history.location.search);
    }
    if (props.poll) {
      props.poll.category.companies = props.poll?.category?.companies.sort((x, y) => {
        if(x.name < y.name) { return -1; }
        if(x.name > y.name) { return 1; }
        return 0;
      }) || [];
    }
    this.state = {
      poll: props.poll,
      loading: props.poll && (!props.completed || props.poll.pollResponseBreakdown) ? false : true,
      selectedCompany: props.completed ? props.user.pollResponses.find(pollResponse => {
        if (pollResponse.current && pollResponse.poll._id == props.poll._id) {
          return true
        }
        return false
      }).company : null,
      comment: null,
      loginModalOpen: false,
      alertOpen: false,
      alertMessage: null,
      submitLoading: false,
      showResults: props.completed,
      pollResults: props.completed ? props.poll.pollResponseBreakdown : null,
      showShareModal: false,
      commentBoxEnabled: false,
      otherCompany: '',
      resolution: null,
    }
  }

  // componentDidMount() {    
  //   if(this.state.open){
  //     document.body.style.overflow = 'hidden';
  //   }    
  // }
  
  // componentWillUnmount() {
  //     document.body.style.overflow = 'unset';
  // }

  componentDidUpdate(){
    window.onpopstate = e => {
       this.goBack();
    }
  }

  componentWillMount = async () => {
    if (!this.state.poll) {
      const { id: pollId } = this.props.computedMatch.params;
      console.log('get poll:', pollId);
      const { poll } = await api.makeRequest(api.getPoll, pollId);
      // // const { polls } = await api.makeRequest(api.getPollsWithId, categoryId);
      // console.log('poll:', poll);
      let completed = false;
      let selectedCompany;
      if(this.props.user && this.props.user.completedPolls.includes(poll._id)) {
        completed = true;
        selectedCompany = this.props.user.pollResponses.find(pollResponse => {
          if (pollResponse.current && pollResponse.poll._id == poll._id) {
            return true
          }
          return false
        }).company
      }
      poll.category.companies = poll?.category?.companies.sort((x, y) => {
        if(x.name < y.name) { return -1; }
        if(x.name > y.name) { return 1; }
        return 0;
      }) || [];
      this.setState({ loading: false, poll, showResults: completed, pollResults: poll.pollResponseBreakdown, selectedCompany: selectedCompany });
    } else if (this.state.showResults && !this.state.pollResults) {
      const { poll } = await api.makeRequest(api.getPoll, this.state.poll._id);
      this.setState({ loading: false, pollResults: poll.pollResponseBreakdown });
    }
  }

  // selectPoll = (poll) => {
  //   this.props.history.push({ pathname: `/poll/${poll._id}` });
  // }

  goBack = () => {
    if (this.props.close) {
      this.props.close();
    } else {
      if (!this.state.loading) {
        this.props.history.push({ pathname: `/` });
      }
    }
    // this.props.history.push({ pathname: '/categories' });
    // this.props.history.goBack();
    // console.log(this.props.history)
  }

  toggleShareModal = () => {
    this.setState({
      showShareModal: !this.state.showShareModal
    })
  }

  submitVote = async () => {
    if (!this.props.user) {
      this.setState({ loginModalOpen: true });
    } else if (!this.state.selectedCompany) {
      alert('Select company to submit vote');
    }
    else {
      if(!this.props.user.terms_acceptance) {
        this.setState({ profileFieldsFormOpen: true });
        return;
      }
      this.setState({ submitLoading: true });
      try {
        const result = await api.makeRequest(api.postPollResponse, this.state.poll._id, this.state.selectedCompany._id, this.state.comment, this.state.poll.category._id, this.state.otherCompany, this.state.resolution);
        console.log('result:', result);
        this.setState({ submitLoading: false, pollResults: result, showResults: true }, () => {
          setTimeout( () => {
            document.getElementById('modal').scrollTop = 0;
          }, 0)
        });
        if (this.props.user.user_type === 'user') {
          boundAddCompletedPoll(this.state.poll._id, { poll: this.state.poll, comment: this.state.comment, company: this.state.selectedCompany, current: true });
        }
        if (this.props.updatePolls) {
          this.props.updatePolls(this.state.poll._id, result);
        }
        this.openAlert('Submission receieved');
      } catch(e) {
        console.log('e:', e);
        alert(`Poll submission failed: ${e}`);
        this.setState({ submitLoading: false })
      }
    }
  }


  selectCompany = (company) => {
    this.setState({ selectedCompany: company })
  }

  closeProfileFieldsForm = () => {
    this.setState({ profileFieldsFormOpen: false })
  }

  changeComment = (e) => {
    this.setState({ comment: e.target.value })
  }

  changeResolution = (e) => {
    this.setState({ resolution: e.target.value })
  }

  closeLoginModal = (user) => {
    this.setState({ loginModalOpen: false, profileFieldsFormOpen: user && Boolean(!user.terms_acceptance) })
  }

  closeAlert = () => {
    this.setState({ alertOpen: false, alertMessage: null })
  }

  openAlert = (message) => {
    this.setState({ alertOpen: true, alertMessage: message })
  }

  goToPoll = () => {
    this.setState({ showResults: false, pollResult: null, comment: null, selectedCompany: null }, () => {
      document.getElementById('modal').scrollTop = 0;
    })
  }

  toggleCommentBoxEnabled = () => {
    this.setState({ commentBoxEnabled: !this.state.commentBoxEnabled })
  }

  copyShareLink = () => {
    this.openAlert("Share link copied to clipboard")
  }

  render() {
    const {
      loading,
      poll,
      selectedCompany,
      comment,
      loginModalOpen,
      alertOpen,
      alertMessage,
      submitLoading,
      showResults,
      pollResults,
      showShareModal,
      commentBoxEnabled,
      profileFieldsFormOpen,
      resolution,
    } = this.state;
    const {
      open=true,
      close,
    } = this.props;
      console.log('poll:', poll);
      console.log('props:', this.props);
      console.log('state:', this.state);
      console.log('selectedCompany:', selectedCompany);
      const sortedCompanies = poll?.category?.companies
      // .sort((x, y) => {
      //   if(x.name < y.name) { return -1; }
      //   if(x.name > y.name) { return 1; }
      //   return 0;
      // }) || [];
      // console.log('sortedCompanies:', sortedCompanies);
      const limitedPollResults = [];
      if (pollResults) {
        limitedPollResults.push(...pollResults.slice(0, 9));
      }
      if (showResults && pollResults && pollResults.length > 9) {
        const others = {
          company: 'Other',
          count: 0,
          percent: 0,
        };
        pollResults.slice(9, pollResults.length).forEach(result => {
          if (result.company === selectedCompany.name) {
            selectedCompany.name = 'Other';
          };
          others.count+=result.count;
          others.percent+=result.percent
        });
        limitedPollResults.push(others);
      }
      return (
        <Fragment>
          <Modal
            open={open}
            onClose={close}
            style={{ overflowY: 'scroll', zIndex: 1200 }}
            BackdropProps={{
              style: { background: 'linear-gradient(to right bottom, #bad409, #84d90a)'}
            }}
            id='modal'
            disableAutoFocus={true}
            disableEnforceFocus={true}
          >
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
              <div style={{ position: 'relative', width: '100%', minHeight: '100vh' }}>
                <div style={{ position: 'absolute', left: '20px', top: '20px', zIndex: 1000 }}>
                  <IconButton
                    onClick={this.goBack}
                    color="secondary"
                    size="large"
                  >
                    <CloseIcon style={{ height: '3.5em', width: '3.5em' }}/>
                  </IconButton>
                </div>
                <div className='container' style={{ overflow: 'none' }}>
                  { loading ?
                    <Loading />
                    :
                    <Fragment>
                      {!showResults ?
                        <div style={{ marginBottom: '60px' }}>
                          <div style={{ width: '90%', margin: 'auto' }}>
                            <Typography variant='h4' style={{ marginTop: '110px', color: 'white' }}>{poll.title}</Typography>
                            <div className="mobile-flex-column-polls" style={{ display: 'flex', marginTop: '50px', justifyContent: 'space-between' ,gap:'1rem' }}>
                              <div style={{ flex: 1 }}>
                                {sortedCompanies.slice(0, Math.ceil((sortedCompanies.length + 1) / 2)).map((company, index) => (
                                  <CompanyOption company={company} index={index + 1} selected={selectedCompany && selectedCompany._id === company._id} selectCompany={this.selectCompany} />
                                ))}
                              </div>
                              <div style={{ flex: 1 }}>
                                {sortedCompanies.slice(Math.ceil((sortedCompanies.length + 1) / 2), sortedCompanies.length).map((company, index) => (
                                  <CompanyOption company={company} index={index + 1 + Math.ceil((sortedCompanies.length + 1) / 2)} selected={selectedCompany && selectedCompany._id === company._id} selectCompany={this.selectCompany} />
                                ))}
                                <div style={{ marginBottom: '30px' }}>
                                  <Button style={{ background: 'none', width: 'fit-content', minWidth: '0px', padding: '0px' }} color='secondary' disableRipple={true} onClick={() => this.selectCompany('other')}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div className='blue-background-hover' style={{ borderRadius: '50%', minWidth: '35px', display: 'flex', backgroundColor: selectedCompany && selectedCompany === 'other' ? '#034e5b' : '', justifyContent: 'center', alignItems: 'center', minHeight: '35px', position: 'relative', border: `2px solid ${selectedCompany && selectedCompany === 'other' ? '#034e5b' : 'white'}`, marginRight: '12px' }}>
                                        <div style={{ margin: '0px', padding: '0px' }}>
                                          +
                                        </div>
                                      </div>
                                      <Typography variant='h5' className='blue-color-hover' style={{ color: selectedCompany && selectedCompany === 'other' ? '#034e5b' : 'white', textAlign: 'left' }}>Other:</Typography>
                                      <div>
                                        <input onChange={(e) => {this.setState({ otherCompany: e.target.value })}} className='company-other' style={{ background: 'none', border: 'none', borderBottom: '2px solid white', marginLeft: '10px', height: '40px', '-webkit-appearance': 'none' }}/>
                                      </div>
                                    </div>
                                  </Button>
                                </div>

                              </div>
                            </div>
                            <div style={{  marginTop: '20px', marginBottom: '20px', justifyContent: 'space-between' }}>
                              <div style={{ flex: 1 }}>
                                <Typography variant='h4' style={{ color: '#034e5b' }}>Leave a Complaint</Typography>
                              </div>
                              <div style={{ flex: 1 }}>
                                <p style={{ color: '#034e5b'}}>
                                  Please feel free to leave a complaint related to this question. We will do our best to get all comments and feedback to the appropriate companies related to the question. If you have a login with Votuza.com, various companies may respond to you by email to help resolve your concern. We encourage you to vote and add a complaint or comment for every company and customer service issue you’ve experienced as a consumer, regardless if it was 30 minutes or 30 years ago.
                                </p>
                              </div>
                              <div style={{ flex: 1 }}>
                                <FormControlLabel
                                   control={
                                     <Checkbox checked={this.state.commentBoxEnabled} color="secondary" onChange={this.toggleCommentBoxEnabled} name="Terms" />
                                   }
                                   style={{ color: '#034e5b', fontSize: '15px', letterSpacing: '1px' }}
                                   label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <Typography style={{ color: '#034e5b', fontSize: '15px', letterSpacing: '1px' }}>
                                        <span>I consent to companies receiving my personal information.</span>
                                      </Typography>
                                      <a
                                        data-for="info-tooltip"
                                        data-tip="VOTUZA collects your personal information to better analyze various demographic data related to each consumer for the purpose of market research."
                                      >
                                      <InfoIcon id="info-tooltip" />
                                      </a>
                                      <ReactTooltip
                                        className='tooltip'
                                        id="info-tooltip"
                                        backgroundColor='#034e5b'
                                        effect="solid"
                                      />
                                    </span>
                                    }
                                 />
                              </div>
                            </div>
                          </div>
                          <div style={{ marginBottom: '40px' }}>
                            <TextField
                               multiline
                               disabled={!commentBoxEnabled}
                               variant='outlined'
                               value={comment}
                               onChange={this.changeComment}
                               style={{ maxWidth: 'none', width: '100%' }}
                               rows={5}
                               placeholder="File a compaint . . ."
                               helperText="unlimited characters"
                             />
                            <TextField
                               multiline
                               disabled={!commentBoxEnabled}
                               variant='outlined'
                               value={resolution}
                               onChange={this.changeResolution}
                               style={{ maxWidth: 'none', width: '100%' }}
                               rows={5}
                               placeholder="What resolution are you requesting?"
                               helperText="unlimited characters"
                             />
                          </div>
                          <div className="mobile-center" style={{ width: '90%', margin: 'auto' }} >
                            <Button disabled={!selectedCompany} style={{ minWidth: '0px', paddingLeft: '60px', paddingRight: '60px', letterSpacing: '0.5px', height: '36.5px', backgroundColor: '#034e5b' }} onClick={this.submitVote}>Submit Your Vote</Button>
                          </div>
                        </div>
                        :
                        <div style={{ marginBottom: '60px' }}>
                          <div style={{ width: '90%', margin: 'auto' }}>
                            <Typography variant='h3' style={{ marginTop: '110px', color: 'white' }}>{poll.title}</Typography>
                          </div>
                          <div style={{ width: '95%', minHeight: '450px', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', marginTop: '50px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{ flex: 1, minWidth: '280px' }}>
                              <ResultsChart pollResults={limitedPollResults} goToPoll={this.goToPoll} />
                            </div>
                            <div style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'space-between', minWidth: '280px' }}>
                              <div style={{ margin: 'auto', marginTop: '0px', width: 'fit-content' }}>
                                {limitedPollResults.map((pollResult, index) => (
                                  <ResultKey pollResult={pollResult} index={index} selectedCompany={selectedCompany} />
                                ))}
                              </div>
                              {/* <div style={{ display: 'flex', justifyContent: 'center' }} className='hide-750'>
                                <Typography onClick={this.toggleShareModal} variant='h7' color="textSecondary" className="pointer" style={{ display: 'flex', fontWeight: '700', fontSize: '1.25em' }}>Embed | Share &nbsp; <img style={{ fill: '#034e5b', height: '25px' }} src={require("../../assets/share_icon.svg").default}/></Typography>
                              </div> */}
                            </div>
                          </div>
                          <div style={{ display: 'none' }} className='show-750'>
                            <div style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
                              <Button style={{ minWidth: '0px', paddingLeft: '60px', paddingRight: '60px', letterSpacing: '0.5px', height: '36.5px', backgroundColor: '#034e5b' }} onClick={this.goToPoll}>Submit a New Vote</Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Typography onClick={this.toggleShareModal} variant='h7' color="textSecondary" className="pointer" style={{ display: 'flex', fontWeight: '700', fontSize: '1.25em' }}>Embed | Share &nbsp; <img style={{ fill: '#034e5b', height: '25px' }} src={require("../../assets/share_icon.svg").default}/></Typography>
                            </div>
                          </div>
                        </div>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            </div>
          </Modal>
          <ShareSocialButtons shareUrl={`${REACT_APP_SERVER_URL}/share/poll/${poll?._id}`} zIndex={1250}/>
          <ShareModal isOpen={showShareModal} onClose={this.toggleShareModal} copyShareLink={this.copyShareLink} shareUrl={`${REACT_APP_SERVER_URL}/share/poll/${poll?._id}`} />
          {submitLoading && <LoadingOverlay />}
          {loginModalOpen && <LoginModal showModal={loginModalOpen} showLogin={false} handleClose={this.closeLoginModal} openAlert={this.openAlert} pollId={poll._id}/>}
          {profileFieldsFormOpen && <ProfileFieldsForm user={this.props.user} handleClose={this.closeProfileFieldsForm} openAlert={this.openAlert} />}
          <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.closeAlert} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </Fragment>
      )
    }
}

const CompanyOption = ({ company, index, selected, selectCompany }) => (
  <div style={{ marginBottom: '30px' }}>
    <Button variant="outlined" style={{ background: 'none', width: 'fit-content', minWidth: '0px', padding: '0px' }} color='secondary' disableRipple={true} onClick={() => selectCompany(company)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='blue-background-hover' style={{ borderRadius: '50%', minWidth: '35px', display: 'flex', backgroundColor: selected ? '#034e5b' : '', justifyContent: 'center', alignItems: 'center', minHeight: '35px', position: 'relative', border: `2px solid ${selected ? '#034e5b' : 'white'}`, marginRight: '12px' }}>
          <div style={{ margin: '0px', padding: '0px' }}>
            {index}
          </div>
        </div>
        <Typography variant='h5' className='blue-color-hover' style={{ color: selected ? '#034e5b' : 'white', textAlign: 'left' }}>{company.name}</Typography>
      </div>
    </Button>
  </div>
)

const ResultsChart = ({ pollResults, goToPoll }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
    <div style={{ position: 'relative', height: '90%', maxHeight: '380px' }}>
      <PieChart
        animate={true}
        lineWidth={20}
        data={pollResults.map((result, index) => ({
          value: result.count,
          color: colors[index]
        }))}
      />
      <div className='centered'>
        <Typography variant='h6' color="textSecondary">Current Poll Results</Typography>
      </div>
    </div>
    <div style={{ marginTop: '40px', textAlign: 'center'}} className='hide-750'>
      <Button style={{ minWidth: '0px', paddingLeft: '60px', paddingRight: '60px', letterSpacing: '0.5px', height: '36.5px', backgroundColor: '#034e5b' }} onClick={goToPoll}>Submit a New Vote</Button>
    </div>
  </div>
)

const ResultKey = ({ pollResult, index, selectedCompany }) => (
  <div style={{ marginBottom: '10px', display: 'flex' }}>
    <div>
      <div style={{ backgroundColor: colors[index], width: '100px', height: '6px', marginBottom: '3px' }}/>
      <Typography variant='h7' color="textSecondary" style={{ display: 'flex', fontWeight: '700' }}>{pollResult.company} {Math.round(pollResult.percent * 1000) / 10}%</Typography>
    </div>
    {selectedCompany?.name === pollResult.company &&
      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginLeft: '10px' }}>
        <CheckCircleIcon style={{ fill: '#034e5b' }}/>
      </div>
    }
  </div>
)

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(Poll));
