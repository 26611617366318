import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, Modal, Typography, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import Icon from '@material-ui/core/Icon';
import { login, register, registerFacebook, attemptAuthentication } from '../../auth';
import {
  boundUpdateUser
} from "../../redux/actions";
import * as api from '../../api';

export default class ProfileFieldsForm extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const years = [];
    const year = new Date().getFullYear() - 10;
    for (let i = 0; i < 100; i++) {
      years.push(String(year - i));
    }
    this.state = {
      joinZip: user.zip_code || '',
      birthYear: user.year_of_birth || '',
      gender: user.gender,
      race: user.race,
      maritalStatus: user.marital_status,
      income: user.income_bracket,
      submitLoading: false,
      joinZipValidated: null,
      marketingChecked: user.allow_email_promotions,
      termsChecked: false,
      submitLoading: false,
      years
    }
  }

  validateZip = (value = this.state.joinZip) => {
    if(value.length === 5) {
      this.setState({ joinZipValidated: 'success' })
    } else {
      this.setState({ joinZipValidated: 'error' })
    }
  }

  handleBirthYear = (e) => {
    this.setState({ birthYear: e.target.value });
  }

  handleGender = (e) => {
    this.setState({ gender: e.target.value });
  }

  handleRace = (e) => {
    this.setState({ race: e.target.value });
  }

  handleEthnicity = (e) => {
    this.setState({ ethnicity: e.target.value });
  }

  handleMaritalStatus = (e) => {
    this.setState({ maritalStatus: e.target.value });
  }

  handleIncomeBracket = (e) => {
    this.setState({ income: e.target.value });
  }

  handleJoinZip = (e) => {
    this.setState({ joinZip: e.target.value });
    if(this.state.joinZipValidated) {
      this.validateZip(e.target.value);
    }
  }

  toggleMarketing = () => {
    this.setState({ marketingChecked: !this.state.marketingChecked})
  }

  toggleTerms = () => {
    this.setState({ termsChecked: !this.state.termsChecked})
  }

  submit = async () => {
    try {
      this.setState({ submitLoading: true })
      const editedFields = {
        zip_code: this.state.joinZip,
        year_of_birth: this.state.birthYear,
        income_bracket: this.state.income,
        marital_status: this.state.maritalStatus,
        gender: this.state.gender,
        race: this.state.race,
        terms_acceptance: {
          version: 'default',
          acceptance_ts: new Date(),
        },
        allow_email_promotions: this.state.marketingChecked
      };
      boundUpdateUser(editedFields);
      await api.makeRequest(api.updateUser, editedFields);
      this.props.handleClose();
      this.props.openAlert("Profile completed");
    } catch(e) {

    }
  }

  render() {
    const { handleClose } = this.props;
    const registerButtonDisabled = !this.state.race || !this.state.birthYear || !this.state.gender || !this.state.maritalStatus || !this.state.income || !this.state.joinZip || !this.state.termsChecked || !this.state.joinZipValidated
    return (
      <Fragment>
        <Modal
          open={true}
          onClose={handleClose}
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255, 255, 0.99)'}
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          style={{ overflowY: 'scroll' }}
        >
          <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
            {/* <div style={{ position: 'absolute', left: '20px', top: '20px'}}>
              <IconButton
                onClick={this.props.handleClose}
                color="primary"
                size="large"
                disabled={this.state.submitLoading}
              >
                <CloseIcon style={{ height: '3.5em', width: '3.5em'}}/>
              </IconButton>
            </div> */}
              <Fragment>
                <Typography style={{ fontSize: '15px', color: '#034e5b', paddingTop: '40px', marginBottom: '50px' }}>Complete your profile</Typography>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <TextField
                    id="standard-basic"
                    label="Zip Code"
                    variant="standard"
                    onBlur={() => {
                      this.validateZip()
                    }}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    error={this.state.joinZipValidated === 'error'}
                    helperText={this.state.joinZipValidated === 'error' && "Invalid zip code"}
                    InputProps={{
                      endAdornment: this.state.joinZipValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                    }}
                    inputRef={this.zipRef}
                    value={this.state.joinZip}
                    onChange={this.handleJoinZip}
                    disabled={this.state.submitLoading}
                  />
                  <FormControl>
                    <InputLabel>Birth Year</InputLabel>
                    <Select
                      value={this.state.birthYear}
                      onChange={this.handleBirthYear}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {this.state.years.map(year => (
                        <MenuItem value={year}>{year}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={this.state.gender}
                      onChange={this.handleGender}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'male'}>male</MenuItem>
                      <MenuItem value={'female'}>female</MenuItem>
                      <MenuItem value={'non-binary'}>non-binary</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Race</InputLabel>
                    <Select
                      value={this.state.race}
                      onChange={this.handleRace}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'asian'}>asian</MenuItem>
                      <MenuItem value={'black'}>black</MenuItem>
                      <MenuItem value={'euro-asian'}>euro-asian</MenuItem>
                      <MenuItem value={'hispanic/latino'}>hispanic/latino</MenuItem>
                      <MenuItem value={'native-american'}>native-american</MenuItem>
                      <MenuItem value={'pacific-islander'}>pacific-islander</MenuItem>
                      <MenuItem value={'white'}>white</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Marital Status</InputLabel>
                    <Select
                        value={this.state.maritalStatus}
                        onChange={this.handleMaritalStatus}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                    >
                      <MenuItem value={'single'}>single</MenuItem>
                      <MenuItem value={'married'}>married</MenuItem>
                      <MenuItem value={'divorced'}>divorced</MenuItem>
                      <MenuItem value={'widowed'}>widowed</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Income Bracket</InputLabel>
                    <Select
                        value={this.state.income}
                        onChange={this.handleIncomeBracket}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                    >
                      <MenuItem value={'0-40k'}>0-40k</MenuItem>
                      <MenuItem value={'40-100k'}>40-100k</MenuItem>
                      <MenuItem value={'100k-250k'}>100k-250k</MenuItem>
                      <MenuItem value={'250k-500k'}>250k-500k</MenuItem>
                      <MenuItem value={'500k+'}>500k+</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <div style={{ maxWidth: '300px' }}>
                    <FormControlLabel
                       disabled={this.state.submitLoading}
                       control={
                         <Checkbox checked={this.state.termsChecked} color="primary" onChange={this.toggleTerms} name="Terms" />
                       }
                       style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}
                       label={<Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}><span>Agree to our <a style={{ textDecoration: 'underline', color: '#034e5b' }} target="_blank">Terms & Conditions</a></span></Typography>}
                     />
                   </div>
                   <div style={{ maxWidth: '300px' }}>
                     <FormControlLabel
                        disabled={this.state.submitLoading}
                        control={
                          <Checkbox checked={this.state.marketingChecked} color="primary" onChange={this.toggleMarketing} name="Terms" />
                        }
                        style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}
                        label={<Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}><span>Send me VOTUZA notifications, promotions, and newsletters.</span></Typography>}
                      />
                    </div>
                </div>
                <LoadingButton disabled={registerButtonDisabled} loading={this.state.submitLoading} variant="contained" style={{ marginTop: '20px', marginBottom: '30px', minHeight: '36.5px' }} onClick={this.submit}>{this.state.submitLoading ? <CircularProgress style={{ color: 'white', height: '25px', width: '25px' }}/> : <span>Submit</span>}</LoadingButton>
              </Fragment>
          </div>
        </Modal>
      </Fragment>
    )
  }

}