import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TrendingDownOutlinedIcon from '@material-ui/icons/TrendingDownOutlined';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import { getUser } from "../../redux/selectors";
import MenuDrawer from '../Common/MenuDrawer';
import LoginModal from '../Common/LoginModal';
import ProfileFieldsForm from '../Common/ProfileFieldsForm';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getTabValue = (path) => {
  console.log('path:', path);
  if(path.includes('about')) {
    return 1;
  };
  if(path.includes('worst')) {
    return 2;
  };
  if(path.includes('categories') || path.includes('category')) {
    return 3;
  };
  if(path.includes('shame')) {
    return 4;
  };
  if(path.includes('sales')) {
    return 5;
  };
  if(path === '/') {
    return 0;
  }
  return -1
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalOpen: false,
      alertOpen: false,
      alertMessage: null,
      profileFieldsFormOpen: false,
      showLogin: true,
    }
  }

  openLoginModal = (showLogin=true) => {
    this.setState({ loginModalOpen: true, showLogin })
  }

  closeLoginModal = (user) => {
    this.setState({ loginModalOpen: false, profileFieldsFormOpen: user && Boolean(!user.terms_acceptance) })
  }

  closeProfileFieldsForm = () => {
    this.setState({ profileFieldsFormOpen: false })
  }

  closeAlert = () => {
    this.setState({ alertOpen: false, alertMessage: null })
  }

  openAlert = (message) => {
    this.setState({ alertOpen: true, alertMessage: message })
  }

  goToPage = (route) => {
    this.props.history.push({ pathname: `/${route}` });
  }

  render() {
    console.log('this.props.location.pathname :', this.props.location.pathname );
    const { loginModalOpen, alertOpen, alertMessage, profileFieldsFormOpen } = this.state;
    const { grayColorScheme, user } = this.props
    const value = getTabValue(this.props.location.pathname)
    const tabColor = grayColorScheme ? '#909090' : 'white';
    const border = grayColorScheme ? '1.5px solid rgba(255, 255, 255, 1)' : '1.5px solid rgba(255, 255, 255, .3)'
    const tabIndicatorProps = grayColorScheme ? { style: { backgroundColor: '#b7b7b7' } } : {};
    return (
      <div style={{ width: '100%' }}>
        <div>
          <AppBar position="static">
              <Toolbar>
                <div className='header-logo clickable-div' style={{ flex: 1, textAlign: 'center', position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={require('../../assets/votuza_logo_final.png').default} style={{ height:'inherit', zIndex: 1000, position: 'relative' }} className="pointer logo-size" onClick={() => this.goToPage('')}/>
                </div>
                <Tabs
                  value={-1}
                  textColor="primary"
                  TabIndicatorProps={tabIndicatorProps}
                >
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="Home"
                            style={{ color: value === 0 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 0 ? '#034e5b' : tabColor}` }}
                            icon={<HomeOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px' }}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/about",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="About"
                            style={{ color: value === 1 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 1 ? '#034e5b' : tabColor}` }}
                            icon={<InfoOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px'}}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/worst",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="The Worst"
                            style={{ color: value === 2 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 2 ? '#034e5b' : tabColor}` }}
                            icon={<TrendingDownOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px'}}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/categories",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="Poll Categories"
                            style={{ color: value === 3 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 3 ? '#034e5b' : tabColor}` }}
                            icon={<CollectionsBookmarkOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px'}}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/shamelist",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="Shame List"
                            style={{ color: value === 4 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 4 ? '#034e5b' : tabColor}` }}
                            icon={<AnnouncementOutlinedIcon style={{ width: '25px', paddingLeft: '10px', paddingRight: '10px', height: '25px', marginBottom: '0px'}}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <Link
                      to={{
                        pathname: "/sales",
                      }}
                      style={{ width: '100%', color: 'transparent' }}
                    >
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <div style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                          <Tab
                            label="Sales"
                            style={{ color: value === 5 ? '#034e5b' : tabColor, borderBottom: `2px solid ${value == 5 ? '#034e5b' : tabColor}` }}
                            icon={<AssessmentOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px'}}/>}
                          />
                        </div>
                        <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                      </div>
                    </Link>
                  </div>
                  <div className="header-tabs" style={{ position: 'relative'}}>
                    <div className="tab-border" style={{ position:'fixed', height: '1000px', borderLeft: border, top: 0 }} />
                  </div>
                </Tabs>
                <div className='login-button clickable-div' style={{display:'flex', flex: 1, paddingTop: '10px', alignItems:'center', justifyContent:'center', flexDirection: 'column', gap: '10px' }}>
                    <Button onClick={this.props.user ? () => {this.goToPage('profile')} : () => this.openLoginModal(true)} className={this.props.location.pathname === '/profile' ? 'green-button' : 'blue-button-white'} startIcon={<AccountCircleOutlinedIcon style={{ width: '25px', height: '25px', marginBottom: '0px'}} />} style={{ width: '150px', minWidth: '150px' }}>{this.props.user ? "Profile" : "Login"}</Button>
                    {!this.props.user &&<Button onClick={() => this.openLoginModal(false)} className={'blue-button-white'} style={{ width: '150px', minWidth: '150px', minHeight: '37px' }}>Subscribe</Button>}
                </div>
                <MenuDrawer />
                {loginModalOpen && <LoginModal showModal={loginModalOpen} showLogin={this.state.showLogin} handleClose={this.closeLoginModal} openAlert={this.openAlert}/>}
                {profileFieldsFormOpen && <ProfileFieldsForm user={user} handleClose={this.closeProfileFieldsForm} openAlert={this.openAlert} />}
              </Toolbar>
          </AppBar>
          <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.closeAlert} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(Header));
