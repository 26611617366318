import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import {connect} from 'react-redux';
import CookieConsent from "react-cookie-consent";
import Cookies from 'universal-cookie';
import Header from './components/Header';
import Footer from './components/Common/Footer';
import ShareSocialButtons from './components/Common/ShareSocialButtons';
import HowTo from './components/Common/HowTo';
import Landing from './components/Landing';
import LandingAnimation from './components/LandingAnimation';
import { getUser } from "./redux/selectors";
import { attemptAuthentication } from './auth';
import { getVotuzaLandingCookie, getVotuzaLandingAnimationCookie } from './utils';
const cookies = new Cookies();

class RouteWrapper extends React.Component {
  constructor(props) {
    super(props);
    const showLanding = !getVotuzaLandingCookie();
    const showLandingAnimation = !getVotuzaLandingAnimationCookie();
    this.state = {
      showLanding,
      showLandingAnimation,
    }
  }

  componentWillMount = () => {
    attemptAuthentication();
  }

  closeLanding = () => {
    console.log('closeLanding');
    cookies.set('votuzaLandingSeen', true, { expires: new Date(Date.now()+86400000000) });
    this.setState({ showLanding: false });
  }

  closeLandingAnimation = () => {
    console.log('closeLandingAnimation');
    cookies.set('votuzaLandingAnimationSeen', true);
    this.setState({ showLandingAnimation: false });
  }

  render() {
    console.log('this.state', this.state);
    // const backgroundColor = this.props.path ?
    return (
      <Route
        path={this.props.path}
        exact
        render={() => (
          <div className='full-grey' style={{ minHeight: '100vh', position: 'relative' }}>
            {/* {this.state.showLanding &&  <Landing close={this.closeLanding} />} */}
            {this.state.showLandingAnimation && <LandingAnimation close={this.closeLandingAnimation} />} 
          
            { this.props.path !== '/' && !this.props.path.includes('poll') &&
              <Fragment>
                {/* <div className='green-left-background' /> */}
                <div className='green-left-background-container' style={{ position: 'absolute', width: '100%', height: '100%' }}>
                  <div style={{ position: 'relative', display: 'flex', width: '100%', height: '100%' }}>
                    <div style={{ flex: 1, height: '100%', backgroundColor: '#9cd70a', zIndex: 1 }}>
                    
                    </div>
                    <div style={{ width: 'clamp(725px, 60vw, 1100px)', margin: 'auto', height: '100%' }}>
                    </div>
                    <div style={{ flex: 1 }}>
                    
                    </div>
                  </div>
                </div>
                <Header grayColorScheme={true} />
              </Fragment>
            }
            <this.props.component {...this.props} />
            <Footer />
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              cookieName="votuzaAcceptCookies"
              setDeclineCookie={false}
              style={{ background: "#034e5b" }}
              buttonStyle={{ backgroundColor: "#9cd70a", color: 'black', fontSize: '13px' }}
              expires={10000}
            >
              <span>
                This website uses cookies to enhance the user experience. By using our website, you agree to our <a style={{ textDecoration: 'underline', color: 'white' }} href="/privacy">Privacy Policy</a> and our cookie usage.
              </span>
            </CookieConsent>
            <HowTo />
            <ShareSocialButtons/>
          </div>
        )}
      />
    );
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default connect(mapStateToProps)(RouteWrapper);
