import { Typography } from "@material-ui/core"

const VotuzaForYou = () => (
  <div className='container-home' style={{ borderBottom: '2px solid #034e5b' }}>
    <div style={{ padding: '20px', background: '#034e5b', borderRadius: '10px' }}>
      <div style={{ paddingBottom: '20px' }}>
        <Typography variant='h3' color='primary'>
          What VOTUZA can do for you
        </Typography>
      </div>
      <div>
        <Typography variant='h7' style={{ color: 'white', fontSize: '1.1rem' }}>
          When you vote and post a complaint about an issue with a company, you will open a Vortal of opportunities and resolutions for your complaint, once your complaint is sent. Not only could you receive resolution from the company you have an issue with, but also other companies within the same category. Any company may contact you and make an offer to resolve your complaint directly with you, the consumer, or other companies in the same category offering compensation with competitive pricing or services.
        </Typography>
      </div>
      <div style={{ paddingTop: '20px' }}>
        <Typography variant='h3' style={{ color: 'white', fontSize: '2.65rem' }}>
          Competitive Conversion
        </Typography>
        <div>
        <Typography variant='h7' style={{ color: 'white', fontSize: '1.1rem' }}>
          VOTUZA supports 'Competitive Conversions', the act of a company offering a competing brand's consumer a promotion or incentive in exchange for their loyalty.
        </Typography>
        </div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Typography variant='h5' color='primary'>
            Better Car Offers
          </Typography>
          <div>
          <Typography variant='h7' style={{ color: 'white', fontSize: '1.1rem' }}>
              If you have an issue with your car company, after you make a complaint, that company or other car companies may offer to buy back your car, offer a better trade-in for your car, or give a monetary compensation for your issue.
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Typography variant='h5' color='primary'>
            Lower Interest Rates
          </Typography>
          <div>
          <Typography variant='h7' style={{ color: 'white', fontSize: '1.1rem' }}>
              If your credit card company raises your interest rate on your credit card, other credit cards companies may offer you lower interest rates or better balance transfers.
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Typography variant='h5' color='primary'>
            Lower Premiums
          </Typography>
          <div>
          <Typography variant='h7' style={{ color: 'white', fontSize: '1.1rem' }}>
              If your insurance rates have skyrocketed and you are unhappy with how much you pay, other insurance companies can offer you lower premiums to switch from your current carrier.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default VotuzaForYou;