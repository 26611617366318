import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Cookies from 'universal-cookie';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Menu from '@material-ui/core/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import Select from '@material-ui/core/Select';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Switch from '@material-ui/core/Switch';
import { withRouter } from 'react-router-dom'
import Loading from '../Common/Loading';
import Poll from '../Poll';
import { getUser } from '../../redux/selectors';
import {
  boundUpdateUser, boundLogout
} from "../../redux/actions";
import * as api from '../../api';
const cookies = new Cookies();

const settingsOptions = [
  {
    heading: 'User Information',
    subHeading: 'YOUR PROFILE',
    editable: true,
    options: [
      {
        title: 'FIRST NAME',
        type: 'text',
        field: 'first_name',
        editable: false,
        validate: (value) => {
          if (value.length < 2) {
            return false
          }
          return true;
        }
      },
      {
        title: 'MIDDLE NAME',
        type: 'text',
        field: 'middle_name',
        editable: false,
        validate: (value) => {
          if (value.length < 2) {
            return false
          }
          return true;
        }
      },
      {
        title: 'LAST NAME',
        type: 'text',
        field: 'last_name',
        editable: false,
        validate: (value) => {
          if (value.length < 2) {
            return false
          }
          return true;
        }
      },
      {
        title: 'EMAIL',
        type: 'text',
        field: 'email'
      },
      {
        title: 'ZIPCODE',
        type: 'text',
        field: 'zip_code',
        editable: true,
        validate: (value) => {
          console.log('validateZipCode:', value);
          if (value.length != 5) {
            console.log('invalid length')
            return false
          }
          for (let i = 0; i < value.length; i++) {
            if (isNaN(Number(value[i]))) {
              console.log('invalid zipcode value:', value[i])
              return false;
            }
          }
          return true;
        }
      },
      {
        title: 'YEAR OF BIRTH (YYYY)',
        type: 'year',
        field: 'year_of_birth',
        editable: false,
        validate: (value) => {
          if (value.length != 4) {
            return false
          }
          for (let i = 0; i < value.length; i++) {
            if (isNaN(Number(value[i]))) {
              return false;
            }
          }
          return true;
        }
      },
      {
        title: 'RACE',
        type: 'input',
        field: 'race',
        editable: false,
        enum: ['asian', 'black', 'euro-asian', 'hispanic/latino', 'native-american', 'pacific-islander', 'white',]
      },
      {
        title: 'GENDER',
        type: 'input',
        field: 'gender',
        editable: false,
        enum: ['male', 'female', 'non-binary']
      },
      {
        title: 'MARITAL STATUS',
        type: 'input',
        field: 'marital_status',
        editable: true,
        enum: ['married', 'single', 'divorced', 'widowed']
      },
      {
        title: 'INCOME BRACKET',
        type: 'input',
        field: 'income_bracket',
        editable: true,
        enum: ['0-40k', '40-100k', '100k-250k', '250k-500k', '500k+']
      }
    ]
  },
  {
    heading: 'General Settings',
    subHeading: 'MANAGE & CONTROL',
    editable: true,
    options: [
      {
        title: 'Email Notifications',
        type: 'switch',
        field: 'allow_email_notifications',
        editable: true,
      },
      {
        title: 'Desktop Notifications',
        type: 'switch',
        field: 'allow_desktop_notifications',
        editable: true,
      },
      {
        title: 'Email Newsletter',
        type: 'switch',
        field: 'allow_email_newsletter',
        editable: true,
      },
      {
        title: 'Promotional Emails',
        type: 'switch',
        field: 'allow_email_promotions',
        editable: true,
      },
      {
        title: 'Allow companies to contact',
        type: 'switch',
        field: 'allow_companies_contact',
        editable: true,
      },
    ]
  },
  {
    heading: 'Poll History',
    subHeading: 'VIEW & UPDATE',
    options: [

    ]
  }
]

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileTab: 0,
      showEdit: false,
      editedFields: {},
      invalidEditedFields: new Set(),
    }
  }

  toggleShowEdit = () => {
    if (!this.state.showEdit) {
      this.setState({ showEdit: true })
    } else {
      const { editedFields } = this.state;
      const editedKeys = Object.keys(editedFields);
      console.log('editedKeys:', editedKeys);

    }
  }

  updateEditedFields = (e, option) => {
    console.log('e:', e.target.value);
    console.log('option:', option);
    const editedFields = { ...this.state.editedFields };
    editedFields[option.field] = e.target.value;
    const valid = option.validate(e.target.value);
    console.log('valid:', valid);
    if (!valid && !this.state.invalidEditedFields.has(option.field)) {
      console.log('set to invalid');
      this.setState({
        invalidEditedFields: this.state.invalidEditedFields.add(option.field),
        editedFields
      })
    } else if (!valid) {
      this.setState({
        editedFields
      })
    } else {
      console.log('set to valid')
      const updatedSet = new Set(this.state.invalidEditedFields);
      updatedSet.delete(option.field);
      this.setState({
        invalidEditedFields: updatedSet,
        editedFields
      })
    }
  }

  saveEdit = async () => {
    const originalUser = { ...this.props.user };
    try {
      const { editedFields } = this.state;
      if (Object.keys(editedFields).length === 0) {
        this.setState({ showEdit: false });
      }
      console.log('saveEdit:', editedFields)
      boundUpdateUser(editedFields);
      await api.makeRequest(api.updateUser, editedFields);
      this.setState({ editedFields: {}, invalidEditedFields: new Set(), showEdit: false })
    } catch(e) {
      console.log('e:', e);
      boundUpdateUser(originalUser);
    }
  }

  selectProfileTab = (index) => {
    this.setState({ profileTab: index })
  }

  logout = () => {
    console.log('logout');
    window.localStorage.removeItem('votuzaUserCookie');
    cookies.remove('votuzaUserCookie');
    console.log('cookies:', cookies)
    console.log('removed cookies');
    boundLogout();
    this.props.history.push({ pathname: `/` });
  }

  render() {
    const { categories, profileTab, showEdit, editedFields, invalidEditedFields } = this.state;
    console.log('editedFields:', editedFields);
    console.log('invalidEditedFields:', invalidEditedFields)
    const settings = this.props.user && settingsOptions.map(setting => {
      return {...setting, options: setting.options.map(option => (
        {...option, value: this.props.user[option.field] || null}
      ))}
    })
    if (!settings) {
      return (
        <div style={{ position: 'relative', width: '100%' }}>
          <div className='container'>
            <Loading />
          </div>
        </div>
      )
    }
    const setting = settings[profileTab];
    console.log('setting:', setting);
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div className='left-padding' style={{ paddingTop: '50px', display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ backgroundColor: 'white', borderRadius: '15px', flex: 3, minWidth: '265px', height: 'fit-content', boxShadow: '0px 22px 61px -9px rgb(0 0 0 / 9%), 0px 16px 34px -31px rgb(0 0 0 / 38%), 0px -7px 40px -17px rgb(0 0 0 / 16%)' }}>
              <div style={{ borderBottom: '1px solid lightgrey' }}>
                <Typography variant='h4' color='textSecondary' style={{ paddingLeft: '32px', paddingTop: '30px', paddingBottom: '15px' }}>Profile</Typography>
              </div>
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                {settings.map((setting, index) => (
                  <div className='pointer' onClick={() => this.selectProfileTab(index)} style={{ display: 'flex', height: '70px', paddingLeft: '20px', alignItems: 'center', justifyContent: 'space-between', backgroundColor: index === profileTab ? 'rgb(239, 239, 239)' : '' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
                      <div style={{ backgroundColor: 'lightgrey', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '40px', minWidth: '40px'}}>
                        <SettingsIcon style={{ fill: 'rgb(183, 183, 183)' }}/>
                      </div>
                      <div style={{ marginLeft: '20px', borderBottom: '1px solid rgba(183, 183, 183, .5)', height: '100%', display: 'flex', width: '100%', alignItems: 'center', boxSizing: 'border-box' }}>
                        <Typography variant='h6' className="text-grey" style={{ paddingBottom: '0px', fontSize: '1.15rem', lineHeight: 1 }}>{setting.heading}</Typography>
                      </div>
                    </div>
                    <div style={{ paddingRight: '15px', height: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(183, 183, 183, .5)', boxSizing: 'border-box' }}>
                      <ArrowForwardIosIcon fontSize='small' style={{ fill: 'rgb(183, 183, 183)' }} />
                    </div>
                  </div>
                ))}
                <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
                  <Button onClick={this.logout} className="blue-button" style={{ width: '150px', minWidth: '150px' }}>Logout</Button>
                </div>
              </div>
            </div>
            <div style={{ flex: 5 }}>
              <div style={{ borderBottom: '1px solid lightgrey', height: '77px', alignItems: 'center', paddingTop: '10px', paddingLeft: '35px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant='h6' color='textSecondary' style={{ paddingBottom: '0px', fontSize: '1.15rem', lineHeight: 1 }}>{setting.heading}</Typography>
                  <Typography variant='h7' className="text-grey" style={{ paddingTop: '0px', fontWeight: '800', fontSize: '.8rem', paddingBottom: '0px' }}>{setting.subHeading}</Typography>
                </div>
                {profileTab === 0 &&
                  <button disabled={invalidEditedFields.size > 0} style={{ borderRadius: '50%', width: '40px', height: '40px', border: 'none', padding: '0px', backgroundColor: 'rgb(239, 239, 239)' }} onClick={!showEdit ? this.toggleShowEdit : this.saveEdit}>
                    <Typography variant='h7' className="text-grey" style={{ paddingTop: '0px', fontWeight: '800', fontSize: '.8rem', paddingBottom: '0px' }}>{showEdit? 'SAVE' : 'EDIT'}</Typography>
                  </button>
                }
              </div>
              {setting.heading === 'Poll History' ?
                <div style={{ paddingLeft: '35px' }}>
                  <Polls polls={this.props.user.pollResponses}/>
                </div>
                :
                <div style={{ paddingLeft: '35px' }}>
                  <Options options={settings[profileTab].options} editedFields={editedFields} editMode={showEdit} updateEditedFields={this.updateEditedFields} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function Polls({ polls }) {
  return (
    <div style={{ paddingTop: '30px' }}>
      {polls.map(poll => (
        <PollItem poll={poll} />
      ))}
    </div>
  )
}

class PollItemComponent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showComment: true,
    }
  }

  toggleShowComment = () => {
    this.setState({ showComment: !this.state.showComment })
  }

  render() {
    const { poll, history } = this.props;
    console.log('poll:', poll);
    const { showComment, } = this.state;
    return (
      <div style={{ paddingBottom: '30px' }}>
        <Typography onClick={() => {history.push({ pathname: `/poll/${poll.poll._id}` })}} className='pointer blue-hover' variant='h5' color="primary" style={{ fontSize: '1.7rem', lineHeight: 1 }}>{poll.poll.title}</Typography>
        <div>
          <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>You voted for <span style={{ color: '#034e5b' }}>{(poll.company?.name || poll.company?.other_company)} (<span style={{ color: poll.current ? 'green' : 'red'}}>{poll.current ? 'current' : 'outdated'}</span>)</span></Typography>
        </div>
        { poll.comment && <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>Your compaint:</Typography> }

          { poll.comment && showComment &&
            <div style={{ padding: '20px', backgroundColor: 'white', margin: '10px', borderRadius: '10px' }}>
              <Typography variant='h7' style={{ color: '#034e5b', fontWeight: '800', fontSize: '.8rem' }}>"{poll.comment}"</Typography>
            </div>
          }
      </div>
    )
  }
}

const PollItem = withRouter(PollItemComponent);

function Options({ options, editMode, updateEditedFields, editedFields }) {
  return (
    <div style={{ paddingTop: '30px' }}>
      {options.map(option => {
        if (option.type === 'input') {
          return (
            <InputOption option={option} />
          )
        }
        if (option.type === 'year') {
          return (
            <InputYearOption option={option} />
          )
        }
        if (option.type === 'switch') {
          return (
            <SwitchOption option={option} />
          )
        }
        return (
          <TextOption option={option} editMode={editMode} editedFields={editedFields} dirty={Boolean(editedFields[option.field])} updateEditedFields={updateEditedFields}/>
        )
     })}
    </div>
  )
}

class SwitchOption extends Component {

  updateField = async () => {
    const originalUser = { ...this.props.user };
    try {
      console.log('updateField:', this.props.option);
      const updatedField = {};
      updatedField[this.props.option.field] = !Boolean(this.props.option.value)
      console.log('updatedUser:', updatedField);
      boundUpdateUser(updatedField);
      await api.makeRequest(api.updateUser, updatedField);
    } catch(e) {
      console.log('e:', e);
      boundUpdateUser(originalUser);
    }
  }

  render() {
    const { option } = this.props;
    return (
      <div style={{ paddingBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant='h5' color="primary" style={{ fontSize: '1.7rem', lineHeight: 1 }}>{option.title || 'n/a'}</Typography>
          <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.value ? 'ENABLED' : 'DISABLED'}</Typography>
        </div>
        <div>
          <Switch
            checked={option.value}
            onChange={this.updateField}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>

      </div>
    )
  }
}

class TextOption extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false
    }
  }

  toggleFocus = () => {
    this.setState({ focused: !this.state.focused });
  }

  render () {
    const { option, editMode, editedFields, updateEditedFields, dirty } = this.props;
    const { focused } = this.state;
    if (editMode && (option.editable || !option.value)) {
      const invalid = dirty && !focused && !option.validate(editedFields[option.field]);
      return (
        <div style={{ paddingBottom: '21px' }}>
          <div>
            <input onFocus={this.toggleFocus} onBlur={this.toggleFocus} value={editedFields[option.field] || option.value} pattern={option.pattern} onChange={(e) => updateEditedFields(e, option)} style={{ color: '#9cd70a', background: 'none', border: 'none', fontSize: '1.7rem', fontFamily: 'Grotesk', fontWeight: 400, outline: 'none', borderBottom: '1px solid' }}/>
          </div>
          <Typography variant='h7' style={{ color: invalid ? 'red' : '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.title}{invalid && '*'}</Typography>
        </div>
      )
    }
    return (
      <div style={{ paddingBottom: '30px' }}>
        <Typography variant='h5' color="primary" style={{ fontSize: '1.7rem', lineHeight: 1 }}>{option.value || 'n/a'}</Typography>
        <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.title}</Typography>
      </div>
    )
  }
}

class InputOption extends Component{
  constructor(props) {
    super(props)
    this.state = {
      showOptions: false,
      textRef: React.createRef(),
    }
  }

  toggleShowOptions = (e) => {
    console.log('toggleShowOptions:', e);
    this.setState({ showOptions: !this.state.showOptions })
  }

  selectOption = async (item) => {
    const originalUser = { ...this.props.user };
    try {
      console.log('selectOption:', item);
      console.log('option:', this.props.option);
      const updatedUser = {};
      updatedUser[this.props.option.field] = item;
      boundUpdateUser(updatedUser);
      this.setState({ showOptions: false })
      await api.makeRequest(api.updateUser, updatedUser);
      console.log('success');
    } catch(e) {
      console.log('e:', e);
      boundUpdateUser(originalUser);
    }

  }

  render() {
    const { option } = this.props;
    const { showOptions, textRef } = this.state;
    console.log('render:', textRef);
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div style={{ display: 'flex' }}>
          <Typography variant='h5' color="primary" ref={this.state.textRef} style={{ fontSize: '1.7rem', lineHeight: 1 }}>{option.value || 'n/a'}</Typography>
          { (option.editable || !option.value) && (
            <IconButton aria-label="open-options" size="small" style={{ marginLeft: '10px' }} onClick={this.toggleShowOptions}>
               <PlayArrowIcon fontSize="inherit" style={{ transform: showOptions ? 'rotate(-90deg)' : 'rotate(90deg)' }}/>
            </IconButton>
          )}
        </div>
        <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.title}</Typography>
        { (option.editable || !option.value) && (
          <Menu
             elevation={0}
             getContentAnchorEl={null}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
             }}
             open={showOptions}
             style={{ marginTop: '10px', marginBottom: '10px' }}
             onClose={this.toggleShowOptions}
             anchorEl={textRef.current}
          >
            {option.enum.map(item => (
              <MenuItem onClick={() => this.selectOption(item)}>{item}</MenuItem>
            ))}
          </Menu>
        )}
      </div>
    )
  }
}

class InputYearOption extends Component{
  constructor(props) {
    super(props);
    const years = [];
    const year = new Date().getFullYear() - 10;
    for (let i = 0; i < 100; i++) {
      years.push(String(year - i));
    }
    this.state = {
      showOptions: false,
      textRef: React.createRef(),
      years
    }
  }

  toggleShowOptions = (e) => {
    console.log('toggleShowOptions:', e);
    this.setState({ showOptions: !this.state.showOptions, anchorEl: e.currentTarget })
  }

  menuEntered = () => {
    console.log(this.props.option);
    const yearId = `year-${this.props.option.value}` || 'year-1980'
    console.log('yearId:', yearId);
    const listItemYear = document.getElementById(yearId);
    console.log('listItemYear:', listItemYear);
    if(listItemYear) {
      listItemYear.scrollIntoView();
    }
  }

  selectOption = async (item) => {
    const originalUser = { ...this.props.user };
    try {
      console.log('selectOption:', item);
      console.log('option:', this.props.option);
      const updatedUser = {};
      updatedUser[this.props.option.field] = item;
      boundUpdateUser(updatedUser);
      this.setState({ showOptions: false })
      await api.makeRequest(api.updateUser, updatedUser);
      console.log('success');
    } catch(e) {
      console.log('e:', e);
      boundUpdateUser(originalUser);
    }

  }

  render() {
    const { option } = this.props;
    const { showOptions, textRef, years } = this.state;
    console.log('render yo:', option);
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div style={{ display: 'flex' }}>
          <Typography variant='h5' color="primary" ref={textRef} style={{ fontSize: '1.7rem', lineHeight: 1 }}>{option.value || 'n/a'}</Typography>
          { (option.editable || !option.value) &&
            <IconButton aria-label="open-options" size="small" style={{ marginLeft: '10px' }} onClick={this.toggleShowOptions}>
               <PlayArrowIcon fontSize="inherit" style={{ transform: showOptions ? 'rotate(-90deg)' : 'rotate(90deg)' }}/>
            </IconButton>
          }
        </div>
        <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.title}</Typography>
        { (option.editable || !option.value) && (
          <Menu
             elevation={0}
             getContentAnchorEl={null}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
             }}
             open={showOptions}
             style={{ marginTop: '10px', height: '400px' }}
             onClose={this.toggleShowOptions}
             anchorEl={textRef.current}
             id="year"
             onEntered={this.menuEntered}
          >
            {years.map(year => (
              <MenuItem id={`year-${year}`} onClick={() => this.selectOption(year)}>{year}</MenuItem>
            ))}
          </Menu>
        )}
      </div>
    )
  }
}

// function InputOption({ option }) {
//   return (
//     <div style={{ paddingBottom: '30px' }}>
//       <FormControl>
//           <Select
//             value={option.value || 'n/a'}
//             displayEmpty
//             inputProps={{ 'aria-label': 'Without label' }}
//           >
//             {!option.value &&
//               <MenuItem value="n/a" disabled>
//                 n/a
//               </MenuItem>
//             }
//             {option.enum.map(value => (
//               <MenuItem value={value}>{value}</MenuItem>
//             ))}
//           </Select>
//           <Typography variant='h7' style={{ color: '#b7b7b7', fontWeight: '800', fontSize: '.8rem' }}>{option.title}</Typography>
//         </FormControl>
//       </div>
//   )
// }

function mapStateToProps(state){
  return { user: getUser(state) };
}

connect(mapStateToProps)(SwitchOption);

export default withRouter(connect(mapStateToProps)(Profile));
