const initialState = null

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER': {
      return { ...state, ...action.payload }
    }
    case 'ADD_COMPLETED_POLL': {
      return { ...state, completedPolls: [...state.completedPolls, action.payload.completedPollId], pollResponses: [action.payload.pollResponse, ...state.pollResponses] }
    }
    case 'LOGOUT': {
      return null
    }
    default: {
      return state;
    }
  }
};

export default user;
