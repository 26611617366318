import React, { Component, Fragment, useState } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { getUser } from "../../redux/selectors";
import Loading from '../Common/Loading';
import Poll from '../Poll';
import * as api from '../../api';
import { getPollsFromStorage, setPollsToStorage } from '../../utils';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Category extends Component {
  constructor(props) {
    console.log('category props:', props);
    super(props);
    const polls = getPollsFromStorage(props.computedMatch.params.id) || [];
    const { name } = queryString.parse(props.location.search);
    console.log('name', name);
    this.state = {
      polls: polls,
      category: name ? { name } : {},
      loading: polls.length === 0,
      modalOpen: false
    }
  }

  componentWillMount = async () => {
    try {
      const { id: categoryId } = this.props.computedMatch.params;
      const [ { polls }, { category } ] = await Promise.all([api.makeRequest(api.getPollsWithId, categoryId), api.makeRequest(api.getCategory, categoryId)]);
      // const { polls } = await api.makeRequest(api.getPollsWithId, categoryId);
      console.log('polls:', polls);
      console.log('category:', category);
      this.setState({ polls, category, loading: false });
      setPollsToStorage(categoryId, polls);
    } catch(e) {
      
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  selectPoll = (poll) => {
    // this.props.history.push({ pathname: `/poll/${poll._id}` });
    this.setState({ selectedPoll: poll });
  }

  goBack = () => {
    this.props.history.push({ pathname: '/categories' });
  }

  closePoll = () => {
    this.setState({ selectedPoll: null });
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  updatePolls = (pollId, pollResponseBreakdown) => {
    console.log('updatePolls:', pollId, pollResponseBreakdown, this.state.category._id);
    const polls = getPollsFromStorage(this.state.category._id);
    console.log('polls:', polls);
    const poll = polls.find(poll => poll._id === pollId);
    console.log('poll:', poll);
    poll.pollResponseBreakdown = pollResponseBreakdown;
    setPollsToStorage(this.state.category._id, polls);
    this.setState({ polls: polls });
  }

  suggestPoll = (poll) => {
    console.log('poll:', poll);
    this.toggleModal();
    this.openAlert('Thank you for your suggestion!');
    let name = '';
    let email = '';
    if (this.props.user) {
      name = this.props.user.first_name + ' ' + this.props.user.last_name;
      email = this.props.user.email;
    }
    let message = `POLL SUGGESTION for ${this.state.category.name} category: '${poll}'`;
    api.makeRequest(api.postInquiry, { email, name, message });
  }

  suggestCategory = (category) => {
    console.log('category:', category);
    this.toggleModal();
    this.openAlert('Thank you for your suggestion!');
    let name = '';
    let email = '';
    if (this.props.user) {
      name = this.props.user.first_name + ' ' + this.props.user.last_name;
      email = this.props.user.email;
    }
    let message = 'CATEGORY SUGGESTION: ' + category;
    api.makeRequest(api.postInquiry, { email, name, message });
  }

  closeAlert = () => {
    this.setState({ alertOpen: false, alertMessage: null })
  }

  openAlert = (message) => {
    this.setState({ alertOpen: true, alertMessage: message })
  }

  render() {
    const { polls, loading, category, selectedPoll, modalOpen, alertOpen, alertMessage } = this.state;
    const sortedPolls = polls.sort((a, b) => {
      return b.orderWeight - a.orderWeight;
    });
    const { user } = this.props;
    console.log('render:', category);
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div className='left-padding' style={{ paddingTop: '50px', paddingBottom: '85px' }}>
            <div style={{ paddingBottom: '20px' }}>
              <Button variant="text" style={{ background: 'none', padding: '0px', margin: '0px', minWidth: '0px' }} onClick={this.goBack}>
                <ArrowRightAltIcon style={{ transform: 'rotate(180deg)', color: '#9cd70a' }} />
                <Typography variant='h6' color='primary'>
                  Back to Categories
                </Typography>
              </Button>
            </div>
            { loading ?
              <Loading />
              :
              <Fragment>
                <div style={{ paddingBottom: '20px' }}>
                  <Typography variant='h2' color='primary' style={{ lineHeight: .9 }}>
                    {category.name}
                  </Typography>
                </div>
                <div>
                  {sortedPolls.map( (poll, index) => (
                    <div style={{ display: 'flex', marginBottom: '15px', borderBottom: '1px solid lightgrey' }}>
                      <div>
                        {user && user.completedPolls.includes(poll._id) ?
                          <Typography variant='h5' className='poll-button' color='textSecondary' onClick={() => this.selectPoll(poll)}>{poll.title} <CheckCircleIcon style={{ fill: '#9cd70a' }}/> </Typography>
                          :
                          <Typography variant='h5' className='poll-button' color='textSecondary' onClick={() => this.selectPoll(poll)}>{poll.title} </Typography>
                        }
                        <Typography variant='h6' className="text-grey" style={{ fontSize: '1rem', marginBottom: '5px' }}>{poll.vote_count} Vote{poll.vote_count !== 1 && 's'} &nbsp;&nbsp; | &nbsp;&nbsp; {poll.comment_count} Complaint{poll.comment_count !== 1 && 's'}</Typography>
                      </div>
                    </div>
                  ))}
                  <div style={{ marginTop: '10px', display: 'inline-block' }}>
                  <Typography variant='h6' className='blue-text green-hover pointer' onClick={this.toggleModal}>
                    Suggest a poll
                  </Typography>
                  </div>
                  { modalOpen && <SuggestPollModal closeModal={this.toggleModal} suggestPoll={this.suggestPoll} />}
                </div>
              </Fragment>
            }
          </div>
        </div>
        { selectedPoll && <Poll updatePolls={this.updatePolls} modal={true} open={true} completed={user && user.completedPolls.includes(selectedPoll._id)} poll={selectedPoll} close={this.closePoll} /> }
        <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.closeAlert} severity="success">
            {alertMessage}
          </Alert>
        </Snackbar>
       </div>
    )
  }
}

const SuggestPollModal = ({ closeModal, suggestPoll }) => {
  const [pollNameValue, setPollNameValue] = useState('');
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className='modal-container'
      disableAutoFocus={true}
      disableEnforceFocus={true}
      BackdropProps={{
        invisible: false
      }}
    >
      <div style={{backgroundColor: '#f6f6f6', position: 'relative', display: 'flex', justifyContent: 'center', flexDirection:'column', border: '2px solid #034e5b', maxWidth: '90%', minWidth: '300px', borderRadius: '20px', overflow: 'hidden', position: 'relative'}}>
        <div style={{ position: 'absolute', left: '0px', top: '0px'}}>
          <IconButton
            onClick={closeModal}
            color="primary"
            size="medium"
          >
            <CloseIcon style={{ height: '2.5em', width: '2.5em'}}/>
          </IconButton>
        </div>
        <div className="mobile-form" style={{display: 'flex', maxWidth: '600px', paddingLeft: '60px', paddingRight: '60px', paddingTop: '25px', paddingBottom: '50px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white' }}>
          <Typography variant='h5' color="textSecondary" style={{ marginBottom: '20px' }}>Suggest a Poll</Typography>
          <TextField
            id="standard-basic"
            label="Poll"
            type="poll"
            name="poll"
            variant="standard"
            value={pollNameValue}
            onChange={(e) => setPollNameValue(e.target.value)}
          />
          <Button disabled={pollNameValue.length === 0} onClick={() => suggestPoll(pollNameValue)} style={{ marginTop: '40px', minWidth: '0px', paddingLeft: '50px', paddingRight: '50px', letterSpacing: '0.5px', height: '36.5px' }}>Submit</Button>
        </div>
      </div>
    </Modal>
  )
};

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(Category));
