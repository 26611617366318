import React from 'react';
import { BrowserRouter, Switch} from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import RouteWrapper from './RouteWrapper';
import ProtectedRouteWrapper from './ProtectedRouteWrapper';
import Home from './components/Home';
import About from './components/About';
import Legal from './components/Legal';
import Privacy from './components/Privacy';
import Categories from './components/Categories';
import NonResponders from './components/NonResponders';
import Profile from './components/Profile';
import Category from './components/Category';
import Poll from './components/Poll';
import VerifyAccount from './components/VerifyAccount';
import ResetPassword from './components/ResetPassword';
import Worst from './components/Worst';
import Sales from './components/Sales';
import MediaKit from './components/MediaKit';
import FAQ from './components/FAQ';
import theme from './styles/theme';
import store from "./redux/store";
import Feedback from './components/Common/BetaFeedback';

const routing = (
  <HttpsRedirect>
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <Switch>
              <RouteWrapper path="/about" component={About} />
              <RouteWrapper path="/terms-of-use" component={Legal} />
              <RouteWrapper path="/privacy" component={Privacy} />
              <ProtectedRouteWrapper path="/profile" component={Profile} />
              <RouteWrapper path="/categories" component={Categories} />
              <RouteWrapper path="/category/:id" component={Category} />
              <RouteWrapper path="/shamelist" component={NonResponders} />
              <RouteWrapper path="/poll/:id" component={Poll} />
              <RouteWrapper path="/verifyAccount" component={VerifyAccount} />
              <RouteWrapper path="/resetPassword" component={ResetPassword} />
              <RouteWrapper path="/worst" component={Worst} />
              <RouteWrapper path="/sales" component={Sales} />
              <RouteWrapper path="/faq" component={FAQ} />
              <RouteWrapper path="/media" component={MediaKit} />
              <RouteWrapper path="/" exact component={Home} />
            </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  </HttpsRedirect>
)

export default routing;
