import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import Poll from '../Poll';
import * as api from '../../api';
import { getUser } from '../../redux/selectors';

class TrendingPolls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      polls: [],
    }
  }

  componentWillMount = async () => {
    try {
      const { polls } = await api.makeRequest(api.getFeaturedPolls);
      console.log('polls:', polls);
      this.setState({ polls });
    } catch (e) {
      console.log('e:', e);
    }
  }

  selectPoll = (poll) => {
    // this.props.history.push({ pathname: `/poll/${poll._id}` });
    this.setState({ selectedPoll: poll });
  }

  closePoll = () => {
    this.setState({ selectedPoll: null });
  }

  updatePolls = (pollId, pollResponseBreakdown) => {
    const polls = this.state.polls.slice();
    const poll = polls.find(poll => poll._id === pollId);
    poll.pollResponseBreakdown = pollResponseBreakdown;
    this.setState({ polls: polls });
  }

  render() {
    const { polls, selectedPoll } = this.state;
    const { user } = this.props;
    return (
      <div>
        <div className='container-home' style={{ paddingBottom: '20px' }}>
          <div className="trending-polls">
            <div style={{ paddingBottom: '40px' }}>
              <Typography variant='h3' color='textSecondary'>
                Trending Polls
              </Typography>
            </div>
            <div>
              {polls.map((poll, index) => (
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <div style={{ minWidth: '63px' }}>
                    <Typography variant='h4' color='textSecondary' style={{ color: 'white' }}>{index + 1}.</Typography>
                  </div>
                  <div>
                    {user && user.completedPolls.includes(poll._id) ?
                      <Typography variant='h4' className='poll-button' onClick={() => this.selectPoll(poll)}>{poll.title} <CheckCircleIcon style={{ fill: '#9cd70a' }}/> </Typography>
                      :
                      <Typography variant='h4' className='poll-button' onClick={() => this.selectPoll(poll)}>{poll.title} </Typography>
                    }
                    <Typography variant='h6' style={{ fontSize: '1rem', color: 'white' }}>{poll.vote_count} Vote{poll.vote_count !== 1 && 's'} &nbsp;&nbsp; | &nbsp;&nbsp; {poll.comment_count} Complaint{poll.comment_count !== 1 && 's'}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        { selectedPoll && <Poll updatePolls={this.updatePolls} modal={true} open={true} completed={user && user.completedPolls.includes(selectedPoll._id)} poll={selectedPoll} close={this.closePoll} /> }
      </div>
    )
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(TrendingPolls));
