import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';

export default class MediaKit extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container' style={{ paddingBottom: '0px'}}>
          <div style={{ paddingTop: '70px' }}>
            <div style={{ paddingBottom: '40px' }}>
              <Typography variant='h2' color='primary' style={{ zIndex: 5 }}>
                Media Kit
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
