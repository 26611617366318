import Cookies from 'universal-cookie';
import * as api from './api';
import { getUserCookie } from './utils';
import {
  boundUpdateUser
} from "./redux/actions";
const cookies = new Cookies();

const _set_local_storage = (key, value) => {
  window.localStorage.setItem(key, value)
}

const _check_local_storage = (key) => {
  let value = window.localStorage.getItem(key)
  return value
}

export const register = async (email, password, firstName, lastName, zipCode, birthYear, gender, race, marketing_opt_in, income, maritalStatus, redirectUrl) => {
  return new Promise(function(resolve, reject) {
    api.makeRequest(api.register, email, password, firstName, lastName, zipCode, birthYear, gender, race, marketing_opt_in, income, maritalStatus, redirectUrl)
    .then((response)=>{
      handleLoginResponse(response)
      .then( () => resolve())
      .catch( () => reject())
    })
    .catch((err)=>{
      reject(err)
    })
  })
}

export const registerFacebook = async (access_token) => {
  return new Promise(function(resolve, reject) {
    api.makeRequest(api.registerFacebook, access_token)
    .then((response)=>{
      handleLoginResponse(response)
      .then( (data) => resolve(data))
      .catch( () => reject())
    })
    .catch((err)=>{
      reject(err)
    })
  });
}

export const attemptAuthentication = async () => {
  return new Promise(async (resolve, reject) => {
    let cookie = getUserCookie();
    console.log('cookie:', cookie);
    if (cookie) {
      api.makeRequest(api.authenticate)
      .then(async (response)=>{
        const user = await handleLoginResponse(response)
        resolve(user);
      })
      .catch((err)=>{
        console.log('err:', err);
        reject();
      })
    } else {
      reject()
    }
  })
}

export const login = async (username, password) => {
  return new Promise(function(resolve, reject) {
    api.makeRequest(api.login, username, password)
    .then((response)=>{
      handleLoginResponse(response)
      .then( () => resolve())
      .catch( () => reject())
    })
    .catch((err)=>{
      reject(err)
    })
  })
}

export const resetPasswordEmail = (email) => {
  api.makeRequest(api.resetPasswordEmail, email);
}

export const handleLoginResponse = (response) => {
  console.log('handleLoginResponse: ', response);
  return new Promise(function(resolve, reject) {
    if (!response.token || !response.user){
      console.log('Missing token in login response');
      reject("")
    } else{
      console.log('set cookie');
      cookies.set('votuzaUserCookie', response.token, { path: '/', expires: new Date(Date.now()+86400000000) });
      _set_local_storage('votuzaUserCookie', response.token);
      // cookies.addChangeListener(unauthenticateChangeListener);
      boundUpdateUser(response.user);
      resolve(response.user)
    }
  })
}
