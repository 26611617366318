import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as api from '../../api';

const message = "Coming soon.";

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { } = this.state;
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container' style={{ paddingBottom: '0px'}}>
          <div className='left-padding' style={{ paddingTop: '50px' }}>
            <div style={{ paddingBottom: '40px' }}>
              <Typography variant='h2' color='primary' style={{ zIndex: 5 }}>
                VOTUZA <span style={{ fontSize: '30px' }}>Terms of Use</span>
              </Typography>
            </div>
            <div>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >Welcome to</span>
                <span >&nbsp;VOTUZA.com.</span>
                <span >
                  &nbsp; The following terms and conditions apply to all visitors to or
                  users of the VOTUZA website,{" "}
                  <a href="http://www.votuza.com">www.votuza.com</a> (“Website”). By using
                  this Website, you agree to be bound by these terms and conditions, which
                  may be modified by VOTUZA at any time and posted on this Website. In the
                  event of a violation of these terms and conditions, VOTUZA reserves the
                  right to seek all remedies available by law and in equity.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  By accepting these terms and conditions through your use of the Website,
                  you certify that you are 18 years of age or older. If you are under the
                  age of 18 but at least 13 years of age, you may use this Website only
                  under the supervision of a parent or legal guardian who agrees to be bound
                  by these terms and conditions.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  All registration information provided by you on this Website must be
                  truthful and accurate. Providing any untruthful or inaccurate information
                  constitutes a breach of these terms and conditions.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  This Website Terms and Condition was last updated on <u>May 15, 2022</u>.
                </span>
              </p>
              <div style={{ marginLeft: '40px'}}>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <strong>
                  <span >Website Content</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  All features, content, surveys, or information depicted on the Website are
                  subject to change at any time without notice.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  This Website and its content are intended to comply with U.S laws and
                  regulations. If you are a non-U.S based user, be advised that other
                  countries may have laws, regulatory requirements, and product safety
                  requirements that are different than those in the U.S. &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  VOTUZA reserves the right, but is not obligated, to limit access to its
                  services to any person, geographic region or jurisdiction. We may exercise
                  this right on a case-by-case basis. VOTUZA also reserves the right to
                  discontinue any surveys or to modify and otherwise remove any services,
                  content or information at any time.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  You understand that by using this Website or accessing any information or
                  content provided on this Website, you may encounter content that may be
                  deemed by some to be offensive, indecent, or objectionable, which content
                  may or may not be identified as such. You agree to use the Website and any
                  service at your sole risk and that VOTUZA and its affiliates shall have no
                  liability to you for content that may be deemed offensive, indecent, or
                  objectionable.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <strong>
                  <span >Accuracy of Information</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  We do our best to ensure that information on the Websites is complete,
                  accurate and current. Despite our efforts, information on the Websites may
                  occasionally be inaccurate, incomplete or out of date. We make no
                  representation as to the completeness, accuracy or correctness of any
                  information on the Websites.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Intellectual property Rights&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA retain all rights regarding their trademarks, trade names, brand
                  names and trade dress. These marks, names trade dress, and all associated
                  logos or images, are registered and/or common law trademarks of
                  VOTUZA.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  The VOTUZA Website and all of its materials, including, but not limited
                  to, its software, source code, HTML code, scripts, web apps, text,
                  artwork, photographs, images, animations, design, video, and audio
                  (collectively, “Materials”) are protected by U.S. copyright laws and other
                  U.S. and international laws and treaties. All Materials are provided by
                  VOTUZA as a service on this Website may be used only for personal and
                  informational purposes. No right, title or interest in our Materials is
                  conveyed to you. This is a limited license, not a transfer of title to our
                  Materials, and such license is subject to the following restrictions: (a)
                  you may not copy, reproduce, publish, transmit, distribute, perform,
                  display, post, modify, create derivative works from, sell, license or
                  otherwise exploit this Website or any of its Materials without our prior
                  written permission; (b) you may not access or use the Website for any
                  competitive or commercial purpose; and (c) you will not permit any copying
                  of our Materials. Any unauthorized copying, alteration, distribution,
                  transmission, performance, display, or other use of these Materials is
                  prohibited. VOTUZA may revoke this limited license at any time for any or
                  no reason. All rights not expressly granted are reserved by VOTUZA.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA has a no-tolerance policy regarding third-party use of our
                  trademarks or names in metatags hidden text or ad keywords. More
                  specifically, the unauthorized use of our trademarks or names in metatags,
                  keywords, page text, hidden text, and/or ad keywords in conjunction with
                  search engines constitutes trademark infringement and unfair
                  competition.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA does not permit any activities on this Website that infringes on
                  the intellectual properties of others, including but not limited to
                  copyright, trademark, or patent. VOTUZA may remove any content of any kind
                  that a user posts to this Website ("Comments"). &nbsp; At its sole
                  discretion, VOTUZA may limit or permanently terminate a user account for
                  any reason. &nbsp;For instance, VOTUZA may limit a user’s ability to
                  submit Comments if such comments infringe another’s copyright, or may
                  terminate a user’s account for egregious or repeated infringing conduct on
                  the Website.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  If you are the owner of an intellectual property or an agent for such
                  owner and believe that any Comments on this Website by third parties
                  infringes upon your rights, you may notify VOTUZA by providing the
                  following information in writing:
                </span>
              </p>
              <ul style={{ listStyleType: "disc", marginLeft: "0in" }}>
                <li>
                  <span >
                    A physical or electronic signature of a person authorized to act on
                    behalf of the owner of the intellectual property right that is allegedly
                    infringed;
                  </span>
                </li>
                <li>
                  <span >
                    A clear and concise identification of the intellectual property right
                    being infringed;
                  </span>
                </li>
                <li>
                  <span >
                    Identification of the Comment(s) or material that is claimed to be
                    infringing and a description of the infringing activity and information
                    reasonably sufficient to permit VOTUZA to locate the same;
                  </span>
                </li>
                <li>
                  <span >
                    Information reasonably sufficient to permit VOTUZA to contact you, such
                    as an address, telephone number, and, if available, an email address;
                  </span>
                </li>
                <li>
                  <span >
                    A statement that you have a good faith belief that use of the comment(s)
                    or material in the manner complained of is not authorized by the
                    intellectual property owner, its agent, or the law; and
                  </span>
                </li>
                <li>
                  <span >
                    A statement that the information in the notification is accurate, and,
                    under penalty of perjury, that you are authorized to act on behalf of
                    the owner of the intellectual property right that is allegedly
                    infringed.
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  The information should be sent to the following:
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                }}
              >
                <span >
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Email:</strong>{" "}
                  <a href="mailto:info@VOTUZA.com">
                    <span style={{ textDecoration: "none" }}>info@votuza.com</span>
                  </a>
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                }}
              >
                <strong>
                  <span >
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Mailing Address:&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: 15, color: "black", }}>
                  8033{" "}
                  <strong>
                    <span style={{ fontWeight: "normal" }}>Sunset Blvd.</span>
                  </strong>{" "}
                  #175 Los Angeles, CA 90046
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                }}
              >
                <strong>
                  <span >&nbsp;</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  If you fail to comply with any or all of the requirements above, your
                  notice may not be valid.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Your Content</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  You retain ownership of the intellectual property rights in any
                  information, submissions or comments you provide or publish on our Website
                  (“Your Content”). VOTUZA does not claim ownership over any of Your
                  Content. These terms do not grant us any license or rights to Your Content
                  except for the limited purposes described in these terms and conditions.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA shall be free, but not obligated, to use any ideas, concepts,
                  know-how, text, photographs, images or techniques ("User Submissions")
                  contained in any communication you send to or post on the Website for any
                  purpose whatsoever including, but not limited to, use, edit, copy,
                  reproduce, disclose, display, publish, remove, prepare derivative works
                  from, perform, distribute, exhibit, broadcast, or otherwise exploit the
                  User Submissions, in whole or in part, in any form, media or technology,
                  now known or hereafter developed. You understand that such User
                  Submissions may be accessed and viewed by others, including by the general
                  public, and, whether or not such User Submissions are published, VOTUZA
                  does not guarantee any confidentiality with respect to any User
                  Submissions. You are solely responsible for your own User Submissions and
                  the consequences of publishing them on the Website. VOTUZA reserves the
                  right to determine in its sole discretion whether User Submissions are
                  appropriate and comply with these Website Terms and Conditions.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA does not regularly review posted comments but reserves the right
                  (but not the obligation) to monitor and edit or remove any comments
                  submitted to the Website. You grant VOTUZA the right to use the name that
                  you submit in connection with any comments. You agree not to use a false
                  email address, impersonate any person or entity, or otherwise mislead as
                  to the origin of any comments you submit. You are and shall remain solely
                  responsible for the content of any comments you make and you agree to
                  indemnify VOTUZA and its affiliates for all claims resulting from any
                  Comments you submit. VOTUZA and its affiliates take no responsibility and
                  assume no liability for any comments submitted by you or any third
                  party.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  If you do post content or submit material, and unless we indicate
                  otherwise, you grant VOTUZA a nonexclusive, royalty-free, perpetual,
                  irrevocable, and full licensable right to use, reproduce, modify, adapt,
                  publish, translate, create derivative works from, distribute, and display
                  such content throughout the world in any media, but only for the limited
                  purposes of: (1) assisting VOTUZA to improve its services related to the
                  Website, and (2) aggregating Your Content in de-identified form for use
                  with third parties who are subjects of the surveys to assist the third
                  parties to improve their services, as permitted by the VOTUZA Privacy
                  Policy.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  In addition, you represent and warrant that you own or control the
                  appropriate rights in and to Your Content, including any intellectual
                  property owned by third parties.&nbsp;
                </span>
              </p>

 
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Website Linking
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  Linking to any VOTUZA web page is prohibited absent express written
                  permission from VOTUZA. Framing, inline linking or other association of
                  the VOTUZA Website or its Materials with links, advertisements and/or
                  other information not originating from the VOTUZA Website is expressly
                  prohibited. &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  Periodically, links may be established from this Website to one or more
                  external Websites or resources operated by third parties (the “Third Party
                  Sites”). These links are provided for your convenience only. In addition,
                  certain Third Party Sites also may provide links to the VOTUZA Website.
                  None of such links should be deemed to imply that VOTUZA endorses the
                  Third Party Sites or any content therein.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA does not control and is not responsible or liable for any Third
                  Party Sites or any content, advertising, products, or other materials on
                  or available from such Third Party Sites. Access to any Third Party Sites
                  is at your own risk and VOTUZA will have no liability arising out of or
                  related to such Websites and/or their content or for any damages or loss
                  caused or alleged to be caused by or in connection with any purchase, use
                  of or reliance on any such content, goods, or services available on or
                  through any such Third Party Sites.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Prohibited Uses of Website
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  You shall not post, transmit, redistribute, upload, or promote any
                  communications, content or materials that (a) contain corrupted files,
                  viruses, or any other similar software files, the intent of which is to
                  damage the operation of another's computer; (b) are unlawful, threatening,
                  harassing, abusive, defamatory, invasive of privacy or publicity rights,
                  infringing of any intellectual property rights, vulgar, obscene, sexually
                  explicit, hateful, profane, indecent, racially or ethnically derogatory,
                  or otherwise objectionable; (c) contain chain letters or pyramid schemes;
                  (d) contain any unsolicited advertising, promotional materials, or other
                  forms of solicitation to other users, individuals or entities; (e)
                  impersonate any person, business or entity, including our company and our
                  employees and agents; (f) encourage conduct that would constitute a
                  criminal offense; (g) give rise to civil liability; (h) otherwise violate
                  any law; or (i) in doing so, amounts to any conduct that, in the judgment
                  of VOTUZA, restricts, impairs, interferes or inhibits any other user from
                  using or enjoying the Website and/or our related services and
                  products.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  In addition to any remedies that we may have at law or in equity, if
                  VOTUZA determines, in our sole discretion, that you have violated or are
                  likely to violate the foregoing prohibitions, we may take any action we
                  deem necessary to cure or prevent the violation, including without
                  limitation, the immediate removal of the related materials from the
                  Website. We will fully cooperate with any law enforcement authorities or
                  court order or subpoena requesting or directing us to disclose the
                  identity of anyone posting such materials.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 10,
                  
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  color: "#333333",
                  fontWeight: "bold",
                  
                }}
              >
                <span style={{ fontSize: 15, color: "windowtext" }}>
                  Website Security&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  We use the industry standard encryption protocol known as Secure Socket
                  Layer (SSL) to keep the information you enter on our Website, including
                  Your Content, secure.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  You are prohibited from violating or attempting to violate the security of
                  the Website, including, without limitation, (a) accessing data not
                  intended for such user or logging onto a server or an account which the
                  user is not authorized to access; (b) attempting to probe, scan or test
                  the vulnerability of a system or network or to breach security or
                  authentication measures without proper authorization; (c) attempting to
                  interfere with service to any user, host, or network, or with access to
                  the Website, including, without limitation, via means of submitting a
                  virus to the Website, overloading, flooding, spamming, mail-bombing,
                  crashing, or usurping the Website’s resources or bandwidth; (d) sending
                  unsolicited email, including promotions and/or advertising of products or
                  services; or (e) forging any TCP/IP packet header or any part of the
                  header information in any email or newsgroup posting. Violations of
                  VOTUZA’s system or network security using any of the means above may
                  result in civil and/or criminal liability. VOTUZA will investigate
                  occurrences that may involve such violations and may involve, and
                  cooperate with, law enforcement authorities in prosecuting users who are
                  invo
                  <span style={{ color: "black" }}>
                    lved in such violations. You agree not to use any device, software or
                    routine to interfere or attempt to interfere with the proper working of
                    the Website or any activity being conducted on this Website. In some
                    jurisdictions, limitations of liability are not permitted. In such
                    jurisdictions, the foregoing limitations on liability may not apply to
                    you.
                  </span>
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  You also agree not to use or attempt to use any engine, software, tool,
                  agent or other device or mechanism (including without limitation spiders,
                  bots, crawlers, avatars or intelligent agents) to navigate or search this
                  Website other than the search engine and search agents available from
                  VOTUZA and other than generally available third-party web browsers (e.g.,
                  Netscape Navigator, Microsoft Explorer, or Mozilla Firefox).
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>
                    Registration, Accounts and Passwords
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  You are responsible for the personal protection and security of any
                  password or username that you may use to access this Website. You are
                  responsible for all direct or indirect damages and liable for all activity
                  conducted on this Website that can be linked or traced back to your
                  username or password. You are obligated to immediately report a lost or
                  stolen password or username to VOTUZA.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Consent to Notice</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  When you visit this Website or send e-mails to us, you are communicating
                  with us electronically. You consent to receive communications from us
                  electronically. We will communicate with you by e-mail and/or by posting
                  notices on this Website. You agree that all agreements, notices,
                  disclosures and other communications that we provide to you electronically
                  satisfy any legal requirement that such communications be in writing.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Privacy Statement.</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  VOTUZA is committed to protecting the privacy of the visitors to this
                  Website. For information on how information is collected, used, or
                  disclosed by VOTUZA in connection with your use of this Website, please
                  consult our{" "}
                  <a href="http://www.ae.com/web/help/privacy_policy.jsp?topic=1">
                    <span style={{ color: "black" }}>Privacy Policy</span>
                  </a>
                  .&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Disclaimer of Warranty
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  YOUR USE OF THE WEBSITE IS AT YOUR RISK. THE INFORMATION, MATERIALS AND
                  SERVICES PROVIDED ON OR THROUGH THE WEBSITE ARE PROVIDED “AS IS” WITHOUT
                  ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL
                  PROPERTY. VOTUZA NOR ANY OF THEIR RESPECTIVE AFFILIATES OR SUBSIDIARIES
                  WARRANT THE ACCURACY OR COMPLETENESS OF THE INFORMATION, MATERIALS OR
                  SERVICES PROVIDED ON OR THROUGH THE WEBSITE. THE INFORMATION, MATERIALS
                  AND SERVICES PROVIDED ON OR THROUGH THIS WEBSITE MAY BE OUT OF DATE, AND
                  VOTUZA, NOR ANY OF THEIR RESPECTIVE AFFILIATES OR SUBSIDIARIES MAKES ANY
                  COMMITMENT OR ASSUMES ANY DUTY TO UPDATE SUCH INFORMATION, MATERIALS OR
                  SERVICES. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO
                  THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
                  PROHIBITIONS.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  ALL SERVICES AND INFORMATION ACQUIRED THROUGH THE WEBSITE ARE PROVIDED “AS
                  IS” AND ARE SUBJECT ONLY TO ANY APPLICABLE WARRANTIES OF THEIR RESPECTIVE
                  AUTHORS, CREATERS, DISTRIBUTORS AND SUPPLIERS, IF ANY. TO THE FULLEST
                  EXTENT PERMISSIBLE BY APPLICABLE LAW, VOTUZA HEREBY DISCLAIMS ALL
                  WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, ANY IMPLIED
                  WARRANTIES WITH RESPECT TO THE PRODUCTS AND SERVICES LISTED OR ACQUIRED ON
                  OR THROUGH THE WEBSITE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
                  WE HEREBY EXPRESSLY DISCLAIM ALL LIABILITY FOR PRODUCT DEFECT OR FAILURE,
                  CLAIMS THAT ARE DUE TO NORMAL WEAR, PRODUCT MISUSE, ABUSE, PRODUCT
                  MODIFICATION, IMPROPER PRODUCT SELECTION, NON-COMPLIANCE WITH ANY CODES,
                  OR MISAPPROPRIATION.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Limitation of Liability
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  IN NO EVENT WILL VOTUZA BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES FOR USE OF THIS
                  WEBSITE OR ANY OTHER HYPERLINKED WEBSITES, INCLUDING, WITHOUT LIMITATION,
                  LOST PROFITS OR REVENUES, COSTS OF REPLACEMENT, BUSINESS INTERRUPTIONS,
                  LOSS OF DATA OR DAMAGES RESULTING FROM USE OF OR RELIANCE ON THE
                  INFORMATION PRESENTED ON OR THROUGH THE WEBSITE. IN SOME JURISDICTIONS,
                  LIMITATIONS OF LIABILITY ARE NOT PERMITTED. IN SUCH JURISDICTIONS, THE
                  FOREGOING LIMITATIONS ON LIABILITY MAY NOT APPLY TO YOU. IN THE EVENT OF
                  ANY PROBLEM WITH THE WEBSITE OR ANY CONTENT THEREIN, YOU AGREE THAT YOUR
                  SOLE REMEDY IS TO CEASE USING THE WEBSITE.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Indemnification</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  YOU AGREE TO INDEMNIFY AND HOLD VOTUZA, ITS DIRECTORS, OFFICERS,
                  EMPLOYEES, AGENTS, AND AFFILIATES HARMLESS FROM AND AGAINST ANY AND ALL
                  CLAIMS, DAMAGES, COSTS AND EXPENSE, INCLUDING ATTORNEY FEES, ARISING FROM
                  OR RELATED TO YOUR USE OF THIS WEBSITE OR BREACH OF THE TERMS AND
                  CONDITIONS.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Dispute Resolution</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  Mindful of the high cost of litigation, not only in dollars but also in
                  time and energy, both you and VOTUZA agree to the following dispute
                  resolution procedure: In the event of any controversy, claim, action or
                  dispute arising out of or related to your use of this Websites, including
                  and not limited to the breach, enforcement, interpretation, or validity of
                  this Agreement or any part of it (“Dispute”), the party asserting the
                  Dispute shall first try in good faith to settle such Dispute by providing
                  written notice to the other party by registered mail describing the facts
                  and circumstances (including any relevant documentation) of the Dispute,
                  and allowing the receiving party 30 days from the date of mailing to
                  respond to the Dispute. Notice shall be sent to:
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                  textIndent: ".5in",
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>Email:</span>
                </strong>
                <span style={{ fontSize: 15, color: "black" }}>
                  &nbsp;<a href="mailto:info@VOTUZA.com">info@votuza.com</a>
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                  textIndent: ".5in",
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>
                    Mailing Address:&nbsp;
                  </span>
                </strong>
                <span style={{ fontSize: 15, color: "black", }}>
                  8033{" "}
                  <strong>
                    <span style={{ fontWeight: "normal" }}>Sunset Blvd.</span>
                  </strong>{" "}
                  #175 Los Angeles, CA 90046
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  
                  textIndent: ".5in",
                 
                }}
              >
                <span >&nbsp;</span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  Unless you indicate otherwise in your notice, VOTUZA shall respond to your
                  notice using your address in the profile associated with your account on
                  the Website.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  In the event that VOTUZA is unable to resolve the dispute with you, you
                  and VOTUZA both agree the parties shall resolve their dispute utilizing
                  binding arbitration, which shall be adjudicated by the American
                  Arbitration Association in Delaware. &nbsp;&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Jurisdiction
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA controls this Website from its offices within the State of
                  California. VOTUZA does not imply that the materials published on this
                  Website are appropriate for use outside of the United States. &nbsp;If you
                  access this Website from outside of the United States, you do so on your
                  own initiative and you are responsible for compliance with local laws. The
                  terms of this Website shall be governed by the laws of the state of
                  Delaware, without giving effect to its conflict of laws provisions.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <strong>
                  <span style={{ fontSize: 15, color: "black" }}>No Other Agreements</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                 
                }}
              >
                <span style={{ fontSize: 15, color: "black" }}>
                  The provisions and conditions of the Terms of Use, the Privacy Policy and
                  the Website constitute the entire agreement between you and VOTUZA related
                  to the use of this Website and supersedes any prior agreements or
                  understandings not incorporated in the Terms of Use.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  fontSize: 19,
                  fontFamily: '"Arial",sans-serif',
                  lineHeight: "normal",
                  color: "#666666",
                  fontWeight: "bold",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span
                  style={{
                    fontSize: 15,
                    
                    color: "windowtext"
                  }}
                >
                  Revisions
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginTop: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  VOTUZA may terminate, change, suspend or discontinue any aspect of this
                  Website, including the availability of any features of this Website, at
                  any time and without notice. VOTUZA also reserves the right, at its sole
                  discretion, to change, modify, add or remove any portion of these terms in
                  whole or in part, at any time and without notice. VOTUZA may terminate the
                  authorization, rights and license given above at any time and without
                  notice. Your continued use of this Website after any changes to these
                  terms are posted will be considered acceptance of those changes.
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <strong>
                  <span >Termination</span>
                </strong>
                <span >
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;This Agreement is effective unless and until terminated by either
                  you or VOTUZA. You may terminate this Agreement at any time. VOTUZA also
                  may terminate this Agreement at any time and may do so immediately without
                  notice. VOTUZA may deny you access to this Website, if in VOTUZA's sole
                  discretion you fail to comply with any term or provision of this
                  Agreement. Upon any termination of this Agreement by either you or VOTUZA,
                  you must promptly destroy all materials, downloaded or otherwise, obtained
                  from this Website, as well as all copies of such materials, whether made
                  under the terms of this Agreement or otherwise.&nbsp;
                </span>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <strong>
                  <span >Additional Assistance</span>
                </strong>
              </p>
              <p
                style={{
                  marginRight: "0in",
                  marginLeft: "0in",
                  
                  
                  margin: "0in",
                  marginBottom: "12.0pt",
                  
                }}
              >
                <span >
                  If you do not understand any of the foregoing Terms and Conditions or if
                  you have any questions or comments, we invite you to contact our Customer
                  Service Department by e-mail at{" "}
                  <a href="mailto:info@VOTUZA.com">
                    info@votuza.com
                  </a>
                  <span style={{ color: "black" }}>.</span> &nbsp;
                </span>
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(Legal);
