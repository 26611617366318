import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ContactForm from '../Common/ContactForm';
import $ from "jquery";
import * as api from '../../api';

class About extends Component {
  constructor(props) {
    super(props);
    this.ceoRef = React.createRef();
    this.state = {
    }
  }

  // componentDidMount = () => {
  //   const glow = $('.confirm_selection');
  //   console.log('glow:', glow);
  //   console.log('ceoRef:', this.ceoRef);
  //   this.interval = setInterval(() => {
  //     this.ceoRef.current.className === 'glow' ? this.ceoRef.current.className = '' :  this.ceoRef.current.className = 'glow';
  //   }, 1000);
  // };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  render() {
    const title = <span ref={this.ceoRef} id="glow-container" style={{ color: 'red' }}>Attention CEOs</span>
    const messages = [
      <span>Now you can view customer complaints and rankings to take action. View your competitors and benchmark other industries with our comprehensive reports. Contact our team today to resolve your customers' issues by focusing on improving the quality of your customer service.</span>
    ];
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container' style={{ paddingBottom: '0px'}}>
          <div className='left-padding' style={{ paddingTop: '50px' }}>
            <div style={{ paddingBottom: '40px' }}>
              <Typography variant='h2' color='primary' style={{ zIndex: 5 }}>
                Sales Reporting <span style={{ fontSize: '30px' }}>unlock valuable feedback</span>
              </Typography>
            </div>
            <div>
              <Typography variant='h4' className="text-grey" style={{ lineHeight: 1 }}>{title}</Typography>
            </div>
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
              {messages.map((message) => (
                <div style={{ marginBottom: '30px' }}>
                  <Typography variant='h4' className="text-grey" style={{ lineHeight: 1 }}>{message}</Typography>
                </div>
              ))}
            </div>
            <ContactForm reason="Sales reporting" showSalesInputs={true}/>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(About);
