import React, { Fragment } from 'react';
import Loading from '../Common/Loading';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CloseIcon from '@material-ui/icons/Close';
import queryString from 'query-string'
import * as api from '../../api';

const iconSize = 50;

export default class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    const jwt = values.jwt;
    const redirectUrl = values.redirectUrl;
    this.state = {
      loading: true,
      jwt,
      redirectUrl
    }
  }

  componentWillMount = async () => {
    try {
      const result = await api.makeRequest(api.verifyAccount, this.state.jwt);
      this.setState({ loading: false, verifySuccess: true });
    } catch(e) {
      console.log('e:', e);
      alert('verification failed');
      this.setState({ loading: false, verifySuccess: false });
    }
  }

  handleClose = () => {
    window.location.href = this.state.redirectUrl;
  }

  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        <Modal
          open={true}
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255, 255, 0.97)'}
          }}
          style={{ overflowY: 'scroll' }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
            <div style={{ position: 'absolute', left: '20px', top: '20px'}}>
              <IconButton
                onClick={this.handleClose}
                color="primary"
                size="large"
                disabled={loading}
              >
                <CloseIcon style={{ height: '3.5em', width: '3.5em'}}/>
              </IconButton>
            </div>
            { loading ?
              <div>
                <Loading />
              </div>
              :
              <Fragment>
                <div style={{ marginBottom: '50px' }}>
                  <VerifiedUserIcon style={{ height: '5em', width: '5em', fill: '#9cd70a'}} />
                </div>
                <div style={{ maxWidth: '600px', margin: 'auto', marginTop: '0px', marginBottom: '0px', textAlign: 'center' }}>
                  <Typography color='textSecondary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>{this.state.verifySuccess? "You're account has been verified" : "Verification failed" }</Typography>
                  {this.state.verifySuccess && <Typography color='textSecondary' style={{ fontSize: '1rem', marginBottom: '20px' }}>You can continue from where you left off from your original tab or close this popup.</Typography>}
                </div>
              </Fragment>
            }
          </div>
        </Modal>
      </Fragment>
    )
  }
}
