import { Component } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom'
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';

class FeaturedCategories extends Component {
  constructor(props) {
    super(props);
    const categories = getCategoriesFromStorage() || [];
    this.state = {
      categories: categories,
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {
      console.log('e:', e);
    }
  }

  selectCategory = (category) => {
    if(category === 'others') {
      this.props.history.push(`categories`);
    } else {
      this.props.history.push(`category/${category._id}?name=${category.name}`);
    }
  }

  render() {
    const { categories } = this.state;
    const featuredCategories = categories.filter(category => category.featured);
    return (
      <div className='container-home'>
        <div className='mobile-featured-cat'>
          <div style={{ paddingBottom: '20px' }}>
            <Typography variant='h5' style={{ color: '#9cd70a' }}>
              Featured Categories
            </Typography>
          </div>
            {featuredCategories.length > 0 &&
              <div>
                {featuredCategories.slice(0, featuredCategories.length - 1).map( (category, index) => (
                  <div>
                    <Typography variant='h1' color='textPrimary' className='green-hover' style={{ lineHeight: 1, display: 'inline', color: '#034e5b' }} onClick={() => this.selectCategory(category)}>
                      {category.name}
                    </Typography>
                    <div style={{ display: 'inline-block', overflow: 'hidden', marginLeft: '5px' }}>
                      <span style={{ fontSize: '1rem', color: '#9cd70a', fontWeight: 700 }}>{category.poll_count} Polls</span>
                    </div>
                  </div>
                ))}
                <div className='home-about-holder' style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <div>
                      <Typography variant='h1' className='green-hover' color='textPrimary' style={{ lineHeight: 1, display: 'inline', color: '#034e5b' }}  onClick={() => this.selectCategory(featuredCategories[featuredCategories.length-1])}>
                        {featuredCategories[featuredCategories.length-1].name}
                      </Typography>
                      <div style={{ display: 'inline-block', overflow: 'hidden', marginLeft: '5px' }}>
                        <span style={{ fontSize: '1rem', color: '#9cd70a', fontWeight: 700 }}>{featuredCategories[featuredCategories.length-1].poll_count} Polls</span>
                      </div>
                    </div>
                    <div>
                        <Typography variant='h1' lassName='green-hover' className='pointer' style={{ lineHeight: 1, color: '#034e5b' }} onClick={() => this.selectCategory('others')}>
                          Others +
                        </Typography>
                    </div>
                  </div>
                </div>
              </div>
            }
        </div>
      </div>
    )
  }
}

export default withRouter(FeaturedCategories);