import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CaliforniaPrivacyPdf from '../../assets/Votuza_California_Consumer_Privacy_Act.pdf';
import Button from '@material-ui/core/Button';
import * as api from '../../api';

const message = "A website designed to hold companies accountable for the highest level of customer service for all consumers that support those companies.";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container' style={{ paddingBottom: '0px'}}>
          <div className =" mobile-center left-padding" style={{ paddingTop: '50px' }}>
            <div style={{ paddingBottom: '10px' }}>
              <Typography variant='h2' color='primary' style={{ zIndex: 5 }}>
                VOTUZA <span style={{ fontSize: '30px' }}>Privacy Policy</span>
              </Typography>
            </div>
            <div>
                <br/>
                <p>Welcome to VOTUZA (“Our Company”). VOTUZA collects user information to provide direct feedback to corporations, organizations, and entities on social and business issues to effect social change and to empower consumers. This privacy policy will explain how VOTUZA uses the personal data we collect from you when you use our website. This privacy policy covers the following topics:</p>
                <ul id="l1">
                   
                      <h2>Data we collect</h2>
                      <p>VOTUZA collects the following data:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Personal identification information including your name, email, gender, age, geographic location, IP address, MAC address, ethnic background and other information that will be a part of your profile or account on our website.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Your comments and/or response to our surveys and services on our website or via email.</p>
                    </li>

                      <h2>How we collect your data</h2>
                      <p>You directly provide VOTUZA with most of the data we collect. We collect data and process data when you:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Register for a user account on our website.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Create a profile.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Comment or respond to any surveys or services on our website or via email.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Use or view our website via your browser&#39;s cookies.</p>
                    </li>
                      <h2>How we use your data</h2>
                      <p>VOTUZA collects your data so that we can:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Process and aggregate your comments and responses.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Manage your account.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Email you with feedback or responses to your inquiries with regard to our services.</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Provide your comments and feedback to corporations, organizations or entities that we work with.</p>
                      </li>

                      <h2>How we store your data</h2>
                      <p>The personal data that we collected from you is stored within a country of the European Union but may also, whenever necessary, be transferred to and processed in the United States. Any such transfer of your personal data will be carried out in compliance with applicable laws and without undermining your privacy rights.</p>
                      <p>VOTUZA will keep your personal data for as long as your account is active. If at anytime your account becomes inactive for more than seven (7) years or is canceled by you, we will delete your data by removing all personal identification information from our servers.</p>
                      <h2>Marketing</h2>
                      <p>You have the right at any time to stop VOTUZA from contacting you for marketing purposes. Please be aware that if you have agreed to receive marketing, you may always opt out at a later date<span >.</span></p>
                      <p>If you no longer wish to be contacted for marketing purposes, please visit your profile settings to unsubscribe.</p>
                      <h2>Your data protection rights</h2>
                      <p>VOTUZA would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following, under certain conditions:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">The right to access<span class="p"> - You have the right to request VOTUZA for copies of your personal data. We may charge you a small fee for this service</span><span class="s1">.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3" >The right to rectification<span class="p"> - You have the right to request that VOTUZA correct any information you believe is inaccurate.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">The right to erasure<span class="p"> - You have the right to request that VOTUZA erase your personal data</span><span class="s4">.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">The right to restrict processing<span class="p"> - You have the right to request that VOTUZA restrict theprocessing of your personal data</span><span class="s4">.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">The right to object to processing<span class="p"> - You have the right to object to VOTUZA&#39;s processing of your personal data.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">The right to data portability<span class="p"> - You have the right to request that VOTUZA transfer the data that we have collected to another organization, or directly to you</span><span class="s4">.</span></p>
                      </li>

                      <p>If you make a request regarding any of the above, we will endeavor to respond to you within one month<span>. </span>To contact us by email, please use the following: <a href="mailto:info@VOTUZA.com" class="a" target="_blank">info@votuza.com</a>.</p>
                      <h2>Cookies</h2>
                      <p class="s7"><span class="s6">Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or other similar technology. </span><span class="p">For more information on cookies and related technology, please visit </span>allaboutcookies<span>.</span>org<span class="p">.</span></p>
                      <h2>How we use cookies</h2>
                      <p>VOTUZA uses cookies in a range of ways to improve your experience on our website, including:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Keeping you signed in;</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Understanding how you use our website; and</p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p>Track your comments and responses to our surveys and services.</p>
                      </li>

                      <p class="s6">VOTUZA uses the following types of cookies:</p>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">Functionality<span class="p"> - VOTUZA uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</span></p>
                  </li>
                  <li data-list-text="•" style={{ marginLeft: '15px' }}>
                      <p class="s3">Promotions<span class="p"> - VOTUZA uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. VOTUZA may share some of this data with the corporations, organizations and entities to which our surveys and services relate. This means that when you visit another website, you may be shown advertising based on your browsing patterns onour website</span><span class="s1">.</span></p>
                      </li>

                <h2>Managing cookies</h2>
                <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>
                <h2>Privacy policies of other websites</h2>
                <p>The VOTUZA website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, our privacy policies may not apply and you should read their privacy policy to understand how their policies and treatment of data may differ from ours.</p>
                <p class="s9" style={{fontWeight: 'bold'}}>Minors</p>
                <p class="s6">We do not collect any personal data directly from individuals under the age of 13 on our websites. If we discover that any such information is in our possession, we will delete it.</p>
                <p class="s9"style={{fontWeight: 'bold'}}>California Privacy Rights</p>
                <p class="s6">Visitors, users, and others who reside in the State of California are also subject to our California Privacy Notice, which can be accessed <a href={CaliforniaPrivacyPdf} class='a' target="_blank" style={{ textDecoration: 'underline' }}>here</a>.</p>
                <h2>Changes to our privacy policy</h2>
                <p>VOTUZA keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on <u>April 6, 2022</u>.</p>
                <h2>Contacting us</h2>
                <p>If you have any questions about VOTUZA&#39;s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us by email at <a href="mailto:info@votuza.com" class="a" target="_blank">info@VOTUZA.com</a>.</p>
                <div>
                <h2>
                    <a href={CaliforniaPrivacyPdf} class='a' target="_blank" style={{ textDecoration: 'underline' }}>Privacy Notice under the California Consumer Privacy Act of 2018</a>
                </h2>
                </div>
                </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(Privacy);
