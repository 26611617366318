import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, Modal, Typography, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import Icon from '@material-ui/core/Icon';
import { login, register, registerFacebook, attemptAuthentication, resetPasswordEmail } from '../../auth';
const { REACT_APP_FACEBOOK_APPID, REACT_APP_CLIENT_URL } = process.env;

export default class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    const years = [];
    const year = new Date().getFullYear() - 10;
    for (let i = 0; i < 100; i++) {
      years.push(String(year - i));
    }
    this.emailRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.zipRef = React.createRef();
    this.passwordRef = React.createRef();
    this.passwordConfirmRef = React.createRef();
    this.state = {
      showLogin: props.showLogin,
      email: '',
      password: '',
      joinEmail: '',
      joinPassword: '',
      joinPasswordConfirm: '',
      joinFirstName: '',
      joinLastName: '',
      joinZip: '',
      birthYear: null,
      gender: null,
      race: null,
      ethnicity: null,
      maritalStatus: null,
      income: null,
      submitLoading: false,
      emailValidated: null,
      passwordValidated: null,
      joinEmailValidated: null,
      joinPasswordValidated: null,
      joinPasswordConfirmValidated: null,
      joinFirstNameValidated: null,
      joinLastNameValidated: null,
      joinZipValidated: null,
      awaitingVerification: false,
      marketingChecked: false,
      termsChecked: false,
      showResetPassword: false,
      years
    }
  }

  toggleShowLogin = () => {
    if(this.state.submitLoading) {
      return;
    }
    this.setState({ showLogin: !this.state.showLogin });
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  validateRegistration = () => {
    this.validateEmail();
    this.validateFirstName();
    this.validateLastName();
    this.validatePassword();
    this.validatePasswordConfirm();
    this.validateZip();
  }

  validateEmail = (value = this.state.joinEmail) => {
    console.log('validate Email:', value);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(value).toLowerCase())) {
      this.setState({ joinEmailValidated: 'success' })
    } else {
      this.setState({ joinEmailValidated: 'error' })
    }
  }

  validateFirstName = (value = this.state.joinFirstName) => {
    console.log('validateFirstName:', this.state.firstName)
    if(value.length > 1) {
      this.setState({ joinFirstNameValidated: 'success' })
    } else {
      this.setState({ joinFirstNameValidated: 'error' })
    }
  }

  validateLastName = (value = this.state.joinLastName) => {
    if(value.length > 1) {
      this.setState({ joinLastNameValidated: 'success' })
    } else {
      this.setState({ joinLastNameValidated: 'error' })
    }
  }

  validatePassword = (value = this.state.joinPassword) => {
    if (value.length > 6) {
      this.setState({ joinPasswordValidated: 'success' })
    } else {
      this.setState({ joinPasswordValidated: 'error' })
    }
  }

  validatePasswordConfirm = (value = this.state.joinPasswordConfirm) => {
    if(this.state.joinPassword === value) {
      this.setState({ joinPasswordConfirmValidated: 'success' })
    } else {
      this.setState({ joinPasswordConfirmValidated: 'error' })
    }
  }

  validateZip = (value = this.state.joinZip) => {
    if(value.length === 5) {
      this.setState({ joinZipValidated: 'success' })
    } else {
      this.setState({ joinZipValidated: 'error' })
    }
  }

  handleBirthYear = (e) => {
    this.setState({ birthYear: e.target.value });
  }

  handleGender = (e) => {
    this.setState({ gender: e.target.value });
  }

  handleRace = (e) => {
    this.setState({ race: e.target.value });
  }

  handleEthnicity = (e) => {
    this.setState({ ethnicity: e.target.value });
  }

  handleMaritalStatus = (e) => {
    this.setState({ maritalStatus: e.target.value });
  }

  handleIncomeBracket = (e) => {
    this.setState({ income: e.target.value });
  }

  handlePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  handleJoinEmail = (e) => {
    this.setState({ joinEmail: e.target.value });
    if(this.state.joinEmailValidated) {
      this.validateEmail(e.target.value);
    }
  }

  handleJoinPassword = (e) => {
    this.setState({ joinPassword: e.target.value });
    if(this.state.joinPasswordValidated) {
      this.validatePassword(e.target.value);
    }
  }

  handleJoinPasswordConfirm = (e) => {
    this.setState({ joinPasswordConfirm: e.target.value });
    if(this.state.joinPasswordConfirmValidated) {
      this.validatePasswordConfirm(e.target.value);
    }
  }

  handleJoinFirstName = (e) => {
    this.setState({ joinFirstName: e.target.value });
    if(this.state.joinFirstNameValidated) {
      this.validateFirstName(e.target.value);
    }
  }

  handleJoinLastName = (e) => {
    this.setState({ joinLastName: e.target.value });
    if(this.state.joinLastNameValidated) {
      this.validateLastName(e.target.value);
    }
  }

  handleJoinZip = (e) => {
    this.setState({ joinZip: e.target.value });
    if(this.state.joinZipValidated) {
      this.validateZip(e.target.value);
    }
  }

  handleResetEmail = (e) => {
    this.setState({ resetEmail: e.target.value });
  }

  login = async () => {
    if(this.state.submitLoading) {
      return;
    }
    console.log('login');
    if(!this.state.email || !this.state.password) {
      alert('Missing login fields');
      return;
    }
    this.setState({ submitLoading: true })
    try {
      await login(this.state.email, this.state.password);
      this.props.handleClose();
      this.props.openAlert("Login successful");
    } catch(e) {
      console.log('e:', e);
      this.setState({ submitLoading: false })
      alert('Invalid email or password');
    }
  }

  getLocation = () => {
    if (this.props.pollId) {
      return `${window.location.origin}/poll/${this.props.pollId}`;
    }
    return window.location.href;

  }

  register = async () => {
    if(this.state.submitLoading) {
      return;
    }
    this.validateRegistration();
    if(!this.state.termsChecked || !this.state.gender || !this.state.race || !this.state.maritalStatus || !this.state.income || !this.state.birthYear || this.state.joinEmailValidated !== 'success' || this.state.joinPasswordValidated !== 'success' || this.state.joinPasswordConfirmValidated !== 'success' || this.state.joinFirstNameValidated !== 'success' || this.state.joinLastNameValidated !== 'success' || this.state.joinZipValidated !== 'success'){
      alert('Missing or invalid fields');
      return;
    }
    this.setState({ submitLoading: true });
    try {
      console.log('register:', this.state.marketingChecked);
      await register(this.state.joinEmail, this.state.joinPassword, this.state.joinFirstName, this.state.joinLastName, this.state.joinZip, this.state.birthYear, this.state.gender, this.state.race, this.state.marketingChecked, this.state.income, this.state.maritalStatus, this.getLocation());
      this.toggleAwaitingVerification();
      // this.props.handleClose();
    } catch(e) {
      console.log('e:', e);
      this.setState({ submitLoading: false })
      alert(e.message);
    }
  }

  toggleAwaitingVerification = () => {
    this.setState({ awaitingVerification: true });
    this.props.openAlert("Registration successful");
    setTimeout( async () => {
      try {
        this.checkVerification();
      } catch(e) {
        console.log('e:', e);
      }
    })
  }

  checkVerification = () => {
    console.log('checkVerification');
    setTimeout( async () => {
      try {
        const { verified } = await attemptAuthentication();
        console.log('verification:', verified);
        if (verified) {
          console.log('good!');
          this.props.openAlert("Successfully verified");
          this.props.handleClose();
        } else {
          this.checkVerification();
        }
      } catch(e) {
        console.log('e:', e);
      }
    }, 5000)
  }

  handleClose = () => {
    if(this.state.submitLoading) {
      return;
    }
    this.props.handleClose();
  }

  responseFacebook = async (response) => {
    console.log(response);
    if (response && response.accessToken) {
      try {
        const user = await registerFacebook(response.accessToken);
        this.props.handleClose(user);
        this.props.openAlert("Login successful");
      } catch(e) {
        console.log('e:', e);
        alert(e.message);
      }
    } else {
      alert("error logging in")
    }
  }

  toggleMarketing = () => {
    this.setState({ marketingChecked: !this.state.marketingChecked})
  }

  toggleTerms = () => {
    this.setState({ termsChecked: !this.state.termsChecked})
  }

  submitResetPassword = () => {
    resetPasswordEmail(this.state.resetEmail);
    this.props.handleClose();
    this.props.openAlert("Reset password email sent")
  }

  toggleResetPassword = () => {
    this.setState({ showResetPassword: !this.state.showResetPassword });
  }

  render() {
    const registerButtonDisabled = !this.state.termsChecked || !this.state.gender || !this.state.race || !this.state.maritalStatus || !this.state.income || !this.state.birthYear || this.state.joinEmailValidated !== 'success' || this.state.joinPasswordValidated !== 'success' || this.state.joinPasswordConfirmValidated !== 'success' || this.state.joinFirstNameValidated !== 'success' || this.state.joinLastNameValidated !== 'success' || this.state.joinZipValidated !== 'success';
    const { showLogin, awaitingVerification, showResetPassword } = this.state;
    const { showModal, handleClose } = this.props;
    if (awaitingVerification) {
      return (
        <Fragment>
          <Modal
            open={showModal}
            onClose={handleClose}
            BackdropProps={{
              style: { backgroundColor: 'rgba(255,255, 255, 0.97)'}
            }}
            style={{ overflowY: 'scroll' }}
            disableAutoFocus={true}
            disableEnforceFocus={true}
          >
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
              <div style={{ marginBottom: '50px' }}>
                <EmailIcon style={{ height: '5em', width: '5em', fill: '#9cd70a'}} />
              </div>
              <div style={{ maxWidth: '600px', margin: 'auto', marginTop: '0px', marginBottom: '0px', textAlign: 'center' }}>
                <Typography color='textSecondary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Awaiting email verification</Typography>
                <Typography color='textSecondary' style={{ fontSize: '1rem', marginBottom: '20px' }}>Once you click the verification link that was sent to your email (please also check your spam and promotions inbox), this modal will automatically close.</Typography>
              </div>
            </div>
          </Modal>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Modal
          open={showModal}
          onClose={handleClose}
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255, 255, 0.99)'}
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          style={{ overflowY: 'scroll' }}
        >
          <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
            <div style={{ position: 'absolute', left: '20px', top: '20px'}}>
              <IconButton
                onClick={this.handleClose}
                color="primary"
                size="large"
                disabled={this.state.submitLoading}
              >
                <CloseIcon style={{ height: '3.5em', width: '3.5em'}}/>
              </IconButton>
            </div>
            {showResetPassword ?
              <Fragment>
                  <Typography color='primary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Reset Password</Typography>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      type="email"
                      name="email"
                      variant="standard"
                      value={this.state.resetEmail}
                      onChange={this.handleResetEmail}
                      disabled={this.state.submitLoading}
                    />
                  </div>
                  <LoadingButton loading={this.state.submitLoading} variant="contained" disabled={!this.state.resetEmail} style={{ marginTop: '20px', marginBottom: '20px', minHeight: '36.5px' }} onClick={this.submitResetPassword}>{this.state.submitLoading ? <CircularProgress style={{ color: 'white', height: '25px', width: '25px' }}/> : <span>Submit</span>}</LoadingButton>
                  <Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px', marginTop: '10px' }}><button className="text-button" onClick={this.toggleResetPassword}>Log In</button> </Typography>
              </Fragment>
              :
              <Fragment>
              {showLogin ?
                <Fragment>
                  <Typography color='primary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Sign in </Typography>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      type="email"
                      name="email"
                      variant="standard"
                      value={this.state.email}
                      onChange={this.handleEmail}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      name="password"
                      variant="standard"
                      type="password"
                      value={this.state.password}
                      onChange={this.handlePassword}
                      disabled={this.state.submitLoading}
                      onKeyPress= {(e) => {
                        if (e.key === 'Enter') {
                          this.login();
                        }
                      }}
                    />
                  </div>
                  <LoadingButton loading={this.state.submitLoading} variant="contained" disabled={!this.state.email || !this.state.password} style={{ marginTop: '20px', marginBottom: '20px', minHeight: '36.5px' }} onClick={this.login}>{this.state.submitLoading ? <CircularProgress style={{ color: 'white', height: '25px', width: '25px' }}/> : <span>Log In</span>}</LoadingButton>
                  <FacebookLogin
                     appId={REACT_APP_FACEBOOK_APPID}
                     disableMobileRedirect={true}
                     isMobile={false}
                     autoLoad={false}
                     fields="id,name,email,user_gender,first_name,last_name,user_birthday,picture,user_hometown,user_location"
                     callback={this.responseFacebook}
                     render={renderProps => (
                       <Button style={{ marginBottom: '30px', minHeight: '36.5px', backgroundColor: '#3b5998', marginTop: '0px' }} onClick={renderProps.onClick}><FacebookIcon style={{ marginRight: '10px' }}/>Login with Facebook</Button>
                     )}
                  />
                  <Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}>Not a member? <button className="text-button" onClick={this.toggleShowLogin}>Sign up</button> </Typography>
                  <Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px', marginTop: '10px' }}><button className="text-button" onClick={this.toggleResetPassword}>Forgot my password</button> </Typography>
                </Fragment>
                :
                <Fragment>
                  <Typography style={{ fontSize: '15px', color: '#034e5b', paddingTop: '40px', marginBottom: '50px' }}>You must signup to vote</Typography>
                  <Typography color='primary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Join VOTUZA </Typography>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      onBlur={() => {
                        this.validateEmail()
                      }}
                      error={this.state.joinEmailValidated === 'error'}
                      helperText={this.state.joinEmailValidated === 'error' && "Invalid email."}
                      InputProps={{
                        endAdornment: this.state.joinEmailValidated === 'success' &&<CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/>
                      }}
                      type="email"
                      name="email"
                      inputRef={this.emailRef}
                      value={this.state.joinEmail}
                      onChange={this.handleJoinEmail}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="First Name"
                      margin="normal"
                      variant="standard"
                      onBlur={() => {
                        this.validateFirstName()
                      }}
                      error={this.state.joinFirstNameValidated === 'error'}
                      helperText={this.state.joinFirstNameValidated === 'error' && "Cannot be empty"}
                      InputProps={{
                        endAdornment: this.state.joinFirstNameValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                      }}
                      inputRef={this.firstNameRef}
                      value={this.state.joinFirstName}
                      type="name"
                      name="firstname"
                      onChange={this.handleJoinFirstName}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="Last Name"
                      variant="standard"
                      onBlur={() => {
                        this.validateLastName()
                      }}
                      error={this.state.joinLastNameValidated === 'error'}
                      helperText={this.state.joinLastNameValidated === 'error' && "Cannot be empty"}
                      InputProps={{
                        endAdornment: this.state.joinLastNameValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                      }}
                      inputRef={this.lastNameRef}
                      value={this.state.joinLastName}
                      name="lastname"
                      onChange={this.handleJoinLastName}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      type="password"
                      onBlur={() => {
                        this.validatePassword()
                      }}
                      error={this.state.joinPasswordValidated === 'error'}
                      helperText={this.state.joinPasswordValidated === 'error' && "Password must be more than 6 characters long."}
                      InputProps={{
                        endAdornment: this.state.joinPasswordValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                      }}
                      inputRef={this.passwordRef}
                      value={this.state.joinPassword}
                      name="password"
                      onChange={this.handleJoinPassword}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="Repeat Password"
                      variant="standard"
                      type="password"
                      onBlur={() => {
                        this.validatePasswordConfirm()
                      }}
                      error={this.state.joinPasswordConfirmValidated === 'error'}
                      helperText={this.state.joinPasswordConfirmValidated === 'error' && "Passwords must match."}
                      InputProps={{
                        endAdornment: this.state.joinPasswordConfirmValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                      }}
                      inputRef={this.passwordConfirmRef}
                      value={this.state.joinPasswordConfirm}
                      name="passwordconfirm"
                      onChange={this.handleJoinPasswordConfirm}
                      disabled={this.state.submitLoading}
                    />
                    <TextField
                      id="standard-basic"
                      label="Zip Code"
                      variant="standard"
                      onBlur={() => {
                        this.validateZip()
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      error={this.state.joinZipValidated === 'error'}
                      helperText={this.state.joinZipValidated === 'error' && "Invalid zip code"}
                      InputProps={{
                        endAdornment: this.state.joinZipValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                      }}
                      inputRef={this.zipRef}
                      value={this.state.joinZip}
                      name="zipcode"
                      onChange={this.handleJoinZip}
                      disabled={this.state.submitLoading}
                    />
                    <FormControl>
                      <InputLabel>Birth Year</InputLabel>
                      <Select
                        value={this.state.birthYear}
                        onChange={this.handleBirthYear}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {this.state.years.map(year => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        value={this.state.gender}
                        onChange={this.handleGender}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        <MenuItem value={'male'}>male</MenuItem>
                        <MenuItem value={'female'}>female</MenuItem>
                        <MenuItem value={'non-binary'}>non-binary</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>Race</InputLabel>
                      <Select
                        value={this.state.race}
                        onChange={this.handleRace}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        <MenuItem value={'asian'}>asian</MenuItem>
                        <MenuItem value={'black'}>black</MenuItem>
                        <MenuItem value={'euro-asian'}>euro-asian</MenuItem>
                        <MenuItem value={'hispanic/latino'}>hispanic/latino</MenuItem>
                        <MenuItem value={'native-american'}>native-american</MenuItem>
                        <MenuItem value={'pacific-islander'}>pacific-islander</MenuItem>
                        <MenuItem value={'white'}>white</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>Marital Status</InputLabel>
                      <Select
                          value={this.state.maritalStatus}
                          onChange={this.handleMaritalStatus}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                      >
                        <MenuItem value={'single'}>single</MenuItem>
                        <MenuItem value={'married'}>married</MenuItem>
                        <MenuItem value={'divorced'}>divorced</MenuItem>
                        <MenuItem value={'widowed'}>widowed</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>Income Bracket</InputLabel>
                      <Select
                          value={this.state.income}
                          onChange={this.handleIncomeBracket}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                      >
                        <MenuItem value={'0-40k'}>0-40k</MenuItem>
                        <MenuItem value={'40-100k'}>40-100k</MenuItem>
                        <MenuItem value={'100k-250k'}>100k-250k</MenuItem>
                        <MenuItem value={'250k-500k'}>250k-500k</MenuItem>
                        <MenuItem value={'500k+'}>500k+</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <div style={{ maxWidth: '300px' }}>
                      <FormControlLabel
                         disabled={this.state.submitLoading}
                         control={
                           <Checkbox checked={this.state.termsChecked} color="primary" onChange={this.toggleTerms} name="Terms" />
                         }
                         style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}
                         label={<Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}><span>Agree to our <a style={{ textDecoration: 'underline', color: '#034e5b' }} target="_blank" href={`${REACT_APP_CLIENT_URL}/terms-of-use`}>Terms & Conditions</a></span></Typography>}
                       />
                     </div>
                     <div style={{ maxWidth: '300px' }}>
                       <FormControlLabel
                          disabled={this.state.submitLoading}
                          control={
                            <Checkbox checked={this.state.marketingChecked} color="primary" onChange={this.toggleMarketing} name="Terms" />
                          }
                          style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}
                          label={<Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px' }}><span>Send me VOTUZA notifications, promotions, and newsletters.</span></Typography>}
                        />
                      </div>
                  </div>
                  <LoadingButton disabled={false} loading={this.state.submitLoading} variant="contained" style={{ marginTop: '20px', marginBottom: '20px', minHeight: '36.5px' }} onClick={this.register}>{this.state.submitLoading ? <CircularProgress style={{ color: 'white', height: '25px', width: '25px' }}/> : <span>Sign up</span>}</LoadingButton>
                  <FacebookLogin
                     appId={REACT_APP_FACEBOOK_APPID}
                     disableMobileRedirect={true}
                     isMobile={false}
                     autoLoad={false}
                     fields="id,name,email,user_gender,first_name,last_name,user_birthday,picture,user_hometown,user_location"
                     callback={this.responseFacebook}
                     render={renderProps => (
                       <Button style={{ marginBottom: '30px', minHeight: '36.5px', backgroundColor: '#3b5998', marginTop: '0px' }} onClick={renderProps.onClick}><FacebookIcon style={{ marginRight: '10px' }}/>Sign up with Facebook</Button>
                     )}
                  />
                  <Typography style={{ color: 'grey', fontSize: '15px', letterSpacing: '1px', paddingBottom: '50px' }}>Already a member? <button className="text-button" onClick={this.toggleShowLogin}>Log In</button> </Typography>
                </Fragment>
              }
              </Fragment>
            }
          </div>
        </Modal>
      </Fragment >
    )
  }
}
