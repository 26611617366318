import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'
import Header from './components/Header';
import Loading from './components/Common/Loading';
import Footer from './components/Common/Footer';
import { getUser } from "./redux/selectors";
import { attemptAuthentication } from "./auth"
import HowTo from "./components/Common/HowTo";
import ShareSocialButtons from "./components/Common/ShareSocialButtons";

class ProtectedRouteWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: props.user ? false : true
    }
  }

  componentWillMount = async () => {
    try {
      await attemptAuthentication();
      this.setState({ loading: false })
    } catch(e) {
      this.props.history.push({ pathname: `/` });
    }
  }

  render() {
    const { loading } = this.state;
    // const backgroundColor = this.props.path ?
    return (
      <Route
        path={this.props.path}
        exact
        render={() => (
          <div className='full-grey' style={{ minHeight: '100vh' }}>
            {loading ?
              <Fragment>
                <Header grayColorScheme={true} />
                <Loading />
              </Fragment>
              :
              <Fragment>
                { this.props.path !== '/' && !this.props.path.includes('poll') &&
                  <Fragment>
                    <div className='green-left-background' />
                    <Header grayColorScheme={true} />
                  </Fragment>
                }
                <this.props.component {...this.props} />
              </Fragment>
            }
            <HowTo />
            <ShareSocialButtons/>
            <Footer />
          </div>
        )}
      />
    );
  }
}

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(ProtectedRouteWrapper));
