import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import ReactTooltip from "react-tooltip";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ContactForm from '../Common/ContactForm';
import * as api from '../../api';

const messages = [
  "A website designed to hold companies accountable for the highest level of customer service on behalf of all customers that support those companies.",
  "A social media platform transporting relevant market research from the community through a vortal to create an immediate brand call to action and public resolution.",
  <span>An unconventional voting system utilizing the voice of the American people to make a change within various companies through a &nbsp; 
      <a
        style={{ textDecoration: 'underline' }}
        data-for="vortal-tooltip"
        data-tip="a voting portal for gathering information from the community"
      >
        vortal
      </a>
      &nbsp; for all people.
      <ReactTooltip
        id="vortal-tooltip"
        backgroundColor='#034e5b'
        effect="solid"
      />
  </span>
];

const voicingMessages = [
  <span>CEOs will <span style={{ textDecoration: 'underline' }}>no longer be able to hide</span> from the dissatisfaction of their customers  when it’s viral news.</span>,
  <span>CEOs will no longer <span style={{ textDecoration: 'underline' }}>be able to ignore</span> the relevant information and details of poor  customer service experiences.</span>,
  <span>CEOs will <span style={{ textDecoration: 'underline' }}>no longer disregard</span> the customer's extreme frustration over company runaround and mishandling of their valid service complaints.</span>,
  <span>CEOs will no longer be able to hide behind <span style={{ textDecoration: 'underline' }}>convoluted processes</span> to submit a dissatisfaction.</span>,
  <span>CEOs will no longer be able to privately and singularly handle escalations but not <span style={{ textDecoration: 'underline' }}>broadly change root issues.</span></span>,
  <span>CEOs will no longer be able to hide their <span style={{ textDecoration: 'underline' }}>human contact methods</span> and opt for unanswered email template submissions.</span>
];

const secondaryMessages = [
  "VOTUZA's goal is to make a substantial impact to the executive structure of large corporations and make them realize the true concerns of their customers. In understanding what their customers percieve, VOTUZA hopes the final outcome of each voting process will lead to improved business practices.",
  "Although the concerns of each customer may only be a perception of the company, from the customer's point of view, perception is fact. As most voting systems tend to concentrate on the companies that are doing their best, VOTUZA believes bigger change comes from knowing who is the worst."
]

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactForm: false
    }
  }

  toggleContactForm = () => {
    console.log('close');
    this.setState({ showContactForm: !this.state.showContactForm })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { showContactForm } = this.state;
    return (
      <Fragment>
        <div style={{ position: 'relative', width: '100%' }}>
          <div className='container' style={{ paddingBottom: '0px'}}>
            <div className ="left-padding mobile-center" style={{ paddingTop: '50px' }}>
              <div style={{ paddingBottom: '40px' }}>
                <Typography variant='h2' color='primary' style={{ zIndex: 5 }}>
                  VOTUZA <span style={{ fontSize: '30px' }}>noun, verb \vō-tü-zha\</span>
                </Typography>
                <Typography variant='h3' color='primary' style={{ zIndex: 5 }}>
                  Vote. Voice. Vortal.<span style={{ fontSize:'1.1rem', verticalAlign:'super' }}>&reg;</span>
                </Typography>
              </div>
              <div>
                {messages.map((message, index) => (
                  <div style={{ marginBottom: '40px' }}>
                    <Typography variant='h5' color="primary" style={{ display: 'inline' }}>{index + 1}. </Typography>
                    <Typography variant='h5' className="text-grey" style={{ lineHeight: 1, display: 'inline' }}>{message}</Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'absolute', width: '100vw', height: '100%', backgroundColor: '#034e5b' }} />
          <div className='container' style={{ paddingBottom: '0px'}}>
            <div className ="left-padding mobile-center" style={{ paddingTop: '20px', paddingBottom: '15px' }}>
              <div style={{ position: 'relative' }}>
                <div style={{ paddingBottom: '30px' }}>
                  <Typography variant='h4' color='primary' style={{ zIndex: 5 }}>
                    The People's Voicing Platform
                  </Typography>
                </div>
                <div>
                  {voicingMessages.map((message, index) => (
                    <div style={{ marginBottom: '30px' }}>
                      <Typography variant='h5' color="primary" style={{ display: 'inline' }}><span>&#8226;</span> </Typography>
                      <Typography variant='h5' style={{ lineHeight: 1, display: 'inline', color: 'lightgrey' }}>{message}</Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <div className='container' style={{ paddingBottom: '0px'}}>
            <div className ="left-padding mobile-center" style={{ paddingTop: '30px' }}>
              <div className="mobile-flex-column">
                <div style={{ paddingBottom: '20px' }}>
                  <Typography variant='h4' color='primary' style={{ zIndex: 5 }}>
                    VOTUZA's Impact
                  </Typography>
                </div>
                {secondaryMessages.map((message, index) => (
                  <div style={{ marginBottom: '20px', marginRight: index === 0 ? '25px' : '0px', marginTop: '15px' }}>
                    <Typography variant='h7' color="textSecondary" style={{ lineHeight: 1, fontSize: '1.7rem' }}>{message}</Typography>
                  </div>
                ))}
              </div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Button onClick={this.toggleContactForm} style={{ marginTop: '40px', minWidth: '0px', paddingLeft: '25px', paddingRight: '25px', letterSpacing: '0.5px', marginBottom: '40px', height: '36.5px' }}>Contact Us</Button>
              </div>
            </div>
          </div>
        </div>
        {showContactForm && <ContactForm isModal={true} close={this.toggleContactForm} />}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default connect(mapStateToProps)(About);
