import React, { Fragment } from 'react';
import { SocialIcon } from 'react-social-icons';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
} from "react-share";
import ShareModal from './ShareModal';

const iconSize = 40;

export default class ShareSocialButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareModal: false
    }
  }

  toggleShareModal = () => {
    this.setState({ showShareModal: !this.state.showShareModal });
  }
  render() {
    const shareUrl = this.props.shareUrl || window.location.href;
    console.log('yo:', this.props.shareUrl);
    if (this.props.orientation === 'horizontal') {
      return (
        <div className='social-share-icons-mobile' style={{ zIndex: 10, marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: 'fit-content', backgroundColor: 'white', borderRadius: '10px', padding: '5px', marginBottom: '10px', border: '4px solid rgb(3, 78, 91)' }}>
            <Typography color='textSecondary' style={{ fontSize: 'clamp(1rem, 1vw, 1.25rem)' }}>Share this Page</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FacebookShareButton
              style={{ margin: '3px'}}
              url={shareUrl}
            >
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
            <LinkedinShareButton
              style={{ margin: '3px'}}
              url={shareUrl}
            >
              <LinkedinIcon size={iconSize} round />
            </LinkedinShareButton>
            <RedditShareButton
              style={{ margin: '3px'}}
              url={shareUrl}
            >
              <RedditIcon size={iconSize} round />
            </RedditShareButton>
            <TwitterShareButton
              style={{ margin: '3px'}}
              url={shareUrl}
            >
              <TwitterIcon size={iconSize} round />
            </TwitterShareButton>
            <EmailShareButton
                 style={{ margin: '3px'}}
                 url={shareUrl}
                 subject="A website recommendation for you - VOTUZA"
                 openShareDialogOnClick={true}
                 onClick={e => e.preventDefault()}
               >
              <EmailIcon size={iconSize} round />
            </EmailShareButton>
            {/* <SocialIcon url="https://www.facebook.com/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/>
            <SocialIcon url="https://www.linkedin.com/company/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/> */}
            {/* <SocialIcon url="https://www.twitter.com/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/> */}
            <IconButton onClick={this.toggleShareModal}>
              <ShareIcon style={{ color: '#034e5b' }}/>
            </IconButton>
          </div>
          {this.state.showShareModal && <ShareModal onClose={this.toggleShareModal} title="Share VOTUZA" shareUrl={shareUrl}/>}
        </div>
      )
    }
    return (
      <div className={'social-share-icons'} style={{ zIndex: this.props.zIndex || 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', marginBottom: '10px', border: '4px solid rgb(3, 78, 91)' }}>
            <Typography color='textSecondary' style={{ fontSize: 'clamp(1rem, 1vw, 1.25rem)' }}>Share this Page</Typography>
          </div>
          <FacebookShareButton
            style={{ margin: '3px'}}
            url={shareUrl}
          >
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
          <LinkedinShareButton
            style={{ margin: '3px'}}
            url={shareUrl}
          >
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
          <RedditShareButton
            style={{ margin: '3px'}}
            url={shareUrl}
          >
            <RedditIcon size={iconSize} round />
          </RedditShareButton>
          <TwitterShareButton
            style={{ margin: '3px'}}
            url={shareUrl}
          >
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
          <EmailShareButton
               style={{ margin: '3px'}}
               url={shareUrl}
               subject="A website recommendation for you - VOTUZA"
               openShareDialogOnClick={true}
               onClick={e => e.preventDefault()}
             >
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
          {/* <SocialIcon url="https://www.facebook.com/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/>
          <SocialIcon url="https://www.linkedin.com/company/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/> */}
          {/* <SocialIcon url="https://www.twitter.com/votuza" target="_black" fgColor="white" style={{width: '45px', height: '45px', marginBottom: '5px' }}/> */}
          <IconButton onClick={this.toggleShareModal}>
            <ShareIcon style={{ color: '#034e5b' }}/>
          </IconButton>
        </div>
        {this.state.showShareModal && <ShareModal onClose={this.toggleShareModal} title="Share VOTUZA" shareUrl={shareUrl}/>}
      </div>
    )
  }
}
