import React, { Fragment } from 'react';
import Loading from '../Common/Loading';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { FormControlLabel, Checkbox, Modal, Typography, Button, TextField, IconButton, InputAdornment, Button as LoadingButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress'
import queryString from 'query-string'
import * as api from '../../api';

const iconSize = 50;

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    const jwt = values.jwt;
    this.state = {
      submitLoading: false,
      password: '',
      passwordConfirm: '',
      passwordValidated: null,
      passwordConfirmValidated: null,
      jwt
    }
  }

  validatePassword = (value = this.state.password) => {
    if (value.length > 6) {
      this.setState({ passwordValidated: 'success' })
    } else {
      this.setState({ passwordValidated: 'error' })
    }
  }

  validatePasswordConfirm = (value = this.state.passwordConfirm) => {
    if(this.state.password === value) {
      this.setState({ passwordConfirmValidated: 'success' })
    } else {
      this.setState({ passwordConfirmValidated: 'error' })
    }
  }

  handlePassword = (e) => {
    this.setState({ password: e.target.value });
    if (this.state.passwordValidated) {
      this.validatePassword(e.target.value);
    }
  }

  handlePasswordConfirm = (e) => {
    this.setState({ passwordConfirm: e.target.value });
    if (this.state.passwordConfirmValidated) {
      this.validatePasswordConfirm(e.target.value);
    }
  }

  // componentWillMount = async () => {
  //   try {
  //     const result = await api.makeRequest(api.verifyAccount, this.state.jwt);
  //     this.setState({ loading: false });
  //   } catch(e) {
  //     console.log('e:', e);
  //     alert('verification failed');
  //   }
  // }

  resetPassword = async () => {
    console.log('resetPassword');
    if ( this.state.passwordValidated === 'error' || this.state.passwordConfirmValidated === 'error' || !this.state.password || !this.state.passwordConfirm) {
      alert('invalid fields');
      return;
    }
    this.setState({ submitLoading: true });
    try {
      const result = await api.makeRequest(api.resetPassword, this.state.password, this.state.passwordConfirm, this.state.jwt);
      this.setState({ submitLoading: false });
      alert('Password Successfully Reset');
      window.location.href = process.env.REACT_APP_CLIENT_URL;
    } catch(e) {
      console.log('e:', e);
      alert('password reset failed');
      this.setState({ submitLoading: false });
    }
  }

  render() {
    const { loading } = this.state;
    console.log('render resetPassword');
    return (
      <Fragment>
        <Modal
          open={true}
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255, 255, 0.97)'}
          }}
          style={{ overflowY: 'scroll' }}
        >
          <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', flexDirection: 'column', position: 'relative'}}>
            <Typography color='primary' style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Reset Your Password</Typography>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              <TextField
                id="standard-basic"
                label="Password"
                type="password"
                name="password"
                variant="standard"
                value={this.state.password}
                onChange={this.handlePassword}
                disabled={this.state.submitLoading}
                error={this.state.passwordValidated === 'error'}
                helperText={this.state.passwordValidated === 'error' && "Password must be more than 6 characters long."}
                InputProps={{
                  endAdornment: this.state.passwordValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                }}
                onBlur={() => {
                  this.validatePassword()
                }}
              />
              <TextField
                id="standard-basic"
                label="Password Confirm"
                variant="standard"
                type="password"
                value={this.state.passwordConfirm}
                onChange={this.handlePasswordConfirm}
                disabled={this.state.submitLoading}
                onKeyPress= {(e) => {
                  if (e.key === 'Enter') {
                    this.resetPassword();
                  }
                }}
                error={this.state.passwordConfirmValidated === 'error'}
                helperText={this.state.passwordConfirmValidated === 'error' && "Password must be more than 6 characters long."}
                InputProps={{
                  endAdornment: this.state.passwordConfirmValidated === 'success' &&<InputAdornment position="end"><CheckCircleIcon style={{ fill: '#9cd70a', position: 'absolute', right: '0px' }}/></InputAdornment>
                }}
                onBlur={() => {
                  this.validatePasswordConfirm()
                }}
              />
            </div>
            <LoadingButton loading={this.state.submitLoading} variant="contained" style={{ marginTop: '20px', marginBottom: '30px', minHeight: '36.5px' }} onClick={this.resetPassword}>{this.state.submitLoading ? <CircularProgress style={{ color: 'white', height: '25px', width: '25px' }}/> : <span>Reset Password</span>}</LoadingButton>
          </div>
        </Modal>
      </Fragment>
    )
  }
}
