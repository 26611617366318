import React, { Component } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Header from '../Header';
import * as api from '../../api';

class MediaHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container-home'>
          <div style={{ display: 'flex', minWidth: '300px', maxWidth: '800px', margin: 'auto', flexDirection: 'column', justifyContent: 'space-between', gap: 'clamp(20px, 3vw, 40px)', marginTop: '20px', backgroundColor: '#f6f6f6', border: '10px solid #f6f6f6', padding: '5px', paddingLeft: '20px', paddingRight: '20px', borderRadius: '10px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', gap: 'clamp(20px, 3vw, 40px)', }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px', width: '100%' }}>
                  <Typography variant='h3' color='textSecondary' style={{ lineHeight: 1.2, fontSize: '2.3rem' }}>
                    Vote. Voice. Vortal.<font style={{ fontSize:'1.1rem', verticalAlign:'super' }}>&reg;</font>
                  </Typography>
                </div>
                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: '10px' }}>
                <div style={{ paddingTop: '15px', borderTop: '2px solid #034e5b' }}>
                  <div style={{ paddingBottom: '10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography variant='h4' color='primary' style={{ lineHeight: 1.2, textAlign: 'center' }}>
                      It’s simple
                    </Typography>
                    <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', textDecoration: 'underline' }}>
                      Here’s how to use Votuza
                    </Typography>
                    <div>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', minWidth: '33px', display: 'inline-block' }}>
                        1.
                      </Typography>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', display: 'inline', fontStyle: 'italic' }}>
                        Pick a category
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', minWidth: '33px', display: 'inline-block' }}>
                        2.
                      </Typography>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', display: 'inline', fontStyle: 'italic'  }}>
                        Pick a question
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', minWidth: '33px', display: 'inline-block' }}>
                        3.
                      </Typography>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', display: 'inline', fontStyle: 'italic'  }}>
                        Pick the worst company 
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', minWidth: '33px', display: 'inline-block' }}>
                        4.
                      </Typography>
                      <Typography variant='h5' color='textSecondary' style={{ lineHeight: 1.2, textAlign: 'left', display: 'inline', fontStyle: 'italic'  }}>
                        File a complaint 
                      </Typography>
                    </div>
                  </div>
                </div>
                <a href="https://www.businessinsider.com/tesla-owner-blows-up-car-instead-paying-repair-battery-video-2021-12" target="_blank" style={{ margin: 'auto', width: '40%', minWidth: '250px' }}>
                  <div className='pointer' style={{ textAlign: 'center', padding: '8px', alignItems: 'center', margin: 'auto', backgroundColor: '#034e5b', display: 'flex', flexDirection: 'column' }}>
                      <p style={{ color: 'white', margin: '3px', paddingBottom: '3px', fontFamily: 'Grotesk' }}>
                        When customer service fails...
                      </p>
                      <img style={{ width: '90%', height: 'auto' }} src="https://c.tenor.com/2tURN6JFtQkAAAAd/tesla-teslamodels.gif"></img>
                      <p style={{ color: 'white', margin: '3px', fontFamily: 'Grotesk', paddingTop: '3px', fontSize: '.8rem' }}>
                        A frustrated owner blows up their car.
                      </p>
                  </div>
                </a>
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link to={{ pathname: "/categories" }} style={{ padding: '8px 20px 8px 20px', background: 'darkred', borderRadius: '12px' }}>
                <Typography variant='h3' color='textSecondary' style={{ lineHeight: 1.2, fontSize: '2rem', color: 'white', textAlign: 'center', letterSpacing: '2px' }}>
                  File a complaint here
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// <iframe width="420" height="315" frameBorder="0"
//         src="https://www.youtube.com/embed/tgbNymZ7vqY">
// </iframe>

function mapStateToProps(state){
  return {};
}

export default withRouter(connect(mapStateToProps)(MediaHeader));
