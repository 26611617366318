const initialState = {
  authenticated: false
}

const isAuthenticated = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH_STATUS': {
      return { authenticated: action.payload.authenticated };
    }
    case 'LOGOUT': {
      return { authenticated: false };
    }
    default: {
      return state;
    }
  }
};

export default isAuthenticated;
