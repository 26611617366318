import React, { Component, Fragment, useState } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom'
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import * as api from '../../api';
import { getUser } from '../../redux/selectors';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Categories extends Component {
  constructor(props) {
    super(props);
    const categories = getCategoriesFromStorage() || [];
    this.state = {
      categories,
      modalOpen: false,
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {

    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  selectCategory = (category) => {
    this.props.history.push(`category/${category._id}?name=${category.name}`);
  }

  suggestCategory = (category) => {
    console.log('category:', category);
    this.toggleModal();
    this.openAlert('Thank you for your suggestion!');
    let name = '';
    let email = '';
    if (this.props.user) {
      name = this.props.user.first_name + ' ' + this.props.user.last_name;
      email = this.props.user.email;
    }
    let message = 'CATEGORY SUGGESTION: ' + category;
    api.makeRequest(api.postInquiry, { email, name, message });
  }

  closeAlert = () => {
    this.setState({ alertOpen: false, alertMessage: null })
  }

  openAlert = (message) => {
    this.setState({ alertOpen: true, alertMessage: message })
  }

  render() {
    const { categories, modalOpen, alertOpen, alertMessage} = this.state;
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div className='container'>
          <div className='left-padding' style={{ paddingTop: '50px' }}>
            <div style={{ paddingBottom: '20px' }}>
              <Typography variant='h2' color='primary'>
                Poll Categories
              </Typography>
            </div>
            <div>
              {categories.map( (category, index) => (
                <div>
                  <Typography variant='h3' className='grey-h1-button text-grey' style={{ lineHeight: 1, display: 'inline' }} onClick={() => this.selectCategory(category)}>
                    {category.name}
                  </Typography>
                  <div style={{ display: 'inline-block', overflow: 'hidden', marginLeft: '5px' }}>
                    <span style={{ fontSize: '.9rem', color: '#9cd70a', fontWeight: 700 }}>{category.poll_count} Polls</span>
                  </div>
                </div>
              ))}
              <div style={{ marginTop: '10px', display: 'inline-block' }}>
                <Typography variant='h6' className='blue-text green-hover pointer' onClick={this.toggleModal}>
                  Suggest a category
                </Typography>
              </div>
              { modalOpen && <SuggestCategoryModal closeModal={this.toggleModal} suggestCategory={this.suggestCategory} />}
            </div>
          </div>
        </div>
        <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.closeAlert} severity="success">
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

const SuggestCategoryModal = ({ closeModal, suggestCategory }) => {
  const [categoryNameValue, setCategoryNameValue] = useState('');
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className='modal-container'
      disableAutoFocus={true}
      disableEnforceFocus={true}
      BackdropProps={{
        invisible: false
      }}
    >
      <div style={{backgroundColor: '#f6f6f6', position: 'relative', display: 'flex', justifyContent: 'center', flexDirection:'column', border: '2px solid #034e5b', maxWidth: '90%', minWidth: '300px', borderRadius: '20px', overflow: 'hidden', position: 'relative'}}>
        <div style={{ position: 'absolute', left: '0px', top: '0px'}}>
          <IconButton
            onClick={closeModal}
            color="primary"
            size="medium"
          >
            <CloseIcon style={{ height: '2.5em', width: '2.5em'}}/>
          </IconButton>
        </div>
        <div className="mobile-form" style={{display: 'flex', maxWidth: '600px', paddingLeft: '60px', paddingRight: '60px', paddingTop: '25px', paddingBottom: '50px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white' }}>
          <Typography variant='h5' color="textSecondary" style={{ marginBottom: '20px' }}>Suggest a Category</Typography>
          <TextField
            id="standard-basic"
            label="Category"
            type="category"
            name="category"
            variant="standard"
            value={categoryNameValue}
            onChange={(e) => setCategoryNameValue(e.target.value)}
          />
          <Button disabled={categoryNameValue.length === 0} onClick={() => suggestCategory(categoryNameValue)} style={{ marginTop: '40px', minWidth: '0px', paddingLeft: '50px', paddingRight: '50px', letterSpacing: '0.5px', height: '36.5px' }}>Submit</Button>
        </div>
      </div>
    </Modal>
  )
};

function mapStateToProps(state){
  return { user: getUser(state) };
}

export default withRouter(connect(mapStateToProps)(Categories));
