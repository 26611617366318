import React, { Component } from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom'
import { getCategoriesFromStorage, setCategoriesToStorage } from '../../utils';
import Header from '../Header';
import MediaHeader from './MediaHeader';
import TrendingPolls from './TrendingPolls';
import HowTo from '../Common/HowTo';
import * as api from '../../api';

class HomeHeader extends Component {
  constructor(props) {
    super(props);
    const categories = getCategoriesFromStorage() || [];
    this.state = {
      categories: categories,
    }
  }

  componentWillMount = async () => {
    try {
      const { categories } = await api.makeRequest(api.getCategories);
      console.log('categories:', categories);
      this.setState({ categories });
      setCategoriesToStorage(categories);
    } catch(e) {
      console.log('e:', e);
    }
  }

  selectCategory = (category) => {
    if(category === 'others') {
      this.props.history.push(`categories`);
    } else {
      this.props.history.push(`category/${category._id}?name=${category.name}`);
    }
  }

  render() {
    const { categories } = this.state;
    const featuredCategories = categories.filter(category => category.featured);
    console.log('featuredCategories:', featuredCategories)
    return (
      <div className='full-green'>
        <div className='green-home-left-background' />
        <div className='green-home-gradient-background' />
        <Header />
        <MediaHeader />
        <HowTo isHome={true} />
        <div style={{ position: 'relative', width: '100%', paddingBottom: '35px' }}>
          <TrendingPolls />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {};
}

export default withRouter(connect(mapStateToProps)(HomeHeader));
